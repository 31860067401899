import { dotToCommaTransform } from "@/common/formatters/NumericTextFieldInputCleaner";
import { Indent, PickByValue } from "@stenajs-webui/core";
import { TextInput } from "@stenajs-webui/forms";
import { UseGridCellResult } from "@stenajs-webui/grid";
import { ModifiedFieldItemState } from "@stenajs-webui/redux";
import * as React from "react";
import { useSelector } from "react-redux";
import { inputFieldValidationOnlyNumbersCommasAndDots } from "../../../../../common/regex/InputFieldValidation";
import { StoreState } from "../../../../../config/redux/RootReducer";
import { RateSheetModifiedField } from "../../components/RateSheetModifiedField";
import { BulkEditRatePriceTableItem } from "../types";

export const createBulkEditRatesTableCell = (
  id: string,
  gridCell: UseGridCellResult<string>,
  isEditable: boolean,
  propertyName: keyof PickByValue<BulkEditRatePriceTableItem, string | null>
) => {
  return (
    <div className={propertyName}>
      <BulkEditRatesTableCell
        entityId={id}
        gridCell={gridCell}
        isEditable={isEditable}
        propertyName={propertyName}
      />
    </div>
  );
};

interface BulkEditRatesTableCellProps {
  entityId: string;
  isEditable?: boolean;
  gridCell: UseGridCellResult<string>;
  propertyName: keyof PickByValue<BulkEditRatePriceTableItem, string | null>;
}

export const BulkEditRatesTableCell: React.FC<BulkEditRatesTableCellProps> = ({
  entityId,
  isEditable,
  gridCell,
  propertyName,
}) => {
  const persistedValue = useSelector((state: StoreState) => {
    return state.bulkEditRatesState.persisted[entityId]?.[propertyName];
  });
  const editedValue = useSelector((state: StoreState) => {
    return state.bulkEditRatesState.edited[entityId]?.[propertyName];
  });

  const modifiedField: ModifiedFieldItemState =
    editedValue === persistedValue
      ? { id: "" }
      : {
          id: "",
          modified: true,
          originalValue: dotToCommaTransform(String(persistedValue)),
          newValue: String(editedValue),
        };

  return isEditable && gridCell.isEditing ? (
    <Indent row alignItems={"center"}>
      <TextInput
        {...gridCell.requiredProps}
        value={dotToCommaTransform(gridCell.editorValue)}
        onValueChange={(v) => {
          if (inputFieldValidationOnlyNumbersCommasAndDots.test(v)) {
            gridCell.setEditorValue(dotToCommaTransform(v));
          }
        }}
        onMove={gridCell.stopEditingAndMove}
        onDone={() => gridCell.stopEditing()}
        onEsc={gridCell.stopEditingAndRevert}
        autoFocus
        onDoubleClick={(v: any) => {
          if (v.target.value === editedValue) {
            setTimeout(() => gridCell.setEditorValue(String(editedValue)), 1);
          }
        }}
        onFocus={() => gridCell.setEditorValue(String(editedValue))}
        selectAllOnMount={!gridCell.lastKeyEvent}
      />
    </Indent>
  ) : (
    <Indent row alignItems={"center"}>
      <RateSheetModifiedField
        value={persistedValue ? dotToCommaTransform(persistedValue) : undefined}
        modifiedField={modifiedField}
        isEditable={isEditable}
        warningOnEmpty={"Empty is not allowed."}
        includeOldValue={true}
      />
    </Indent>
  );
};
