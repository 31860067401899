import { stenaUserMultiple } from "@stenajs-webui/elements";
import { FilterEntity } from "@stenajs-webui/filter";
import { Select } from "@stenajs-webui/select";
import * as React from "react";
import { useEffect, useState } from "react";
import { SingleValue } from "react-select";
import { useFilterWorkspace } from "../../hooks/UseFilterWorkspace";
import { useLoadCustomers } from "../../hooks/UseLoadCustomers";
import { transformQueryResultToFilterEntity } from "../../utils/transformers";
import { FppSearchFilterSection } from "../FppSearchFilterSection";

interface CustomerOption {
  label?: string;
  value?: string;
  data?: FilterEntity;
}

interface Props {
  enabled?: boolean;
  label?: string;
}
export const ParentCustomerFilterSection: React.FC<Props> = (props) =>
  props.enabled ? <ParentCustomerFilterSectionInternal {...props} /> : <></>;

const ParentCustomerFilterSectionInternal: React.FC<Props> = ({
  enabled,
  label,
}) => {
  const { parentCustomer, setParentCustomer } = useFilterWorkspace();
  const { fetchCustomers, loading, error, data } = useLoadCustomers();
  const [inputText, setInputText] = useState<string>("");
  const [filteredOptions, setFilteredOptions] = useState<Array<FilterEntity>>(
    []
  );

  const selectOptions = filteredOptions.map<CustomerOption>((option) => ({
    value: option.code,
    label: `${option.code} - ${option.name}`,
    data: option,
  }));

  const value: CustomerOption = {
    value: parentCustomer?.code,
    label: parentCustomer?.name,
    data: parentCustomer,
  };

  const onInputChange = (inputValue: string) => {
    setInputText(inputValue);
    if (inputValue.length >= 2) {
      fetchCustomers({ variables: { query: inputValue } });
    }
  };

  const onValueChange = (
    filterItems: SingleValue<CustomerOption> | undefined
  ) => {
    setParentCustomer(filterItems?.data);
  };

  useEffect(() => {
    if (data) {
      const transformedData =
        data?.productPrice.customers.byCustomerNumberOrIndex?.map((customer) =>
          transformQueryResultToFilterEntity(customer)
        ) ?? [];
      setFilteredOptions(transformedData);
    }
  }, [data, setFilteredOptions]);

  return (
    <FppSearchFilterSection
      sectionId={"parentCustomer"}
      label={label}
      enabled={enabled}
      numSelected={parentCustomer ? 1 : undefined}
      leftIcon={stenaUserMultiple}
      error={error?.message}
      onRetry={fetchCustomers}
      children={
        <Select
          options={selectOptions}
          isLoading={loading}
          value={value}
          isClearable
          inputValue={inputText}
          onInputChange={onInputChange}
          onChange={(customer: any) => {
            if (!customer) {
              onValueChange?.(undefined);
            } else if (onValueChange && customer) {
              onValueChange(customer);
            }
          }}
        />
      }
    />
  );
};
