import {
  SearchFilterSelectorContext,
  SearchFilterSelectorContextType,
} from "@/features/search-filter/context/searchFilterSelectorContext";
import { FppFilterSettings } from "@/features/search-filter/types/FppFilterSettings";
import { SectionChips } from "@stenajs-webui/filter";
import { useContext } from "react";
import { useFilterChips } from "../hooks/UseFilterChips";
import { DateRangeChip } from "./DateRangeChip";
import { DepartureChips } from "./DepartureChips";
import { RoutePairChips } from "./RoutePairChips";
import { VehicleTypeChips } from "./VehicleTypeChips";

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const getToAndFromChips = (
  label: string,
  value: string,
  from?: number,
  to?: number
) =>
  from || to
    ? [
        {
          value: value,
          label: label
            .concat(from ? from.toString() : "0")
            .concat(" - ")
            .concat(to ? to.toString() : "no limit"),
        },
      ]
    : undefined;

export const FppFilterChipsWithoutBookingNo = ({
  enableChipClearButtons,
  settings,
}: {
  settings: FppFilterSettings;
  enableChipClearButtons?: boolean;
}) => {
  const {
    onClickRemoveCustomerType,
    onClickRemoveNegotiationStatus,
    onClickRemoveSeller,
    onClickRemoveIncludeCustomersWith,
    onClickRemoveSalesRegion,
    onClickRemoveSalesRegionWithCountries,
    onClickRemoveRebateStatus,
  } = useFilterChips();
  const { committedState } = useContext(
    SearchFilterSelectorContext
  ) as SearchFilterSelectorContextType;

  const negotiationYear = committedState.negotiationYear.toString();
  const {
    noneSelectedChipLabels,
    customerTypeEnabled,
    negotiationStatusEnabled,
    routePairEnabled,
    salesRegionEnabled,
    salesRegionWithCountriesEnabled,
    vehicleTypeEnabled,
    rebateStatusEnabled,
  } = settings;

  return (
    <>
      {settings.yearMonthEnabled ? (
        <SectionChips
          sectionId={"yearMonth"}
          chips={[
            {
              label: String(committedState.year),
              value: String(committedState.year),
            },
          ]}
        />
      ) : (
        (settings.negotiationYearEnabled ||
          settings.negotiationYearChipEnabled) && (
          <SectionChips
            sectionId={"negotiationYear"}
            chips={[
              {
                label: negotiationYear,
                value: negotiationYear,
              },
            ]}
          />
        )
      )}
      <SectionChips
        emptyChipLabel={
          noneSelectedChipLabels?.negotiationStatusNoneSelectedLabel
            ? noneSelectedChipLabels?.negotiationStatusNoneSelectedLabel
            : negotiationStatusEnabled
            ? "All negotation statuses"
            : undefined
        }
        sectionId={"negotiationStatus"}
        chips={committedState.negotiationStatus.map((negStatus) => {
          return {
            value: negStatus.code,
            label: negStatus.name,
          };
        })}
        onClickRemoveOnChip={
          enableChipClearButtons === true
            ? (chip) =>
                onClickRemoveNegotiationStatus(
                  committedState.negotiationStatus.find(
                    (negStatus) => negStatus.id === chip.value
                  )!
                )
            : undefined
        }
      />
      <SectionChips
        sectionId={"yearMonth"}
        chips={
          settings.yearMonthEnabled
            ? [
                {
                  label: months[committedState.month - 1],
                  value: months[committedState.month - 1],
                },
              ]
            : undefined
        }
      />
      <SectionChips
        emptyChipLabel={noneSelectedChipLabels?.sellerNoneSelectedLabel}
        sectionId={"sellers"}
        chips={committedState.sellers.map((seller) => ({
          value: seller.code,
          label: seller.name.toUpperCase().replace("LINE\\", ""),
        }))}
        onClickRemoveOnChip={
          enableChipClearButtons === true
            ? (chip) =>
                onClickRemoveSeller(
                  committedState.sellers.find(
                    (seller) => seller.id === chip.value
                  )!
                )
            : undefined
        }
      />
      <SectionChips
        sectionId={"customerTypes"}
        emptyChipLabel={
          noneSelectedChipLabels?.customerTypesNoneSelectedLabel
            ? noneSelectedChipLabels.customerTypesNoneSelectedLabel
            : customerTypeEnabled
            ? "All types"
            : undefined
        }
        chips={committedState.customerTypes.map((ct) => ({
          value: ct.code,
          label: ct.name,
        }))}
        onClickRemoveOnChip={
          enableChipClearButtons === true
            ? (chip) =>
                onClickRemoveCustomerType(
                  committedState.customerTypes.find(
                    (ct) => ct.id === chip.value
                  )!
                )
            : undefined
        }
      />
      <SectionChips
        sectionId={"includeCustomersWith"}
        chips={committedState.includeCustomersWith.map((incCust) => ({
          value: incCust.code,
          label: incCust.name,
        }))}
        onClickRemoveOnChip={
          enableChipClearButtons === true
            ? (chip) =>
                onClickRemoveIncludeCustomersWith(
                  committedState.includeCustomersWith.find(
                    (incCust) => incCust.id === chip.value
                  )!
                )
            : undefined
        }
      />
      <SectionChips
        sectionId={"salesRegions"}
        emptyChipLabel={
          noneSelectedChipLabels?.salesRegionNoneSelectedLabel
            ? noneSelectedChipLabels.salesRegionNoneSelectedLabel
            : salesRegionEnabled
            ? undefined
            : undefined
        }
        chips={committedState.salesRegions.map((salesRegion) => ({
          value: salesRegion.code,
          label: salesRegion.name,
        }))}
        onClickRemoveOnChip={
          enableChipClearButtons === true
            ? (chip) =>
                onClickRemoveSalesRegion(
                  committedState.salesRegions.find(
                    (salesRegion) => salesRegion.id === chip.value
                  )!
                )
            : undefined
        }
      />
      <SectionChips
        sectionId={"salesRegionsWithCountries"}
        emptyChipLabel={
          noneSelectedChipLabels?.salesRegionWithCountriesNoneSelectedLabel
            ? noneSelectedChipLabels.salesRegionWithCountriesNoneSelectedLabel
            : salesRegionWithCountriesEnabled
            ? undefined
            : undefined
        }
        chips={committedState.salesRegionsWithCountries.map((salesRegion) => ({
          value: salesRegion.code,
          label: salesRegion.name,
        }))}
        onClickRemoveOnChip={
          enableChipClearButtons === true
            ? (chip) =>
                onClickRemoveSalesRegionWithCountries(
                  committedState.salesRegionsWithCountries.find(
                    (salesRegion) => salesRegion.id === chip.value
                  )!
                )
            : undefined
        }
      />
      <RoutePairChips
        enabled={routePairEnabled}
        noSelectedLabel={noneSelectedChipLabels?.routePairNoneSelectedLabel}
        selectedRoutePairs={committedState.routePairs}
      />
      <VehicleTypeChips
        enabled={vehicleTypeEnabled}
        selectedVehicleTypes={committedState.vehicleTypes}
      />
      <SectionChips
        sectionId={"customers"}
        chips={committedState.customers.map((customer) => ({
          value: customer.code,
          label: customer.name,
        }))}
      />
      <SectionChips
        sectionId={"parentCustomer"}
        chips={
          committedState.parentCustomer
            ? [
                {
                  value: committedState.parentCustomer?.code ?? "",
                  label: committedState.parentCustomer?.name ?? "",
                },
              ]
            : undefined
        }
      />
      <SectionChips
        sectionId={"noShowLateHandlingStatus"}
        chips={committedState.noShowLateHandlingStatus.map((status) => ({
          value: status.code,
          label: status.name,
        }))}
      />
      <SectionChips
        sectionId={"noShowLateHandlingType"}
        chips={committedState.noShowLateHandlingType.map((type) => ({
          value: type.code,
          label: type.name,
        }))}
      />
      <SectionChips
        sectionId={"bcNote"}
        chips={
          committedState.bookAndCheckInNotes.length === 2
            ? [{ label: "BC note: both", value: "b" }]
            : committedState.bookAndCheckInNotes.map((note) => ({
                value: note.code,
                label: "BC note: " + note.name,
              }))
        }
      />
      <DepartureChips
        startDate={committedState.startDate}
        startTime={committedState.startTime}
      />
      <SectionChips
        sectionId={"bookingNo"}
        chips={
          committedState.bookingNo
            ? [
                {
                  value: committedState.bookingNo,
                  label: "Booking ".concat(committedState.bookingNo),
                },
              ]
            : undefined
        }
      />
      <DateRangeChip
        startDate={committedState.dateRange.startDate}
        endDate={committedState.dateRange.endDate}
      />
      <SectionChips
        sectionId={"agreementName"}
        chips={
          committedState.agreementName
            ? [
                {
                  value: committedState.agreementName,
                  label: committedState.agreementName,
                },
              ]
            : undefined
        }
      />
      <SectionChips
        sectionId={"agreementNumber"}
        chips={
          committedState.agreementNumber
            ? [
                {
                  value: committedState.agreementNumber,
                  label: committedState.agreementNumber,
                },
              ]
            : undefined
        }
      />
      <SectionChips
        sectionId={"sailingCategories"}
        chips={committedState.sailingCategories.map((sailingCat) => ({
          value: sailingCat.code,
          label: sailingCat.name,
        }))}
      />

      <SectionChips
        sectionId={"routeAgreementSailingCategories"}
        chips={committedState.routeAgreementSailingCategories.map(
          (sailingCat) => ({
            value: sailingCat.code,
            label: sailingCat.name,
          })
        )}
      />
      <SectionChips
        sectionId={"routeAgreementSailingCategories"}
        chips={
          committedState.vehicleTypeAll
            ? [
                {
                  value: "All",
                  label: "All",
                },
              ]
            : undefined
        }
      />

      <SectionChips
        emptyChipLabel={
          noneSelectedChipLabels?.rebateStatusNoneSelectedLabel
            ? noneSelectedChipLabels?.rebateStatusNoneSelectedLabel
            : rebateStatusEnabled
            ? "All statuses"
            : undefined
        }
        sectionId={"rebateStatus"}
        chips={committedState.rebateStatuses.map((rebateStatus) => {
          return {
            value: rebateStatus.code,
            label: rebateStatus.name,
          };
        })}
        onClickRemoveOnChip={
          enableChipClearButtons === true
            ? (chip) =>
                onClickRemoveRebateStatus(
                  committedState.rebateStatuses.find(
                    (rebateStatus) => rebateStatus.id === chip.value
                  )!
                )
            : undefined
        }
      />

      <SectionChips
        sectionId={"vehicleLength"}
        chips={getToAndFromChips(
          "Length: ",
          "vehicleLength",
          committedState.vehicleLength.from,
          committedState.vehicleLength.to
        )}
      />
      <SectionChips
        sectionId={"vehicleWeight"}
        chips={getToAndFromChips(
          "Weight: ",
          "vehicleWeight",
          committedState.vehicleWeight.from,
          committedState.vehicleWeight.to
        )}
      />
      <SectionChips
        sectionId={"emptyLoaded"}
        chips={committedState.emptyLoaded.map((emptyLoaded) => ({
          value: emptyLoaded.code,
          label: emptyLoaded.name,
        }))}
      />
      <SectionChips
        sectionId={"unacc"}
        chips={committedState.unacc.map((unacc) => ({
          value: unacc.code,
          label: unacc.name,
        }))}
      />
      <SectionChips
        sectionId={"unitTypes"}
        chips={committedState.unitTypes.map((unit) => ({
          value: unit.code,
          label: unit.name,
        }))}
      />
      <SectionChips
        sectionId={"countries"}
        chips={committedState.countries.map((country) => ({
          value: country,
          label: country,
        }))}
      />
      <SectionChips
        sectionId={"Region"}
        chips={committedState.regions.map((region) => ({
          value: region.code,
          label: region.name,
        }))}
      />
      <SectionChips
        sectionId={"customerCategories"}
        chips={committedState.customerCategories.map((category) => ({
          value: category.code,
          label: category.name,
        }))}
      />
      <SectionChips
        sectionId={"statNumbersAndIndexes"}
        chips={committedState.statNumbersAndIndexes.map((item) => ({
          value: item.code,
          label: item.name,
        }))}
      />
      <SectionChips
        sectionId={"targetStatuses"}
        chips={committedState.targetStatuses.map((item) => ({
          value: item.code,
          label: item.name,
        }))}
      />
      <SectionChips
        sectionId={"averageNetPrice"}
        chips={getToAndFromChips(
          "Net price: ",
          "averageNetPrice",
          committedState.averageNetPrice.from,
          committedState.averageNetPrice.to
        )}
      />
      <SectionChips
        sectionId={"averageGrossPrice"}
        chips={getToAndFromChips(
          "Gross price: ",
          "averageGrossPrice",
          committedState.averageGrossPrice.from,
          committedState.averageGrossPrice.to
        )}
      />
      <SectionChips
        sectionId={"target"}
        chips={getToAndFromChips(
          "Target: ",
          "target",
          committedState.target.from,
          committedState.target.to
        )}
      />
      <SectionChips
        sectionId={"networkVolume"}
        chips={getToAndFromChips(
          "Network volume: ",
          "networkVolume",
          committedState.networkVolume.from,
          committedState.networkVolume.to
        )}
      />
      <SectionChips
        sectionId={"routeVolume"}
        chips={getToAndFromChips(
          "Route volume: ",
          "routeVolume",
          committedState.routeVolume.from,
          committedState.routeVolume.to
        )}
      />
      <SectionChips
        sectionId={"statNetworkVolume"}
        chips={getToAndFromChips(
          "Stat N volume: ",
          "statNetworkVolume",
          committedState.statNetworkVolume.from,
          committedState.statNetworkVolume.to
        )}
      />
      <SectionChips
        sectionId={"statRouteVolume"}
        chips={getToAndFromChips(
          "Stat R volume: ",
          "statRouteVolume",
          committedState.statRouteVolume.from,
          committedState.statRouteVolume.to
        )}
      />
    </>
  );
};
