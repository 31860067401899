import { IndentValues } from "@/common/utils/IndentValues";
import { SpacingValues } from "@/common/utils/SpacingValues";
import { ReportScreenHeadagreementFragment } from "@/gql/graphql";
import { Column, Heading, Indent, Space, Spacing } from "@stenajs-webui/core";
import {
  CardHeader,
  FlatButton,
  Tab,
  TabMenu,
  stenaOverview,
} from "@stenajs-webui/elements";
import { TableContext } from "@stenajs-webui/grid";
import { Window } from "@stenajs-webui/modal";
import React, { useEffect, useState } from "react";
import StepContainer from "../../common/components/StepContainer";
import { MultiLegAgreementsTableConfigColumnKeys } from "./common/multi-leg-agreements-table-config";
import { RouteAgreementsTableConfigColumnKeys } from "./common/route-agreements-table-config";
import { ReportOption } from "./common/types";
import { MultiLegTable } from "./components/MulltilegTable";
import { ReportOptions } from "./components/ReportOptions";
import { RouteAgreementsTable } from "./components/RouteAgreementsTable";
import large from "./components/large.png";
import small from "./components/small.png";

type AgreementSelectionStepProps = {
  headerAgreementRoutes: ReportScreenHeadagreementFragment["routeAgreements"];
  multilegAgreementRoutes: ReportScreenHeadagreementFragment["multiLegAgrRoutes"];
  reportOptions: ReportOption;
  setReportOptions: React.Dispatch<React.SetStateAction<ReportOption>>;
  routesAgreementTableContext: TableContext<RouteAgreementsTableConfigColumnKeys>;
  multilegRoutesAgreementTableContext: TableContext<MultiLegAgreementsTableConfigColumnKeys>;
  setIsNextAvailable: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedAgreements: React.Dispatch<
    React.SetStateAction<
      | {
          selectedRouteAgreements: ReportScreenHeadagreementFragment["routeAgreements"];
          selectedMultilegRouteAgreements: ReportScreenHeadagreementFragment["multiLegAgrRoutes"];
        }
      | undefined
    >
  >;
};

enum SelectedTab {
  "routeAgreements",
  "multilegRouteAgreements",
}

export const AgreementSelectionStep = ({
  headerAgreementRoutes,
  multilegAgreementRoutes,
  reportOptions,
  setReportOptions,
  routesAgreementTableContext,
  multilegRoutesAgreementTableContext,
  setIsNextAvailable,
  setSelectedAgreements,
}: AgreementSelectionStepProps) => {
  const [selectedTab, setSelectedTab] = useState<SelectedTab>(
    SelectedTab.routeAgreements
  );

  const [isOpen, setIsOpen] = useState(false);
  const numberOfSelectedMultiLegRoutes =
    multilegRoutesAgreementTableContext.state.selectedIds.selectedIds;
  const numberOfSelectedRoutes =
    routesAgreementTableContext.state.selectedIds.selectedIds;

  const selectedRouteAgreements =
    routesAgreementTableContext.state.selectedIds.selectedIds.flatMap(
      (selectedId) =>
        headerAgreementRoutes.filter((route) => {
          return route.id === selectedId;
        })
    );

  const selectedMultilegRouteAgreements =
    multilegRoutesAgreementTableContext.state.selectedIds.selectedIds.flatMap(
      (selectedId) =>
        multilegAgreementRoutes.filter((route) => {
          return route.id === selectedId;
        })
    );

  useEffect(() => {
    setIsNextAvailable(
      numberOfSelectedMultiLegRoutes.concat(numberOfSelectedRoutes).length > 0
    );
  }, [
    numberOfSelectedMultiLegRoutes,
    numberOfSelectedRoutes,
    setIsNextAvailable,
  ]);

  useEffect(() => {
    setSelectedAgreements({
      selectedRouteAgreements,
      selectedMultilegRouteAgreements,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routesAgreementTableContext, multilegRoutesAgreementTableContext]);

  return (
    <div style={{ overflow: "visible" }}>
      <Window
        isOpen={isOpen}
        headerText="Document example layout"
        style={{ overlay: { justifyContent: "center" } }}
        indent={IndentValues.TWENTYFOUR}
        spacing={SpacingValues.TWENTYFOUR}
        onRequestClose={() => setIsOpen(false)}
        shouldCloseOnOverlayClick={true}
      >
        <Heading variant="h4">Small version</Heading>
        <img src={small} alt="description"></img>
        <Space />
        <Heading variant="h4">Large version</Heading>
        <img src={large} alt="description"></img>
      </Window>
      <StepContainer width={"100%"}>
        <CardHeader
          text={"Select layout and agreements"}
          contentRight={
            <FlatButton
              label="View document layout"
              leftIcon={stenaOverview}
              onClick={() => setIsOpen(true)}
            />
          }
        />
        <ReportOptions
          reportOptions={reportOptions}
          setReportOptions={setReportOptions}
        />
        <Spacing num={SpacingValues.TWENTYFOUR} />
        <Indent num={IndentValues.SIXTEEN}>
          <Indent num={IndentValues.TWELVE}>
            <TabMenu>
              <Tab
                label={`Route agreements (${
                  routesAgreementTableContext.state.selectedIds.selectedIds
                    .length
                }/${headerAgreementRoutes?.length || 0})`}
                onClick={() => setSelectedTab(SelectedTab.routeAgreements)}
                selected={selectedTab === SelectedTab.routeAgreements}
              />
              <Tab
                label={`Multileg route agreements (${
                  multilegRoutesAgreementTableContext.state.selectedIds
                    .selectedIds.length
                }/${multilegAgreementRoutes?.length || 0})`}
                onClick={() =>
                  setSelectedTab(SelectedTab.multilegRouteAgreements)
                }
                selected={selectedTab === SelectedTab.multilegRouteAgreements}
              />
            </TabMenu>
          </Indent>
          <Column>
            {selectedTab === SelectedTab.routeAgreements ? (
              <RouteAgreementsTable
                routesAgreementTableContext={routesAgreementTableContext}
                headerAgreementRoutes={headerAgreementRoutes}
              />
            ) : (
              <MultiLegTable
                multilegAgreementRoutes={multilegAgreementRoutes}
                multilegRoutesAgreementTableContext={
                  multilegRoutesAgreementTableContext
                }
              />
            )}
          </Column>
        </Indent>
      </StepContainer>
    </div>
  );
};
