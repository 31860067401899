import { IndentValues } from "@/common/utils/IndentValues";
import { Box } from "@stenajs-webui/core";

interface Props {
  width?: string;
}

const StepBoxBody: React.FC<Props> = ({ children, width }) => (
  <Box
    width={width ?? "100%"}
    overflow={"auto"}
    indent={IndentValues.TWENTYFOUR}
    spacing
  >
    {children}
  </Box>
);
export default StepBoxBody;
