import { RebateAgreementStatusCodeChip } from "@/common/components/chips/RebateAgreementStatusCodeChip";
import { RebateAgreementStatusCode } from "@/gql/graphql";
import { Indent, Txt } from "@stenajs-webui/core";
import { Link } from "@stenajs-webui/elements";
import { StandardTableConfig, createColumnConfig } from "@stenajs-webui/grid";
import { RebateCustomerListTag } from "../components/RebateCustomerListTag";
import { RebateTableActionButtons } from "../components/RebateTableActionButtons";

export interface RebateTableItem {
  id: string;
  status: RebateAgreementStatusCode;
  name: string;
  paymentReceiver: number;
  customerList: Array<{
    index: string;
    indexNo: number;
  }>;
  validFrom: string;
  validTo: string;
  rebateAgreementNumber: number;
}

export type RebateTableColumns = keyof Omit<RebateTableItem, "id"> | "actions";

export const rebateTableConfig = (): StandardTableConfig<
  RebateTableItem,
  RebateTableColumns
> => ({
  keyResolver: (item) => item.id,
  rowIndent: 1,
  columns: {
    status: createColumnConfig((item) => item.status, {
      renderCell: ({ label, item }) => (
        <Indent>
          <RebateAgreementStatusCodeChip
            statusCode={item.status}
            size={"medium"}
            labelToLowerCase
          />
        </Indent>
      ),
    }),
    name: createColumnConfig((item) => item.name, {
      minWidth: "200px",
      renderCell: ({ label, item }) => (
        <Indent>
          <Txt whiteSpace={"nowrap"}>
            <Link onClick={() => ""}>{label}</Link>
          </Txt>
        </Indent>
      ),
    }),
    paymentReceiver: createColumnConfig((item) => item.paymentReceiver, {}),
    customerList: createColumnConfig((item) => item.customerList, {
      renderCell: ({ label, item }) => <RebateCustomerListTag item={item} />,
      width: "500px",
    }),
    validFrom: createColumnConfig((item) => item.validFrom, {}),
    validTo: createColumnConfig((item) => item.validTo, {}),
    rebateAgreementNumber: createColumnConfig(
      (item) => item.rebateAgreementNumber,
      {
        width: "250px",
      }
    ),
    actions: createColumnConfig((item) => item, {
      justifyContentCell: "flex-end",
      columnLabel: "",
      renderCell: ({ label, item }) => <RebateTableActionButtons />,
      width: "40px",
      disableGridCell: true,
    }),
  },
  columnOrder: [
    "status",
    "name",
    "paymentReceiver",
    "customerList",
    "validFrom",
    "validTo",
    "rebateAgreementNumber",
    "actions",
  ],
});
