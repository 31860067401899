import { Box, BoxProps } from "@stenajs-webui/core";
import * as React from "react";
import { FormModelChangeHandler } from "../../../common/forms/types";

import { ZIndex } from "@/common/utils/ZIndexEnum";
import { Card, Label } from "@stenajs-webui/elements";
import {
  PriceCalculatorFormData,
  SearchEntityType,
} from "../PriceCalculatorFormFetcher";
import { PriceCalculatorBookingForm } from "./PriceCalculatorBookingForm";
import { PriceCalculatorCustomerForm } from "./PriceCalculatorCustomerForm";
import { PriceCalculatorSearch } from "./price-calculator-search/PriceCalculatorSearch";
import { ConsignmentQuery } from "@/gql/graphql";

interface Props {
  formData: PriceCalculatorFormData;
  setPriceUpdatePending: (isPriceUpdating: boolean) => void;
  onChangeFormData: FormModelChangeHandler<PriceCalculatorFormData>;
  onClearFormData: () => void;
  isLoading: boolean;
  onSelectConsignmentVersion: (
    bookingNum: number,
    consignmentVersion: number
  ) => void;
  consignment?: ConsignmentQuery;
  setState: React.Dispatch<React.SetStateAction<PriceCalculatorFormData>>;
}

export const InputFieldSetWidthLabeled: React.FC<
  BoxProps & {
    label: string;
  }
> = ({ label, children, ...props }) => {
  return (
    <Box {...props}>
      <Label text={label}>{children}</Label>
    </Box>
  );
};

export const PriceCalculatorFormPanel: React.FC<Props> = ({
  formData,
  setPriceUpdatePending,
  onChangeFormData,
  onClearFormData,
  onSelectConsignmentVersion,
  isLoading,
  consignment,
  setState,
}) => {
  return (
    <Card zIndex={ZIndex.none}>
      <PriceCalculatorSearch
        formData={formData}
        isLoading={isLoading}
        setPriceUpdatePending={setPriceUpdatePending}
        onChange={onChangeFormData}
        onSelectConsignmentVersion={onSelectConsignmentVersion}
      />
      {formData.searchEntityType === SearchEntityType.BOOKING ? (
        <PriceCalculatorBookingForm
          formData={formData}
          onChangeFormData={onChangeFormData}
          consignment={consignment}
        />
      ) : (
        <PriceCalculatorCustomerForm
          formData={formData}
          setState={setState}
          onChangeFormData={onChangeFormData}
          onClearFormData={onClearFormData}
        />
      )}
    </Card>
  );
};
