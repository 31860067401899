import { Space, Text } from "@stenajs-webui/core";
import * as React from "react";
import { cssColor } from "@stenajs-webui/theme";
import { TESTENVIRONMENT_URLS } from "../../config/environment";

export const EnvironmentWarning: React.FC = () => {
  const envWarningText = () => {
    switch (window.location.origin) {
      case TESTENVIRONMENT_URLS[0]:
        return "DEV-TEST!";
      case TESTENVIRONMENT_URLS[1]:
      case TESTENVIRONMENT_URLS[2]:
        return "QA-TEST!";
      case TESTENVIRONMENT_URLS[3]:
      case TESTENVIRONMENT_URLS[4]:
        return "ACC-TEST!";
      case TESTENVIRONMENT_URLS[5]:
      case TESTENVIRONMENT_URLS[6]:
        return "PROD-TEST!";
      case TESTENVIRONMENT_URLS[7]:
        return "LOCAL";
      default:
        return "TEST!";
    }
  };

  return (
    <div
      style={{
        padding: "4px 12px",
        marginLeft: "32px",
        borderRadius: "4px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        border: `2px solid ${cssColor("--lhds-color-red-500")}`,
        fontWeight: "bolder",
        backgroundColor: cssColor("--lhds-color-red-50"),
      }}
    >
      <span role="img" aria-label="user with computer">
        👩‍💻
      </span>
      <Space />
      <Text color={cssColor("--lhds-color-red-500")} variant={"bold"}>
        {envWarningText()}
      </Text>
    </div>
  );
};
