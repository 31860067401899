import { SectionChips } from "@stenajs-webui/filter";
import { format } from "date-fns";

export const DateRangeChip = ({
  endDate,
  startDate,
}: {
  startDate?: Date;
  endDate?: Date;
}) => {
  const formattedStartDate = startDate && format(startDate, "yyyy-MM-dd");
  const formattedEndDate = endDate && format(endDate, "yyyy-MM-dd");

  const getLabel = () =>
    ""
      .concat(startDate && !endDate ? `${formattedStartDate} - ` : "")
      .concat(endDate && !startDate ? ` - ${formattedEndDate}` : "")
      .concat(
        formattedStartDate && formattedEndDate
          ? `${formattedStartDate} - ${formattedEndDate}`
          : ""
      );
  const enabled = formattedStartDate || formattedEndDate;
  return (
    <SectionChips
      sectionId={"dateRange"}
      chips={
        enabled
          ? [
              {
                value: "dateRange",
                label: getLabel(),
              },
            ]
          : undefined
      }
    />
  );
};
