import { GridContainer } from "@/common/components/grid-container/GridContainer";
import { PageLayout } from "@/common/components/page-layout/PageLayout";
import { useLocalError } from "@/common/error/UseLocalError";
import { formatDate } from "@/common/formatters/DateFormatter";
import { FormModelChangeHandler } from "@/common/forms/types";
import { useFormModelState } from "@/common/hooks/UseFormModelState";
import { SpaceValues } from "@/common/utils/SpaceValues";
import { Box, Column, Space } from "@stenajs-webui/core";
import { Banner, FlatButton } from "@stenajs-webui/elements";
import { gql } from "apollo-boost";
import React, { useEffect, useMemo, useState } from "react";
import { useConsignmentPriceCalculation } from "./hooks/UseConsignmentPriceCalulation";
import { useCustomerPriceCalculation } from "./hooks/UseCustomerPriceCalculation";
import { PriceCalculatorActionBar } from "./price-calculator-form-panel/PriceCalculatorActionBar";
import { PriceCalculatorFormPanel } from "./price-calculator-form-panel/PriceCalculatorFormPanel";
import { MultiLegOptionData } from "./price-calculator-form-panel/price-calculator-form-sections/PriceCalculatorSelectMultiLegRoute";
import { useDynamicSize } from "./price-calculator-form-panel/price-calculator-form-sections/sailing-details/hooks/UseDynamicSize";
import { PriceCalculatorBookingSearchResultPane } from "./price-calculator-result-panel/booking-search/PriceCalculatorBookingSearchResultPane";
import { PriceCalculatorCustomerSearchResultPane } from "./price-calculator-result-panel/customer-search/PriceCalculatorCustomerSearchResultPane";
import { formDataStateTransformer } from "./price-calculator-result-panel/transformers/FormDataStateTransformer";
import {
  bookingDataExist,
  customerDataExist,
} from "./utils/ConditionalHelpers";
import {
  FetchConsignmentFragment,
  FetchPriceCalcFragment,
  PriceCalcErrorType,
} from "@/gql/graphql";

export enum SearchEntityType {
  CUSTOMER = "CUSTOMER",
  BOOKING = "BOOKING",
}

export enum MovementType {
  SECTIONAL = "SECTIONAL",
  MULTILEG = "MULTILEG",
}

export const MovementTypeLabels = new Map<string, string>([
  [MovementType.SECTIONAL, "Sectional"],
  [MovementType.MULTILEG, "Multi-leg"],
]);

export interface Goods {
  goodsCode: string;
  weight: string;
}

export interface Ancillary {
  ancillaryCode: string;
  quantity: number;
}

export interface RouteLeg {
  routeCode: string;
  sailingTypeCode: string;
  departureDate: string;
  departureTime: string;
}

export interface PriceCalcResult {
  formData: PriceCalculatorFormData;
  priceCalc: FetchPriceCalcFragment;
}
export interface CustomerSearchResultData {
  formData: PriceCalculatorFormData;
  priceCalcData: FetchPriceCalcFragment[];
  handledError?: PriceCalcHandledError;
}

export interface PriceCalcHandledError {
  error: string;
  errorType: PriceCalcErrorType;
}

export interface BookingSearchResultData {
  consignmentData: FetchConsignmentFragment[];
  priceCalcData: FetchPriceCalcFragment[];
  handledError?: PriceCalcHandledError;
}

export interface PriceCalculatorFormData {
  moreOptionsPanelCollapsed?: boolean;
  searchEntityType?: SearchEntityType;
  selectedCustomerNum?: number;
  movementType: MovementType;
  departureTimeManual?: boolean;
  bookingNum?: number;
  consignmentVersion?: number;
  multiLegRouteData?: MultiLegOptionData;
  routeLegs?: Array<RouteLeg>;
  routeCode?: string;
  sailingDate?: string;
  sailingType?: string;
  departureTime?: string;
  vehicleType?: string;
  vehicleLength?: string;
  vehicleWidth?: string;
  vehicleWeight?: string;
  vehiclePlugins?: number;
  vehicleTradeUnits?: number;
  passengersAdults?: number;
  passengersChildren?: number;
  passengersInfants?: number;
  passengersSleepers?: number;
  drivers?: number;
  loadingPreferenceEnabled?: boolean;
  loadingPreference?: string;
  cargoHazardousGoods?: boolean;
  cargoHazardousGoodsRowWeight?: string;
  cargoHazardousGoodsAmountRows?: number;
  cargoHazardousGoodsQualified?: boolean;
  livestock?: boolean;
  livestockType?: string;
  vehicleShortDescription?: string;
  conditionsStandby?: boolean;
  conditionsTransit?: boolean;
  conditionsReceiverCountryCode?: string;
  conditionsSenderCountryCode?: string;
  conditionsGoods?: Goods[];
  conditionsAncillary?: Ancillary[];
  vehicleTradeWeight?: string;
  includePendingOffered?: boolean;
}
export const fetchPriceCalcFragment = gql`
  fragment FetchPriceCalc on PriceCalc {
    auditLogs
    errorMessage
    baseCurrencyCode
    seaFreightPrice
    surchargeRecords {
      articleType {
        id
        code
        name
      }
      quantity
      surchargePrice
      surchargeVatAmount
      surchargeVatRate
    }
    ancillaryRecords {
      ancillaryCode
      quantity
      ancillaryPrice
      ancillaryVatAmount
      ancillaryVatRate
    }
    totalPrice
    totalVat
    route {
      id
      code
      name
    }
  }
`;

export const fetchConsignmentFragment = gql`
  fragment FetchConsignment on Consignment {
    id
    version
    bookingNum
    statusCode
    transit
    multiLegRoute {
      id
      name
      code
    }
    livestock
    livestockType {
      id
      code
    }
    movementType {
      id
      code
      description
    }
    invoiceCurrencyCode
    length
    weight
    width
    height
    custNo
    numOfAdults
    numOfChildren
    numOfInfants
    numOfSleepers
    numOfFoc
    numOfDrivers
    numOfPlugIn
    numOfTradeVehicles
    numOfVehicleTypeUnits
    qualifiedGoods
    multiLegBookingNumber
    standby
    vehicleType {
      id
      code
      length
      shortDescription
    }
    vehicleRegNo
    trailerRegNo
    version
    customerReference
    sailingArchive {
      id
      sailingType
      departureDate
      departureTime
      route {
        id
        code
        name
      }
    }
    consignmentArticles {
      id
      articleCode
      quantity
      articleType {
        id
        type
        name
      }
    }
    consignmentGoods {
      id
      goodsCode
      weight
      description
    }
    consignmentHazardousGoods {
      id
      weight
    }
    consignmentLoadingPreferences {
      id
      loadingPreferenceCode
    }
    consignmentAddresses {
      id
      countryCode
      addressTypeCode
    }
    consignmentLegInvoice {
      id
      invoiceNo
    }
  }
`;

export enum SelectedTab {
  PRICE,
  AUDIT,
}

export interface AuditData {
  errorMessage: string;
  auditLogs: Array<string>;
  routeCode?: string;
  departureDate: string;
  sailingCategory?: string;
}

export let formDefaultState: PriceCalculatorFormData = {
  selectedCustomerNum: undefined,
  movementType: MovementType.SECTIONAL,
  departureTimeManual: false,
  departureTime: "11:11",
  sailingType: "P",
  moreOptionsPanelCollapsed: true,
  loadingPreferenceEnabled: false,
  loadingPreference: undefined,
  routeCode: undefined,
  multiLegRouteData: undefined,
  conditionsStandby: false,
  conditionsTransit: false,
  conditionsGoods: [],
  conditionsAncillary: [],
  vehicleType: "",
  vehicleWidth: "0",
  vehicleWeight: "0",
  vehiclePlugins: 0,
  vehicleLength: "0",
  vehicleTradeUnits: 0,
  vehicleTradeWeight: "0",
  searchEntityType: SearchEntityType.CUSTOMER,
  passengersAdults: 0,
  passengersChildren: 0,
  passengersInfants: 0,
  passengersSleepers: 0,
  cargoHazardousGoodsAmountRows: 0,
  cargoHazardousGoodsRowWeight: "0",
  sailingDate: formatDate(new Date().toISOString()),
};

export const PriceCalculatorFormFetcher: React.FC = () => {
  const { formFetcherCurrentSize } = useDynamicSize();
  const [priceUpdatePending, setPriceUpdatePending] = useState<boolean>(true);
  const [formDataStateForComparison, setFormDataStateForComparison] =
    useState(formDefaultState);
  const [isFormStateFetched, setIsFormStateFetched] = useState<boolean>(false);
  const [formData, onChangeFormData, setState] =
    useFormModelState<PriceCalculatorFormData>(formDefaultState);

  const {
    fetchConsignment,
    loading: consignmentPriceCalculationLoading,
    bookingResult: bookingSearchResultData,
    error: bookingSearchError,
    consignmentData,
  } = useConsignmentPriceCalculation();

  const {
    calculateCustomerPrice,
    loading: customerPriceCalculationLoading,
    customerResult: customerSearchResultData,
    error: customerSearchError,
  } = useCustomerPriceCalculation();

  const { localError, setLocalError, clearLocalError } = useLocalError();

  useEffect(() => {
    (customerSearchError || bookingSearchError) &&
      setLocalError(customerSearchError || bookingSearchError);
  }, [customerSearchError, bookingSearchError, setLocalError]);

  useMemo(() => {
    const calculationStateHasChanged =
      JSON.stringify({ ...formData }) ===
      JSON.stringify({ ...formDataStateForComparison });

    if (customerSearchResultData) {
      setPriceUpdatePending(!calculationStateHasChanged);
    }

    if (!isFormStateFetched && formData.vehicleType !== "") {
      setIsFormStateFetched(true);
      formDefaultState = { ...formData };
    }
  }, [
    formData,
    formDataStateForComparison,
    customerSearchResultData,
    isFormStateFetched,
  ]);

  const onUpdateFormField: FormModelChangeHandler<PriceCalculatorFormData> = (
    key,
    value
  ) => {
    onChangeFormData(key, value);
    if (key === "moreOptionsPanelCollapsed") return;
    setPriceUpdatePending(true);
    if (key === "movementType") {
      setState((formData) => ({
        ...formDefaultState,
        selectedCustomerNum: formData.selectedCustomerNum,
        searchEntityType: formData.searchEntityType,
        movementType: formData.movementType,
        moreOptionsPanelCollapsed: formData.moreOptionsPanelCollapsed,
      }));
    }
  };

  const onSelectConsignmentVersion = async (
    bookingNum: number,
    consignmentVersion: number
  ) => {
    clearLocalError();
    await fetchConsignment({
      variables: {
        bookingNum: bookingNum.toString(),
        consignmentVersion: consignmentVersion,
      },
    });
  };

  const doesHaveBookingData = bookingDataExist({
    bookingSearchResult: bookingSearchResultData,
    searchEntity: formData.searchEntityType,
  });

  const doesHaveCustomerData = customerDataExist({
    customerSearchResult: customerSearchResultData,
    searchEntity: formData.searchEntityType,
  });

  return (
    <Box
      width={"100%"}
      position={"relative"}
      overflow={"auto"}
      minHeight={"500px"}
    >
      <PageLayout>
        <GridContainer boxMinWidth={800} boxMaxWidth={"0.5fr"}>
          <Column height={"100%"}>
            <PriceCalculatorFormPanel
              onSelectConsignmentVersion={onSelectConsignmentVersion}
              consignment={consignmentData}
              formData={formData}
              isLoading={consignmentPriceCalculationLoading}
              setPriceUpdatePending={setPriceUpdatePending}
              setState={setState}
              onChangeFormData={onUpdateFormField}
              onClearFormData={() => {
                setState((formData) => ({
                  ...formDefaultState,
                  selectedCustomerNum: formData.selectedCustomerNum,
                  searchEntityType: formData.searchEntityType,
                  movementType: formData.movementType,
                  moreOptionsPanelCollapsed: formData.moreOptionsPanelCollapsed,
                }));
                clearLocalError();
              }}
            />
          </Column>
          <Box>
            {formData.searchEntityType === SearchEntityType.CUSTOMER && (
              <PriceCalculatorActionBar
                formData={formData}
                onChangeFormData={onUpdateFormField}
                onCalculatePrice={() => {
                  setPriceUpdatePending(false);
                  setFormDataStateForComparison(
                    formDataStateTransformer({ ...formData })
                  );
                  calculateCustomerPrice(formData);
                }}
                loading={customerPriceCalculationLoading}
                error={localError}
                onClearError={() => clearLocalError()}
              />
            )}

            {doesHaveBookingData && (
              <>
                <Space num={SpaceValues.TWENTYFOUR} />
                <PriceCalculatorBookingSearchResultPane
                  bookingPriceCalcResult={bookingSearchResultData}
                />
              </>
            )}

            {doesHaveCustomerData && (
              <>
                <Space num={SpaceValues.TWENTYFOUR} />
                <PriceCalculatorCustomerSearchResultPane
                  customerPriceCalcResult={customerSearchResultData}
                  priceUpdatePending={priceUpdatePending}
                />
              </>
            )}
          </Box>

          {formData.searchEntityType === SearchEntityType.BOOKING &&
            localError && (
              <Box position={"fixed"} bottom={0} left={0}>
                <Box
                  spacing={2}
                  indent={2}
                  shadow={"popover"}
                  width={formFetcherCurrentSize}
                >
                  <Column maxHeight={"200px"} overflowY={"auto"}>
                    <Banner
                      variant={"error"}
                      headerText={"Could not calculate price"}
                      text={localError?.message}
                      contentRight={
                        <FlatButton
                          label={"Clear error"}
                          onClick={() => clearLocalError()}
                        />
                      }
                    />
                    <Space num={2} />
                  </Column>
                </Box>
              </Box>
            )}
        </GridContainer>
      </PageLayout>
    </Box>
  );
};
