import { stenaStatisticsBar } from "@stenajs-webui/elements";
import * as React from "react";
import { useFilterWorkspace } from "../../hooks/UseFilterWorkspace";
import { RangeFilter } from "../../types/RangeFilter";
import { FppSearchFilterSection } from "../FppSearchFilterSection";
import { RangeControl } from "../RangeControl";

interface Props {
  enabled?: boolean;
  label?: string;
  labelFrom?: string;
  labelTo?: string;
}

export const StatRouteVolumeFilterSection: React.FC<Props> = ({
  enabled,
  label,
  labelFrom,
  labelTo,
}) => {
  const { statRouteVolume, setStatRouteVolume } = useFilterWorkspace();

  const onFilterValueChange = (rangeFilter: RangeFilter) => {
    setStatRouteVolume({ ...rangeFilter });
  };

  const numberOfActiveFilters = () => {
    return statRouteVolume.from || statRouteVolume.to ? 1 : 0;
  };

  return (
    <FppSearchFilterSection
      sectionId={"statRouteVolume"}
      label={label}
      enabled={enabled}
      numSelected={numberOfActiveFilters()}
      leftIcon={stenaStatisticsBar}
      children={
        <RangeControl<RangeFilter>
          value={statRouteVolume}
          valueFrom={statRouteVolume.from}
          valueTo={statRouteVolume.to}
          onValueChange={onFilterValueChange}
          labelFrom={labelFrom ?? "From"}
          labelTo={labelTo ?? "To"}
          maxNumbers={6}
        />
      }
    />
  );
};
