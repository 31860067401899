import { ZIndex } from "@/common/utils/ZIndexEnum";
import { BoxplotChartOptions, ScaleTypes } from "@carbon/charts/interfaces";
import "@carbon/charts/styles.css";
import { Box, Row, Space } from "@stenajs-webui/core";
import {
  CardBody,
  CardHeader,
  FlatButton,
  Spinner,
  stenaTodoList,
} from "@stenajs-webui/elements";
import { RadioButtonWithLabel } from "@stenajs-webui/forms";
import { cssColor } from "@stenajs-webui/theme";
import { Popover } from "@stenajs-webui/tooltip";
import { ApolloError } from "apollo-boost";
import * as React from "react";
import { useRef, useState } from "react";
import { stripGqlErrorText } from "../../../../../common/string/GraphQlErrorParser";
import { BannerByErrorCode } from "../../../../statistics/common/error/BannerByErrorCode";
import { Chart } from "./Chart";
import { Legend } from "./Legend";
import "./TargetSummaryChart.css";
import { useChartComputation } from "./custom-hooks/UseChartComputation";
import { CategoryDataFragment } from "@/gql/graphql";

interface Props {
  targetChartData?: Array<CategoryDataFragment>;
  loading: boolean;
  currency: string;
  error?: ApolloError;
}
export interface GrossNetData {
  netData: {
    group: string;
    key?: number;
    value?: number;
  };
  grossData: {
    group: string;
    key?: number;
    value?: number;
  };
}

const options: BoxplotChartOptions = {
  axes: {
    top: {
      mapsTo: "value",
      scaleType: ScaleTypes.LINEAR,
    },
    bottom: {
      mapsTo: "value",
      scaleType: ScaleTypes.LINEAR,
    },
    left: {
      scaleType: ScaleTypes.LABELS,
      mapsTo: "group",
      stacked: true,
      truncation: {
        numCharacter: 50,
      },
    },
  },
  height: "450px",
  resizable: true,
  animations: false,
  tooltip: {
    enabled: true,
    showTotal: true,
  },
};

type RadioSelection = "gross" | "net";

export const TargetSummaryChart: React.FC<Props> = ({
  targetChartData,
  loading,
  currency,
  error,
}) => {
  const [selectedRadio, setSelectedRadio] = useState<RadioSelection>("gross");
  const calculatedData = useChartComputation(targetChartData);
  const legendButtonRefHolder = useRef<HTMLButtonElement>(null);
  const [legendOpen, setLegendOpen] = useState<boolean>(false);

  const grossData = calculatedData?.compactData.map((cd) => {
    return cd.grossData;
  });

  const netData = calculatedData?.compactData.map((cd) => {
    return cd.netData;
  });

  const handleLegendClose = () => {
    setLegendOpen(false);
  };

  const handleLegendToggle = () => {
    setLegendOpen((prev) => !prev);
  };

  const handleCloseLegend = () => {
    if (legendButtonRefHolder.current) {
      legendButtonRefHolder.current.click();
    }
  };

  const handleRadioSelection = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedRadio = e.target.name as RadioSelection;
    setSelectedRadio(selectedRadio);
  };

  return (
    <>
      <CardHeader
        text={
          "Average price per meter distribution by sailing category and unit type."
        }
        contentCenter={
          <Row width={"500px"}>
            <RadioButtonWithLabel
              checked={selectedRadio === "gross"}
              name="gross"
              onChange={handleRadioSelection}
              label="Avg. gross price"
              textColor={cssColor("--lhds-color-ui-700")}
            />
            <Space num={3} />
            <RadioButtonWithLabel
              checked={selectedRadio === "net"}
              name="net"
              onChange={handleRadioSelection}
              label="Avg. net price"
              textColor={cssColor("--lhds-color-ui-700")}
            />
          </Row>
        }
        contentRight={
          <Popover
            content={<Legend handleCloseLegend={handleCloseLegend} />}
            visible={legendOpen}
            onClickOutside={handleLegendClose}
            arrow={false}
            placement="bottom-end"
            disablePadding={true}
            zIndex={ZIndex.lowest}
          >
            <FlatButton
              leftIcon={stenaTodoList}
              label={"Legend"}
              ref={legendButtonRefHolder}
              onClick={handleLegendToggle}
            />
          </Popover>
        }
      />
      <CardBody className="chartBase">
        <Row>
          {error ? (
            <Box alignItems={"center"}>
              <BannerByErrorCode
                title="Unable to load data"
                text={stripGqlErrorText("error.message")}
                error={error}
              />
            </Box>
          ) : loading ? (
            <Box alignItems="center" width={"100%"}>
              <Spinner size="medium" />
            </Box>
          ) : (
            <>
              {selectedRadio === "net" && (
                <Chart currency={currency} data={netData} options={options} />
              )}

              {selectedRadio === "gross" && (
                <Chart currency={currency} data={grossData} options={options} />
              )}
            </>
          )}
        </Row>
      </CardBody>
    </>
  );
};
