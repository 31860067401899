import { Box, Row, Space } from "@stenajs-webui/core";
import {
  Card,
  CardHeader,
  FlatButton,
  stenaAngleDown,
  stenaAngleRight,
} from "@stenajs-webui/elements";
import { StandardTable } from "@stenajs-webui/grid";
import { orderBy, uniq } from "lodash";
import * as React from "react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "../../../../config/redux/RootReducer";
import { createExpectedVolumeTableConfig } from "../config/ExpectedVolumeTableConfig";
import {
  expectedVolumeActions,
  expectedVolumeSelectors,
} from "../redux/ExpectedVolumeRedux";
import { copyLastYear } from "../thunks/CopyLastYear";

interface Props {
  routePairCode: string;
  statNo: string;
  hideIfNoItems: boolean;
  selectedYear: number;
}

const currentYear = new Date().getUTCFullYear();

export const ExpectedVolumeForRoutePair: React.FC<Props> = ({
  routePairCode,
  statNo,
  hideIfNoItems,
  selectedYear,
}) => {
  const dispatch = useDispatch();

  const yearIsEditable = useMemo(() => {
    return selectedYear >= currentYear;
  }, [selectedYear]);

  const config = useMemo(
    () => createExpectedVolumeTableConfig(routePairCode, yearIsEditable),
    [routePairCode, yearIsEditable]
  );

  const selector = useCallback(
    (state: StoreState) =>
      expectedVolumeSelectors.getExpectedVolumeForRoutePairId(
        state,
        routePairCode
      ),
    [routePairCode]
  );

  const routePairEntity = useSelector(selector);

  const [expanded, setExpanded] = useState(false);

  const entityHasVolume = routePairEntity?.routePairMeta.hasVolume;
  const uniqCustomerNumbersLength = uniq(
    routePairEntity?.volumes.map((v) => v.persisted.customerNo)
  ).length;

  useEffect(() => {
    if (entityHasVolume !== undefined && uniqCustomerNumbersLength <= 8) {
      setExpanded(entityHasVolume);
    }
  }, [entityHasVolume]);

  const onClickClear = useCallback(() => {
    dispatch(expectedVolumeActions.clearExpectedVolume(routePairCode));
  }, [dispatch, routePairCode]);

  const onClickCopy = useCallback(() => {
    dispatch(copyLastYear(routePairCode));
    setExpanded(true);
  }, [dispatch, routePairCode]);

  if (
    !routePairEntity ||
    (hideIfNoItems && !routePairEntity.routePairMeta.hasVolume)
  ) {
    return null;
  }

  const sortedItems = orderBy(routePairEntity.volumes ?? [], [
    (item) => !item.editable.customerIsParent,
    (item) => item.editable.id !== statNo,
  ]);

  return (
    <Card>
      <CardHeader
        text={routePairCode}
        contentLeft={
          <FlatButton
            leftIcon={expanded ? stenaAngleDown : stenaAngleRight}
            onClick={() => setExpanded((expanded) => !expanded)}
          />
        }
        contentRight={
          <Row alignItems={"center"}>
            {yearIsEditable && (
              <>
                <FlatButton label={"Copy 12m vol"} onClick={onClickCopy} />
                <Space />
                <FlatButton
                  label={"Clear expected volume"}
                  disabled={!routePairEntity.routePairMeta.hasVolume}
                  onClick={onClickClear}
                />
              </>
            )}
          </Row>
        }
      />
      {expanded && (
        <Box overflowX={"auto"}>
          <Box display={"table"}>
            <StandardTable config={config} items={sortedItems} />
          </Box>
        </Box>
      )}
    </Card>
  );
};
