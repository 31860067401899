import React, { Fragment } from "react";
import { Indent, Row } from "@stenajs-webui/core";
import { RadioButtonWithLabel } from "@stenajs-webui/forms";
import { InputSpinner } from "@stenajs-webui/elements";
import { ErrorMessage } from "../../validation-error-message/ErrorMessage";
import { testIdConstants } from "@/common/test-id-constants/testIdConstants";
import { CurrencyRadioButtonRowQuery } from "@/gql/graphql";
import { gql, useQuery } from "@apollo/client";

const noneCurrencyOption = [
  {
    name: "None",
    id: "NONE",
  },
];
interface Props {
  value?: string;
  noneOptionEnabled?: boolean;
  onValueChange: (value: string) => void;
}

const query = gql`
  query CurrencyRadioButtonRow {
    productPrice {
      currency {
        all {
          id
          name
        }
      }
    }
  }
`;

export const CurrencyRadioButtonRow: React.FC<Props> = ({
  value = [],
  noneOptionEnabled,
  onValueChange,
}) => {
  const { data, error, loading } = useQuery<CurrencyRadioButtonRowQuery>(query);
  const currencies = data?.productPrice.currency.all;

  const currenciesWithNoneOption = noneCurrencyOption.concat(currencies ?? []);

  const currencyOptions = noneOptionEnabled
    ? currenciesWithNoneOption
    : currencies;

  return (
    <Row alignItems={"center"}>
      {loading && <InputSpinner />}
      {error && <ErrorMessage text={error.message} />}
      {currencyOptions &&
        currencyOptions.map((currency, i) => (
          <Fragment key={currency.id}>
            {i > 0 && <Indent num={1.5} />}
            <RadioButtonWithLabel
              id={currency.id}
              label={currency.name}
              checked={value === currency.id}
              onValueChange={() => onValueChange(currency.id)}
              data-testid={testIdConstants.createRouteAgreementCurrencySelector}
            />
          </Fragment>
        ))}
    </Row>
  );
};
