import { Box } from "@stenajs-webui/core";
import * as React from "react";
import { Link } from "../../../../../common/navigation/Link";
import styles from "../LinkCell.module.css";

interface Props {
  label: string;
  to: string;
}

export const LinkCell: React.FC<Props> = function LinkCell({ label, to }) {
  return (
    <Box className={styles.linkCell} display={"initial"} indent={1}>
      <Link title={label} to={to}>
        {label}
      </Link>
    </Box>
  );
};
