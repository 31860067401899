import { BookingStatusOptions } from "@/common/collections/HandlingBookingStatuses";
import { IndentValues } from "@/common/utils/IndentValues";
import { ZIndex } from "@/common/utils/ZIndexEnum";
import { Box, Row, SeparatorLine } from "@stenajs-webui/core";
import { CardHeader, FlatButton, stenaCheck } from "@stenajs-webui/elements";
import { cssColor } from "@stenajs-webui/theme";
import { useContext, useEffect, useMemo } from "react";
import { batch, useDispatch, useSelector } from "react-redux";
import { FreightPricingPortalError } from "../../../common/error/models/FreightPricingPortalError";
import { NoShowLateHandlingHandleBookingsBulkEditing } from "./components/NoShowLateHandlingHandleBookingsBulkEditing";
import {
  checkAllBookingsForCustomer,
  clearSelectionState,
} from "./redux/reducers";
import { createNoShowLateHandlingBookingSelectionStateSelectors } from "./redux/selectors";
import { FppSearchFilter } from "@/features/search-filter/components/FppSearchFilter";
import { SearchFilterContext } from "@stenajs-webui/filter";
import { createFppSearchFilterActions } from "@/features/search-filter/redux/reducer";
import {
  SearchFilterSelectorContext,
  SearchFilterSelectorContextType,
} from "@/features/search-filter/context/searchFilterSelectorContext";
import { NSHLHFilterSettings } from "./config/NoShowLateHandlingHandleBookingsFilterConfig";
import { useFilterWorkspace } from "@/features/search-filter/hooks/UseFilterWorkspace";
import { FppFilterSettings } from "@/features/search-filter/types/FppFilterSettings";
import { NslhBookingDataFragment } from "@/gql/graphql";
import { routeFactory } from "@/RouteFactory";
import { testIdConstants } from "@/common/test-id-constants/testIdConstants";

interface Props {
  customers: number;
  bookings: number;
  loading?: boolean;
  noShowLateHandlingBookingsData: Array<NslhBookingDataFragment>;
  localErrors: FreightPricingPortalError[];
  setLocalErrors: React.Dispatch<
    React.SetStateAction<FreightPricingPortalError[]>
  >;
}

const resolveAllCheckboxSelectionLabel = (
  totalNumberOfBookings: number,
  numberOfSelectedBookings: number
) => {
  return totalNumberOfBookings > 0 &&
    totalNumberOfBookings === numberOfSelectedBookings
    ? "Deselect all"
    : "Select all";
};

export const NoShowLateHandlingHandleBookingsTableHeader: React.FC<Props> = ({
  customers = 0,
  bookings = 0,
  loading = false,
  noShowLateHandlingBookingsData,
  localErrors,
  setLocalErrors,
}) => {
  const { workspaceState } = useContext(
    SearchFilterSelectorContext
  ) as SearchFilterSelectorContextType;

  const dispatch = useDispatch();
  const numberOfSelectedBookings = useSelector(
    createNoShowLateHandlingBookingSelectionStateSelectors.getNumberOfSelectedBookings
  );
  useEffect(() => {
    if (numberOfSelectedBookings === 0) {
      setLocalErrors([]);
    }
  }, [numberOfSelectedBookings, setLocalErrors]);

  const selectedAllBookings = () => {
    batch(() => {
      noShowLateHandlingBookingsData.forEach((bd) => {
        // If the name for status 'not charge' changes in database in the future the string comparison from the enum down below has to be renamed.
        const customerBookingsWithStatusElseThanNotChargeable =
          bd.customerBookings.filter(
            (bk) => bk.handlingStatus !== BookingStatusOptions.NOTCHARGE
          );

        dispatch(
          checkAllBookingsForCustomer({
            customerNo: bd.id,
            bookingNos: customerBookingsWithStatusElseThanNotChargeable.map(
              (cb) => cb.bookingNo
            ),
          })
        );
      });
    });
  };

  const deselectAllBookings = () => {
    dispatch(clearSelectionState());
  };

  const totalNumberOfBookings = useMemo(() => {
    // If the name for status 'not charge' changes in database in the future the string comparison from the enum down below has to be renamed.
    return noShowLateHandlingBookingsData
      .flatMap((data) => data.customerBookings)
      .filter((cb) => cb.handlingStatus !== BookingStatusOptions.NOTCHARGE)
      .length;
  }, [noShowLateHandlingBookingsData]);

  const resolveAllCheckboxSelectionHandler = () => {
    return totalNumberOfBookings > 0 &&
      totalNumberOfBookings === numberOfSelectedBookings
      ? deselectAllBookings
      : selectedAllBookings;
  };
  const borderTop = `1px solid ${cssColor("--lhds-color-ui-300")}`;

  return (
    <Box
      flexDirection={"column"}
      justifyContent={"space-between"}
      //FIXME: when CardHeader has the necessary props for sticky.
      background={cssColor("--lhds-color-ui-50")}
      top={0}
      left={0}
      zIndex={ZIndex.lowest}
      position={"sticky"}
      borderRadius={"var(--swui-border-radius)"}
    >
      <CardHeader
        text={
          loading
            ? "Loading..."
            : `${customers} Customers, ${bookings} Bookings`
        }
        contentAfterHeading={
          <FlatButton
            leftIcon={stenaCheck}
            label={resolveAllCheckboxSelectionLabel(
              totalNumberOfBookings,
              numberOfSelectedBookings
            )}
            onClick={resolveAllCheckboxSelectionHandler()}
          />
        }
      />

      {numberOfSelectedBookings ? (
        <>
          <NoShowLateHandlingHandleBookingsBulkEditing
            bookingsSelected={numberOfSelectedBookings}
            localErrors={localErrors}
            setLocalErrors={setLocalErrors}
          />
          <SeparatorLine color={cssColor("--lhds-color-ui-300")} />
        </>
      ) : (
        <>
          <Row indent={IndentValues.TWENTYFOUR} borderTop={borderTop}>
            <Row alignItems={"center"} height="56px">
              <SearchFilterContext
                state={workspaceState}
                actions={createFppSearchFilterActions(
                  "noShowLateHandlingHandleBookings"
                )}
                dispatch={dispatch}
              >
                <FppSearchFilterWrapper settings={NSHLHFilterSettings} />
              </SearchFilterContext>
            </Row>
          </Row>
        </>
      )}
      <SeparatorLine color={cssColor("--lhds-color-ui-300")} />
    </Box>
  );
};

const FppSearchFilterWrapper = ({
  settings,
}: {
  settings: FppFilterSettings;
}) => {
  const { clearCommittedState, clearFilter } = useFilterWorkspace();
  const handleBookingsUrl = routeFactory.noShowLateHandling.handleBookings();

  useEffect(() => {
    return () => {
      if (!window.location.href.includes(handleBookingsUrl)) {
        clearCommittedState();
        clearFilter();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FppSearchFilter
      settings={settings}
      filterButtonDataTestId={testIdConstants.nSLHHandleBookingsFilterButton}
    />
  );
};
