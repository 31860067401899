import { Box, Indent, Row } from "@stenajs-webui/core";
import { RadioButtonWithLabel } from "@stenajs-webui/forms";
import React, { Fragment } from "react";
import { FormModelChangeHandler } from "../../../common/forms/types";
import { PaddedHeading } from "../../../common/components/padded-heading/PaddedHeading";
import {
  MovementType,
  MovementTypeLabels,
  PriceCalculatorFormData,
} from "../PriceCalculatorFormFetcher";
import { PriceCalculatorSelectMultiLegRoute } from "./price-calculator-form-sections/PriceCalculatorSelectMultiLegRoute";
import { testIdConstants } from "@/common/test-id-constants/testIdConstants";

interface Props {
  formData: PriceCalculatorFormData;
  onChange: FormModelChangeHandler<PriceCalculatorFormData>;
}
export const PriceCalculatorMovementTypeSelect: React.FC<Props> = ({
  formData,
  onChange,
}) => {
  return (
    <Box indent={4} spacing>
      <PaddedHeading padding={2} variant={"h4"}>
        Select movement type
      </PaddedHeading>
      <Row alignItems={"center"} height={"2rem"}>
        {Object.keys(MovementType).map((type, i) => (
          <Fragment key={type}>
            {i > 0 && <Indent num={1.5} />}
            <RadioButtonWithLabel
              label={MovementTypeLabels.get(type) ?? ""}
              checked={formData.movementType === (type as MovementType)}
              value={type}
              data-testid={testIdConstants.priceCalculatorMovementTypeSelection}
              onValueChange={(value) => {
                const selectedMovementType = value as MovementType;
                onChange("movementType", selectedMovementType);
              }}
            />
          </Fragment>
        ))}
        {formData.movementType === MovementType.MULTILEG && (
          <PriceCalculatorSelectMultiLegRoute
            onChangeFormData={onChange}
            formData={formData}
          />
        )}
      </Row>
    </Box>
  );
};
