import {
  InternalInvoiceSpecificationQuery,
  InternalInvoiceSpecificationQueryVariables,
} from "@/gql/graphql";
import { gql, useQuery } from "@apollo/client";

const InternalInvoiceSpecificationsQuery = gql`
  fragment NshlInvoiceSpecification on NslhInvoice {
    id
    invoiceNo
    consignmentNo
    customer {
      id
      custNo
      name
      custIndex
      invoiceCurrency {
        id
        code
      }
    }
    invoiceDate
    netAmount
    nslhMonth
    invoiceCurrencyCode
    nslhYear
    routeCodeBreak
    thresholdNoShow
    thresholdLateHandling
    routeVolumes {
      id
      shippedVolume
      routePair {
        code
        id
      }
    }
    nslhBookings {
      id
      customer {
        id
        custNo
      }
      consignmentNo
      articlePrice
      bookingNo
      bookingVersion
      customerReference
      departureDate
      departureTime
      handlingStatus
      handlingType
      handlingTypeGrouped
      revisedTime
      revisedDate
      trailerReg
      vehicleReg
      vehicleType {
        id
        shortDescription
      }
      lateHandlingUser
      lateHandlingDate
      route {
        id
        code
        routePair {
          id
          code
          description
        }
      }
    }
  }
  query InternalInvoiceSpecification($invoiceNo: ID!) {
    productPrice {
      noShowLateHandling {
        nslhInvoice {
          byId(id: $invoiceNo) {
            ...NshlInvoiceSpecification
          }
        }
      }
    }
  }
`;

export const useFetchInvoiceSpecification = (invoiceNumber: string) => {
  const { data, error, loading } = useQuery<
    InternalInvoiceSpecificationQuery,
    InternalInvoiceSpecificationQueryVariables
  >(InternalInvoiceSpecificationsQuery, {
    fetchPolicy: "cache-and-network",
    variables: {
      invoiceNo: invoiceNumber,
    },
  });

  const invoiceSpecifications =
    data?.productPrice.noShowLateHandling.nslhInvoice.byId ?? null;

  return {
    invoiceSpecifications,
    error,
    loading,
  };
};
