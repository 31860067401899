import * as React from "react";
import { RebateView } from "./RebateView";
import { SearchFilterSelectorProvider } from "../search-filter/context/searchFilterSelectorContext";

interface Props {}

export const RebateScreen: React.FC<Props> = () => {
  return (
    <>
      <SearchFilterSelectorProvider reducerId={"rebateFilter"}>
        <RebateView />
      </SearchFilterSelectorProvider>
    </>
  );
};
