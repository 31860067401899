import { ErrorWithPath } from "@/common/error/BannerErrorWithLinks";
import {
  RoutesValidationQuery,
  RoutesValidationQueryVariables,
} from "@/gql/graphql";
import { LazyQueryExecFunction } from "@apollo/client";
import { uniq } from "lodash";

interface StepConfigurationConfig {
  customerNumber: number;
  headAgreementId: string;
  validateRoutes: LazyQueryExecFunction<
    RoutesValidationQuery,
    RoutesValidationQueryVariables
  >;
  selectedMultiLegRoutesId: string[];
  selectedRoutesId: string[];
  childrenForReport: string[];
  setIsNextAvailable: React.Dispatch<React.SetStateAction<boolean>>;
  setErrorMessages: React.Dispatch<React.SetStateAction<ErrorWithPath[]>>;
}

export const getStepConfigurations = ({
  customerNumber,
  headAgreementId,
  validateRoutes,
  selectedMultiLegRoutesId,
  selectedRoutesId,
  childrenForReport,
  setIsNextAvailable,
  setErrorMessages,
}: StepConfigurationConfig) => {
  const baseConfigurations = [
    {
      title: "Agreement selection",
      validate: async () => {
        let validationResult = false;
        await validateRoutes({
          variables: {
            custNo: customerNumber,
            headAgreementId: headAgreementId,
            multiLegAgreements: selectedMultiLegRoutesId,
            routeAgreements: selectedRoutesId,
          },
          fetchPolicy: "network-only",
          onCompleted: (data) => {
            const validationErrors =
              data?.validateRoutesForAgreement?.validationResult
                ?.validationErrors;
            const statusCode =
              data.validateRoutesForAgreement?.validationResult?.statusCode;
            if (validationErrors?.length === 0 && statusCode === 200) {
              validationResult = true;
            } else {
              if (validationErrors) {
                const errorMessages = validationErrors.map((e) => ({
                  detailText: e?.errorMessage ?? "",
                  path: e?.path ?? "",
                }));
                setErrorMessages(uniq(errorMessages));
                setIsNextAvailable(false);
                validationResult = false;
              }

              validationResult = false;
            }
          },
          onError: (error) =>
            setErrorMessages([
              {
                detailText: error.message ?? "",
              },
            ]),
        });
        return validationResult;
      },
    },
  ];
  return baseConfigurations.concat([
    {
      title:
        childrenForReport.length === 0
          ? "Customer details & specifications"
          : "Specifications",
      validate: async () => true,
    },
    {
      title: "Conditions & last page info",
      validate: async () => true,
    },
    {
      title: "Surcharges",
      validate: async () => true,
    },
  ]);
};
