import * as React from "react";
import { ReactNode, useState } from "react";
import {
  Box,
  Column,
  Indent,
  Row,
  Space,
  Spacing,
  Text,
  Txt,
} from "@stenajs-webui/core";
import { DateInput } from "@stenajs-webui/calendar";
import { FlatButton, PrimaryButton } from "@stenajs-webui/elements";
import { Checkbox } from "@stenajs-webui/forms";
import { Window } from "@stenajs-webui/modal";
import { DateRange } from "@/gql/graphql";
import { format, subDays } from "date-fns";

interface Props {
  onSubmit: (breakDate?: Date) => void;
  onRequestClose: () => void;
  setBreakeDateChecked: React.Dispatch<React.SetStateAction<boolean>>;
  errorBanner?: ReactNode;
  loading?: boolean;
  breakDateChecked: boolean;
  headAgreementValidDate?: DateRange;
}

export const CopyRouteAgreementModal: React.FC<Props> = ({
  onSubmit,
  onRequestClose,
  setBreakeDateChecked,
  errorBanner,
  loading,
  breakDateChecked,
  headAgreementValidDate,
}) => {
  const [breakDate, setBreakDate] = useState<Date | undefined>(new Date());
  const breakDateMinDate =
    headAgreementValidDate?.start?.isoString ?? undefined;
  const breakDateMaxDate = headAgreementValidDate?.end?.isoString
    ? format(
        subDays(new Date(headAgreementValidDate?.end?.isoString), 1),
        "yyyy-MM-dd"
      )
    : undefined;

  const handleSubmit = () => {
    onSubmit(breakDate);
  };

  return (
    <Window
      isOpen={true}
      width={"500px"}
      onRequestClose={onRequestClose}
      headerText={"Copy route agreement"}
    >
      <Column indent spacing>
        <Column indent spacing>
          <Text variant={"bold"}>Overlapping agreements are not allowed.</Text>
          <Spacing />
          <Text>
            The new agreement will be valid from the day after the selected
            break date.
          </Text>
          <Spacing num={4}>
            <Box width={"30%"}>
              <Row alignItems={"center"}>
                <Checkbox
                  checked={breakDateChecked}
                  onValueChange={(value) => setBreakeDateChecked(value)}
                />
                <Space />
                <Txt>{"Set break date"}</Txt>
              </Row>
              <Space />
              <DateInput
                value={breakDate}
                onChange={setBreakDate}
                disabled={!breakDateChecked}
                minDate={breakDateMinDate}
                maxDate={breakDateMaxDate}
              />
            </Box>
          </Spacing>
          <Row justifyContent={"flex-end"}>
            <FlatButton onClick={onRequestClose} label={"Cancel"} />
            <Indent />
            <PrimaryButton
              onClick={handleSubmit}
              label={"Copy agreement"}
              loading={loading}
            />
          </Row>
        </Column>
        {errorBanner && (
          <>
            <Space num={4} />
            {errorBanner}
          </>
        )}
      </Column>
    </Window>
  );
};
