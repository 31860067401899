import { IndentValues } from "@/common/utils/IndentValues";
import { SpacingValues } from "@/common/utils/SpacingValues";
import { css, Global } from "@emotion/react";
import { Row, SeparatorLine } from "@stenajs-webui/core";
import React from "react";
import { Helmet } from "react-helmet";
import { NslhPageHeader } from "../NslhPageHeader";
import { InvoiceSpecificationList } from "./components/InvoiceSpecificationList";
import { SearchInvoiceFilterBar } from "./components/SearchInvoiceFilterBar";

export const SearchInvoicesScreen: React.FC = () => {
  return (
    <>
      <Global
        styles={css`
          body {
            display: table;
          }
        `}
      />
      <Helmet title="Invoice specification - NSLH - FPP" />
      <NslhPageHeader
        actionBar={<SearchInvoiceFilterBar />}
        headingText={"Follow up"}
      />
      <SeparatorLine />
      <Row indent={IndentValues.TWENTYFOUR} spacing={SpacingValues.TWENTYFOUR}>
        <InvoiceSpecificationList />
      </Row>
    </>
  );
};
