import { SpaceValues } from "@/common/utils/SpaceValues";
import { Row, Space } from "@stenajs-webui/core";
import {
  Banner,
  Card,
  CardBody,
  CardHeader,
  PrimaryButton,
  SecondaryButton,
} from "@stenajs-webui/elements";
import * as React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { routeFactory } from "../../../../../RouteFactory";
import { useFadeState } from "../../../../../common/hooks/UseFadedState";
import { HeadAgreementForm } from "../../common/components/HeadAgreementForm";
import { HeadAgreementFormModel } from "../../common/models/HeadAgreementFormModel";
import { useUpdateHeadAgreementMutation } from "../hooks/UseUpdateHeadAgreementMutation";
import { transformHeadAgreementFormToInput } from "../transformers/HeadAgreementInputTransformer";
import { testIdConstants } from "@/common/test-id-constants/testIdConstants";

interface Props {
  initialFormModel: HeadAgreementFormModel;
  headAgreementId: string;
  customerId: string;
  rowVersion: string;
}

export const HeadAgreementFormContainer: React.FC<Props> = ({
  initialFormModel,
  headAgreementId,
  customerId,
  rowVersion,
}) => {
  const navigate = useNavigate();
  const [localError, setLocalError] = useState<Error | undefined>();
  const [success, setSuccess] = useFadeState(false, 5000);
  const {
    error: mutationError,
    loading,
    updateHeadAgreement,
  } = useUpdateHeadAgreementMutation();
  const [changed, setChanged] = useState(false);
  const [formModel, setFormModel] = useState(initialFormModel);

  const handleChangeFormModel: typeof setFormModel = (value) => {
    setFormModel(value);
    setChanged(true);
  };

  const onSubmit = async () => {
    setLocalError(undefined);
    try {
      const input = transformHeadAgreementFormToInput(
        headAgreementId,
        rowVersion,
        formModel
      );
      const response = await updateHeadAgreement(input);

      const updateResult =
        response.data?.productPrice.headAgreement.updateHeadAgreement;
      if (updateResult && "errors" in updateResult) {
        setLocalError(
          new Error(
            updateResult.errors.map((error) => error.message).join("\n")
          )
        );
      } else {
        setSuccess(true);
        setChanged(false);
        await navigate(
          routeFactory.productAndPrice.customer.customerHeadAgreement({
            headAgreementId,
            customerId,
          })
        );
      }
    } catch (e) {
      setLocalError(e);
    }
  };

  const error = mutationError || localError;

  return (
    <Card>
      <CardHeader text={"Options"} />
      <CardBody>
        <HeadAgreementForm
          value={formModel}
          onValueChange={handleChangeFormModel}
          isUpdateForm
        />

        {error && (
          <Row justifyContent={"flex-end"}>
            <Banner
              variant={"error"}
              headerText={"Unable to save header agreement"}
              text={error.message}
            />
          </Row>
        )}
        <Space num={SpaceValues.SIXTEEN} />
        <Row justifyContent={"flex-end"}>
          <SecondaryButton
            onClick={() => navigate(-1)}
            label={"Cancel"}
            data-testid={testIdConstants.editHeaderAgreementCancelButton}
          />
          <Space num={SpaceValues.SIXTEEN} />
          <PrimaryButton
            onClick={onSubmit}
            success={success}
            disabled={!changed}
            label={"Save agreement"}
            loading={loading}
            data-testid={testIdConstants.editHeaderAgreementSaveAgreementButton}
          />
        </Row>
      </CardBody>
    </Card>
  );
};
