import { SpacingValues } from "@/common/utils/SpacingValues";
import { Box } from "@stenajs-webui/core";
import { Banner, Card, CardBody, Tab, TabMenu } from "@stenajs-webui/elements";
import React, { useState } from "react";
import {
  CustomerSearchResultData,
  MovementType,
  SelectedTab,
} from "../../PriceCalculatorFormFetcher";
import { useRoute } from "../../hooks/UseRoute";
import { PriceCalculatorAuditTab } from "../components/PriceCalculatorAuditTab";
import { transformCustomerResultToAuditData } from "../transformers/TransformToAuditData";
import { PriceCalculatorCustomerDetailMultiLegTab } from "./PriceCalculatorCustomerDetailMultiLegTab";
import { PriceCalculatorCustomerDetailSectionalTab } from "./PriceCalculatorCustomerDetailSectionalTab";

interface Props {
  customerPriceCalcResult?: CustomerSearchResultData;
  priceUpdatePending?: boolean;
}

export const PriceCalculatorCustomerSearchResultPane: React.FC<Props> = ({
  customerPriceCalcResult,
  priceUpdatePending = false,
}) => {
  const { error: routeError, data: routeData } = useRoute(
    customerPriceCalcResult?.formData.routeCode
  );
  const routeName = routeData?.productPrice.route.byId?.name;

  const priceTabDisabled =
    customerPriceCalcResult &&
    customerPriceCalcResult.priceCalcData.some((data) => data.totalPrice === 0);

  const [selectedTab, setSelectedTab] = useState<SelectedTab>(
    priceTabDisabled ? SelectedTab.AUDIT : SelectedTab.PRICE
  );

  const displaySectionalDetailsTab =
    selectedTab === SelectedTab.PRICE &&
    customerPriceCalcResult &&
    customerPriceCalcResult.formData.movementType === MovementType.SECTIONAL;

  const displayMultiLegDetailsTab =
    selectedTab === SelectedTab.PRICE &&
    customerPriceCalcResult &&
    customerPriceCalcResult.formData.movementType === MovementType.MULTILEG;

  return (
    <Card>
      <CardBody>
        <TabMenu>
          <Tab
            disabled={priceTabDisabled}
            label={"Price details"}
            selected={selectedTab === SelectedTab.PRICE}
            onClick={() => setSelectedTab(SelectedTab.PRICE)}
          />
          <Tab
            label={"Audit track"}
            selected={selectedTab === SelectedTab.AUDIT}
            onClick={() => setSelectedTab(SelectedTab.AUDIT)}
          />
        </TabMenu>

        {priceUpdatePending && (
          <Box spacing={SpacingValues.TWENTY}>
            <Banner
              headerText={"Customer and/or sailing details have been changed"}
              text={"You need to recalculate the price"}
              variant={"info"}
            />
          </Box>
        )}
        {routeError && (
          <Box spacing={SpacingValues.TWENTY}>
            <Banner
              headerText={"Could not load route"}
              text={"Tried to load error, but there was a problem"}
              variant={"error"}
            />
          </Box>
        )}
      </CardBody>
      {customerPriceCalcResult && routeName && (
        <>
          {displaySectionalDetailsTab && (
            <PriceCalculatorCustomerDetailSectionalTab
              customerSearchResultData={customerPriceCalcResult}
              routeName={routeName}
            />
          )}
          {displayMultiLegDetailsTab && (
            <PriceCalculatorCustomerDetailMultiLegTab
              customerSearchResultData={customerPriceCalcResult}
            />
          )}
          {selectedTab === SelectedTab.AUDIT && customerPriceCalcResult && (
            <PriceCalculatorAuditTab
              auditData={customerPriceCalcResult.priceCalcData.map(
                (priceCalc) =>
                  transformCustomerResultToAuditData(
                    priceCalc,
                    customerPriceCalcResult?.formData
                  )
              )}
            />
          )}
        </>
      )}
    </Card>
  );
};
