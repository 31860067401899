import { useEffect, useState } from "react";

export const useGetContainerWidth = (
  rowContainerRef: React.RefObject<HTMLDivElement>
) => {
  const [rowContainerWidth, setRowContainerWidth] = useState<number>();

  useEffect(() => {
    if (!rowContainerRef.current) {
      return;
    }

    const resizeObserver = new ResizeObserver(() => {
      if (rowContainerRef?.current?.offsetWidth !== rowContainerWidth) {
        setRowContainerWidth(rowContainerRef?.current?.offsetWidth);
      }
    });

    resizeObserver.observe(rowContainerRef.current);

    return function cleanup() {
      resizeObserver.disconnect();
    };
  }, [rowContainerRef, rowContainerWidth]);

  const containerWidth =
    rowContainerWidth && rowContainerWidth <= 2000 ? "100%" : "50%";

  return { containerWidth };
};
