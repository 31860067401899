import * as React from "react";
import { useMemo } from "react";
import {
  TableCell,
  TableHeadItem,
  TableHeadRow,
  TableRow,
} from "@stenajs-webui/grid";
import { HeadAgreementStatusCodeChip } from "../../../../../common/components/chips/HeadAgreementStatusCodeChip";
import { Box, Row, Text } from "@stenajs-webui/core";
import { orderBy } from "lodash";
import { Banner, Spinner } from "@stenajs-webui/elements";
import { ErrorScreen } from "@stenajs-webui/panels";
import { ApolloError } from "apollo-boost";
import { formatGQLDateRange } from "@/common/formatters/DateFormatter";
import { cssColor } from "@stenajs-webui/theme";
import { SwitchHeadAgreementAllAgreementsFragment } from "@/gql/graphql";
import { IndentValues } from "@/common/utils/IndentValues";
import { SpacingValues } from "@/common/utils/SpacingValues";

interface Props {
  loading?: boolean;
  headAgreements: SwitchHeadAgreementAllAgreementsFragment[];
  error?: ApolloError | undefined;
  onSelectHeadAgreement: (headAgreementId: string) => void;
  selectedHeadAgreementId?: string;
}

export const HeadAgreementListSwitcher: React.FC<Props> = ({
  headAgreements,
  loading,
  error,
  selectedHeadAgreementId,
  onSelectHeadAgreement,
}) => {
  const orderedHeadAgreements = useMemo(
    () =>
      orderBy(
        headAgreements,
        [(agreement) => agreement.valid.end?.isoString],
        ["desc"]
      ),
    [headAgreements]
  );

  return (
    <Box maxHeight={"85vh"} overflowY={"auto"}>
      <TableHeadRow
        indent={2}
        position={"sticky"}
        top={0}
        height={"40px"}
        background={cssColor("--lhds-color-ui-50")}
        borderBottom={"1px solid var(--lhds-color-ui-300)"}
      >
        <TableHeadItem label={"Status"} width={100} height={"40px"} />
        <TableHeadItem
          label={"Number"}
          width={70}
          height={"40px"}
          justifyContent={"flex-end"}
        />
        <TableHeadItem label={"Validity"} width={200} height={"40px"} />
        <TableHeadItem label={"Name"} flex={1} height={"40px"} />
        <TableHeadItem label={""} width={100} height={"40px"} />
      </TableHeadRow>
      {loading && (
        <Row spacing={8} justifyContent={"center"}>
          <Spinner />
        </Row>
      )}
      {error && (
        <Row spacing={8} justifyContent={"center"}>
          <ErrorScreen text={error.message} />
        </Row>
      )}
      {orderedHeadAgreements.length === 0 && !loading && !error ? (
        <Box
          indent={IndentValues.TWENTYFOUR}
          spacing={SpacingValues.TWENTYFOUR}
        >
          <Banner variant="info" headerText="No Header agreements found" />
        </Box>
      ) : (
        orderedHeadAgreements.map((agreement) => (
          <TableRow
            key={agreement.id}
            indent={2}
            hoverBackground={cssColor("--lhds-color-ui-200")}
            style={{ cursor: "pointer" }}
            height={"40px"}
            borderTop={undefined}
            borderBottom={"1px solid var(--lhds-color-ui-300)"}
            onClick={() => {
              onSelectHeadAgreement(agreement.id);
            }}
          >
            <TableCell width={100} height={"40px"}>
              <HeadAgreementStatusCodeChip statusCode={agreement.statusCode} />
            </TableCell>
            <TableCell width={70} justifyContent={"flex-end"} height={"40px"}>
              <Text>{agreement.headAgreementNumber}</Text>
            </TableCell>
            <TableCell width={200} height={"40px"}>
              <Text>{formatGQLDateRange(agreement.valid)}</Text>
            </TableCell>
            <TableCell flex={1}>
              <Text>{agreement.name}</Text>
            </TableCell>
            <TableCell width={100} height={"40px"}>
              {selectedHeadAgreementId === agreement.id && (
                <Text size="small" color={cssColor("--lhds-color-ui-500")}>
                  Selected
                </Text>
              )}
            </TableCell>
          </TableRow>
        ))
      )}
    </Box>
  );
};
