import {
  FeatureFlagsContext,
  FeatureFlagsContextType,
} from "@/config/featureFlags/FeatureFlagsContext";
import { useContext } from "react";
import { NotificationSubscriber } from "./NotificationSubscriber";

export const NotificationSubscriberWrapper = () => {
  const { featureFlags } = useContext(
    FeatureFlagsContext
  ) as FeatureFlagsContextType;

  const useNotificationSubscription =
    featureFlags.agreement.useReportMessageSubscription;

  return <>{useNotificationSubscription ? <NotificationSubscriber /> : null}</>;
};
