import { useFilterWorkspace } from "@/features/search-filter/hooks/UseFilterWorkspace";
import {
  WorkspaceState,
  initialSearchFilterFormModel,
} from "@/features/search-filter/redux/reducer";
import { LazyQueryExecFunction } from "@apollo/client";
import { SeparatorLine } from "@stenajs-webui/core";
import { FlatButton } from "@stenajs-webui/elements";
import {
  StandardTable,
  createStandardTableInitialState,
  useLocalStateTableContext,
} from "@stenajs-webui/grid";
import { ApolloError } from "apollo-boost";
import { useEffect, useMemo, useState } from "react";
import { useNegotiationYears } from "../../../product-and-price/customers/common/negotiation-year/NegotiationYearGate";
import {
  StatisticsAdvancedDataListColumns,
  createByAdvancedResultTableConfig,
} from "../config/StatisticsByAdvancedTableConfig";
import { transformAdvancedStatisticsDataFilterResultToTableRow } from "../transformers/TransformResultToTableRow";
import {
  Exact,
  StatisticsSearchAdvancedListByFilterOptions,
  UseAdvancedStatisticsDataListQuery,
} from "@/gql/graphql";

interface Props {
  committedState: WorkspaceState;
  currentCurrency: string;
  error: ApolloError | undefined;
  advancedStatisticsData:
    | UseAdvancedStatisticsDataListQuery["statistics"]["advancedList"]["byFilter"]["items"]
    | undefined;
  loadAdvancedStatisticsData: LazyQueryExecFunction<
    UseAdvancedStatisticsDataListQuery,
    Exact<{
      byFilterFilterOptions: StatisticsSearchAdvancedListByFilterOptions;
    }>
  >;
  loading: boolean;
}

export const StatisticsByAdvancedTable: React.FC<Props> = ({
  committedState,
  currentCurrency,
  error,
  advancedStatisticsData,
  loadAdvancedStatisticsData,
  loading,
}) => {
  const [prevCurrency, setPrevCurrency] = useState<string | null>(null);

  const { openFilter } = useFilterWorkspace();
  const { defaultYear } = useNegotiationYears();

  const tableRowItems = useMemo(
    () =>
      advancedStatisticsData?.map((csd) =>
        transformAdvancedStatisticsDataFilterResultToTableRow(csd)
      ),
    [advancedStatisticsData]
  );

  const { tableContext } = useLocalStateTableContext(
    "advanced-statistics-table-context",
    createStandardTableInitialState<StatisticsAdvancedDataListColumns>(
      "customerName",
      false
    )
  );

  const resultConfig = useMemo(() => {
    return createByAdvancedResultTableConfig(currentCurrency);
  }, [currentCurrency]);

  useEffect(() => {
    const initialStateWithDefaultYear = {
      ...initialSearchFilterFormModel,
      year: defaultYear,
    };
    const defaultFilterString = JSON.stringify(initialStateWithDefaultYear);
    const committedFilterString = JSON.stringify(committedState);

    if (
      defaultFilterString !== committedFilterString ||
      (prevCurrency !== currentCurrency && prevCurrency !== null)
    ) {
      setPrevCurrency(currentCurrency);
      loadAdvancedStatisticsData();
    }
  }, [
    committedState,
    loadAdvancedStatisticsData,
    currentCurrency,
    prevCurrency,
    defaultYear,
  ]);

  return (
    <>
      <StandardTable
        loading={loading}
        error={error}
        items={tableRowItems ?? []}
        config={resultConfig}
        noItemsContentRight={
          <FlatButton label={"Open filter"} onClick={() => openFilter()} />
        }
        tableContext={tableContext}
        noItemsHeader={"Search for advanced statistics data."}
        noItemsLabel={
          "Please use the filters to search for advanced statistics data."
        }
      />
      {tableRowItems && !loading ? <SeparatorLine /> : null}
    </>
  );
};
