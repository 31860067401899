import { formatThousandsAndDecimals } from "@/common/numbers";
import { Text } from "@stenajs-webui/core";
import { createColumnConfig, StandardTableConfig } from "@stenajs-webui/grid";
import { Tooltip } from "@stenajs-webui/tooltip";
import { transformSailingTypeToSingleLetter } from "../../../../../../common/transformers/SailingTypeTransformer";
import { transformAccUnaccEntityToSingleLetter } from "../../../../../../common/transformers/UnaccompaniedTransformer";
import { createRoutePairCustomerTargetStandardTableCell } from "../RoutePairCustomerTargetStandardTableCell";
import { createRoutePairCustomerTargetTargetTableCell } from "../RoutePairCustomerTargetTableCell";
import {
  currencyColumnOrder,
  customerInfoColumnOrder,
  customerNameColumnOrder,
  noLabelGroupTertiaryOrder,
  sailingUnitSpecificColumnOrder,
  statInfoColumnOrder,
} from "./RoutePairCustomerTargetTableColumnGroups";
import {
  categoryColumnValueResolver,
  countryColumnValueResolver,
  customerNoColumnValueResolver,
  customerNVolumeValueResolver,
  customerRVolumeValueResolver,
  customerVolumeColumnValueResolver,
  getHeaderRowOffsetTop,
  grossColumnValueResolver,
  nameColumnValueResolver,
  netColumnValueResolver,
  sailingCatVolumeColumnValueResolver,
  salesRegionValueResolver,
  sellerColumnValueResolver,
  statNoColumnValueResolver,
  statNVolumeColumnValueResolver,
  statRVolumeColumnValueResolver,
  statVolumeColumnValueResolver,
  targetColumnValueResolver,
  unitTypeColumnValueResolver,
} from "./RoutePairTargetColumnHelperResolver";
import {
  AllCustomerTableColumnKeys,
  AllCustomerTargetsTableRow,
} from "./RowsAndColumnsConfig";

export type EditableRoutePairCustomerTargetTableFields = "target";

export const createRoutePairCustomerTargetTableConfig = (
  currency: string,
  routeStatusDone: boolean = false,
  isSortingDisabled: boolean,
  isRowsSelected: boolean,
  isDataModified: boolean,
  isWarningVisible: boolean
) => {
  const allRoutesTableConfig: StandardTableConfig<
    AllCustomerTargetsTableRow,
    AllCustomerTableColumnKeys
  > = {
    initialSortOrder: "name",
    enableGridCell: true,
    stickyHeader: true,
    showRowCheckbox: true,
    showHeaderCheckbox: true,
    headerRowOffsetTop: getHeaderRowOffsetTop(isRowsSelected, isWarningVisible),
    stickyCheckboxColumn: true,
    stickyColumnGroups: "both",
    disableSorting: isSortingDisabled || isRowsSelected || isDataModified,
    keyResolver: (item) => item.id,
    columns: {
      fillFirst: createColumnConfig((item) => "", {
        columnLabel: "",
        width: routeStatusDone ? "24px" : "0px",
        disableGridCell: true,
      }),
      name: {
        itemValueResolver: nameColumnValueResolver,
        columnLabel: "Name",
        justifyContentHeader: "flex-start",
        justifyContentCell: "flex-start",
        disableGridCell: true,
        renderCell: ({ label, value, item }) =>
          createRoutePairCustomerTargetStandardTableCell(item.name) && (
            <Tooltip label={label} appendTo={document.body}>
              <div
                style={{
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  width: "200px",
                  overflow: "hidden",
                }}
              >
                <Text>{label}</Text>
              </div>
            </Tooltip>
          ),
        width: "200px",
      },
      country: createColumnConfig(countryColumnValueResolver, {
        columnLabel: "Country",
        justifyContentHeader: "flex-end",
        justifyContentCell: "flex-end",
        disableGridCell: true,
        renderCell: ({ label, value, item }) =>
          createRoutePairCustomerTargetStandardTableCell(item.country),
        minWidth: "85px",
      }),
      customerNo: createColumnConfig(customerNoColumnValueResolver, {
        columnLabel: "No.",
        justifyContentHeader: "flex-end",
        justifyContentCell: "flex-end",
        disableGridCell: true,
        renderCell: ({ label, value, item }) =>
          createRoutePairCustomerTargetStandardTableCell(item.customerNo),
        minWidth: "65px",
      }),
      customerNVolume: createColumnConfig(customerNVolumeValueResolver, {
        columnLabel: "N vol.",
        justifyContentHeader: "flex-end",
        justifyContentCell: "flex-end",
        disableGridCell: true,
        renderCell: ({ label, value, item }) =>
          createRoutePairCustomerTargetStandardTableCell(
            item.customerNVolume,
            false,
            (value) => formatThousandsAndDecimals(value)
          ),
        minWidth: "75px",
      }),
      customerRVolume: createColumnConfig(customerRVolumeValueResolver, {
        columnLabel: "R vol.",
        justifyContentHeader: "flex-end",
        justifyContentCell: "flex-end",
        disableGridCell: true,
        renderCell: ({ label, value, item }) =>
          createRoutePairCustomerTargetStandardTableCell(
            item.customerRVolume,
            false,
            (value) => formatThousandsAndDecimals(value)
          ),
        minWidth: "75px",
      }),
      category: createColumnConfig(categoryColumnValueResolver, {
        columnLabel: "Cat.",
        justifyContentHeader: "flex-end",
        justifyContentCell: "flex-end",
        disableGridCell: true,
        renderCell: ({ label, value, item }) =>
          createRoutePairCustomerTargetStandardTableCell(
            item.category.toUpperCase()
          ),
        minWidth: "60px",
      }),
      salesRegion: createColumnConfig(salesRegionValueResolver, {
        columnLabel: "Region",
        justifyContentHeader: "flex-end",
        justifyContentCell: "flex-end",
        disableGridCell: true,
        renderCell: ({ label, value, item }) =>
          createRoutePairCustomerTargetStandardTableCell(item.salesRegion),
        minWidth: "80px",
      }),
      seller: createColumnConfig(sellerColumnValueResolver, {
        columnLabel: "Seller",
        justifyContentHeader: "flex-end",
        justifyContentCell: "flex-end",
        disableGridCell: true,
        renderCell: ({ label, value, item }) =>
          createRoutePairCustomerTargetStandardTableCell(
            value.toUpperCase().replace("LINE\\", "")
          ),
        minWidth: "75px",
      }),
      statNo: createColumnConfig(statNoColumnValueResolver, {
        columnLabel: "No",
        justifyContentHeader: "flex-end",
        justifyContentCell: "flex-end",
        disableGridCell: true,
        renderCell: ({ label, value, item }) =>
          createRoutePairCustomerTargetStandardTableCell(item.statNo),
        minWidth: "60px",
      }),
      statNVolume: createColumnConfig(statNVolumeColumnValueResolver, {
        columnLabel: "N vol.",
        justifyContentHeader: "flex-end",
        justifyContentCell: "flex-end",
        disableGridCell: true,
        renderCell: ({ label, value, item }) =>
          createRoutePairCustomerTargetStandardTableCell(
            item.statNVolume,
            false,
            (value) => formatThousandsAndDecimals(value)
          ),
        minWidth: "75px",
      }),
      statRVolume: createColumnConfig(statRVolumeColumnValueResolver, {
        columnLabel: "R vol.",
        justifyContentHeader: "flex-end",
        justifyContentCell: "flex-end",
        disableGridCell: true,
        renderCell: ({ label, value, item }) =>
          createRoutePairCustomerTargetStandardTableCell(
            item.statRVolume,
            false,
            (value) => formatThousandsAndDecimals(value)
          ),
        minWidth: "75px",
      }),
      sailingCat: createColumnConfig(sailingCatVolumeColumnValueResolver, {
        columnLabel: "Sailing",
        justifyContentHeader: "flex-end",
        justifyContentCell: "flex-end",
        disableGridCell: true,
        renderCell: ({ label, value, item }) =>
          createRoutePairCustomerTargetStandardTableCell(
            transformSailingTypeToSingleLetter(item.sailingCat ?? "")
          ),
        minWidth: "70px",
      }),
      unitType: createColumnConfig(unitTypeColumnValueResolver, {
        columnLabel: "Unit",
        justifyContentHeader: "flex-end",
        justifyContentCell: "flex-end",
        disableGridCell: true,
        renderCell: ({ label, value, item }) =>
          createRoutePairCustomerTargetStandardTableCell(
            transformAccUnaccEntityToSingleLetter(item.unitType ?? "")
          ),
        minWidth: "50px",
      }),
      statVolume: createColumnConfig(statVolumeColumnValueResolver, {
        columnLabel: "Stat vol.",
        justifyContentHeader: "flex-end",
        justifyContentCell: "flex-end",
        disableGridCell: true,
        renderCell: ({ label, value, item }) =>
          createRoutePairCustomerTargetStandardTableCell(
            item.statVolume,
            false,
            (value) => formatThousandsAndDecimals(value)
          ),
        minWidth: "90px",
      }),
      customerVolume: createColumnConfig(customerVolumeColumnValueResolver, {
        columnLabel: "Cust vol.",
        justifyContentHeader: "flex-end",
        justifyContentCell: "flex-end",
        disableGridCell: true,
        renderCell: ({ label, value, item }) =>
          createRoutePairCustomerTargetStandardTableCell(
            item.customerVolume,
            false,
            (value) => formatThousandsAndDecimals(value)
          ),
        minWidth: "95px",
      }),
      net: createColumnConfig(netColumnValueResolver, {
        columnLabel: "Net",
        justifyContentHeader: "flex-end",
        justifyContentCell: "flex-end",
        disableGridCell: true,
        renderCell: ({ label, value, item }) =>
          createRoutePairCustomerTargetStandardTableCell(
            item.net,
            false,
            (value) => formatThousandsAndDecimals(value)
          ),
        minWidth: "64px",
      }),
      gross: createColumnConfig(grossColumnValueResolver, {
        columnLabel: "Gross",
        justifyContentHeader: "flex-end",
        justifyContentCell: "flex-end",
        disableGridCell: true,
        renderCell: ({ label, value, item }) =>
          createRoutePairCustomerTargetStandardTableCell(
            item.gross,
            false,
            (value) => formatThousandsAndDecimals(value)
          ),
        minWidth: "70px",
      }),
      target: createColumnConfig(targetColumnValueResolver, {
        columnLabel: "Target (%)",
        justifyContentHeader: "flex-end",
        justifyContentCell: "flex-end",
        renderCell: routeStatusDone
          ? ({ label, value, item }) => {
              return createRoutePairCustomerTargetStandardTableCell(
                item.target,
                false,
                (value) => formatThousandsAndDecimals(value)
              );
            }
          : createRoutePairCustomerTargetTargetTableCell(),
        isEditable: true,
        width: "105px",
        disableGridCell: isRowsSelected,
      }),
      fillLast: createColumnConfig((item) => "", {
        columnLabel: "",
        width: "24px",
        disableGridCell: true,
      }),
    },
    columnGroups: {
      customerInfo: {
        columnOrder: customerNameColumnOrder,
        label: "Customer info",
      },
      noLabelGroup: {
        columnOrder: customerInfoColumnOrder,
      },
      statInfo: {
        borderLeft: true,
        columnOrder: statInfoColumnOrder,
        label: "Stat info",
      },
      sailingUnitSpecific: {
        borderLeft: true,
        columnOrder: sailingUnitSpecificColumnOrder,
        label: "Sailing & Unit specific",
      },
      currencyGroup: {
        borderLeft: true,
        columnOrder: currencyColumnOrder,
        label: `${currency}/m`,
      },
      noLabelGroupTertiary: {
        borderLeft: true,
        columnOrder: noLabelGroupTertiaryOrder,
      },
    },
    columnGroupOrder: [
      "customerInfo",
      "noLabelGroup",
      "statInfo",
      "sailingUnitSpecific",
      "currencyGroup",
      "noLabelGroupTertiary",
    ],
  };

  if (routeStatusDone) {
    return {
      ...allRoutesTableConfig,
      showRowCheckbox: false,
      showHeaderCheckbox: false,
      stickyCheckboxColumn: false,
      columns: {
        ...allRoutesTableConfig.columns,
        target: {
          ...allRoutesTableConfig.columns.target,
          itemLabelFormatter: undefined,
          onChange: undefined,
          isEditable: false,
        },
      },
    };
  }

  return allRoutesTableConfig;
};
