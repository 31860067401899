import { SpaceValues } from "@/common/utils/SpaceValues";
import { Box, BoxProps, Indent, Row, Space, Txt } from "@stenajs-webui/core";
import { Card, CardBody, CardHeader } from "@stenajs-webui/elements";
import { CheckboxWithLabel } from "@stenajs-webui/forms";
import { cssColor } from "@stenajs-webui/theme";
import * as React from "react";
import { CountryMultiSelect } from "../../../../../../../common/components/data-driven-inputs/selects/country-multi-select/CountryMultiSelect";
import { GoodsMultiSelect } from "../../../../../../../common/components/data-driven-inputs/selects/goods-multi-select/GoodsMultiSelect";
import { ConditionsFormModel } from "../ConditionsFormModel";

const LabelWithChildren: React.FC<
  BoxProps & {
    label: string;
  }
> = ({ label, children, ...props }) => {
  return (
    <Box {...props}>
      <Txt
        variant={"bold"}
        size={"small"}
        color={cssColor("--tjara")}
        whiteSpace="nowrap"
      >
        {label}
      </Txt>
      <Space />
      {children}
    </Box>
  );
};

interface Props {
  value: ConditionsFormModel;
  setFields: (value: Partial<ConditionsFormModel>) => void;
}

export const ConditionsForm: React.FC<Props> = React.memo(
  ({
    setFields,
    value: { goods, countryReceiver, countrySender, standby, transit },
  }) => {
    return (
      <Card>
        <CardHeader text={"Conditions"} />
        <CardBody width={"500px"}>
          <LabelWithChildren label={"Country sender"}>
            <CountryMultiSelect
              value={countrySender}
              onValueChange={(countrySender) => setFields({ countrySender })}
              optionsToRemove={countryReceiver}
            />
          </LabelWithChildren>
          <Space />
          <LabelWithChildren label={"Country receiver"}>
            <CountryMultiSelect
              value={countryReceiver}
              onValueChange={(countryReceiver) =>
                setFields({ countryReceiver })
              }
              optionsToRemove={countrySender}
            />
          </LabelWithChildren>
          <Space />
          <LabelWithChildren label={"Goods"}>
            <GoodsMultiSelect
              value={goods}
              onValueChange={(goods) => setFields({ goods })}
            />
          </LabelWithChildren>
          <Space num={SpaceValues.SIXTEEN} />
          <Row>
            <CheckboxWithLabel
              label={"Standby"}
              value={standby}
              onValueChange={(standby) => setFields({ standby })}
            />
            <Indent />
            <CheckboxWithLabel
              label={"Transit"}
              value={transit}
              onValueChange={(transit) => setFields({ transit })}
            />
          </Row>
        </CardBody>
      </Card>
    );
  }
);
