import { keys } from "lodash";
import { RouteAgreement } from "../../../../../../customers/customer-details/types";
import { MultiLegAgreement } from "../../../../types";
import { groupRouteAgreementsByRoutePair } from "../../util/RouteAgreementGrouper";
import { ExistingMultiLegAgreementTableColumns } from "../multileg/ExistingMultiLegAgreementList";
import { WorkFlowStatusCode, HeadAgreementStatusCode } from "@/gql/graphql";
import { ExistingAgreementTableColumns } from "../SeaAreaTable";

export const transformMultiLegAgreementToTableColumns = (
  multiLegAgreement: MultiLegAgreement,
  customerId: string,
  headAgreementId: string,
  year: number
): ExistingMultiLegAgreementTableColumns => {
  return {
    headAgreementId,
    customerId,
    year,
    name: multiLegAgreement.name,
    multiLegRouteCode: multiLegAgreement.route.code,
    number: multiLegAgreement.idNumber,
    validity: `${multiLegAgreement.valid.start?.isoString ?? ""} - ${
      multiLegAgreement.valid.end?.isoString ?? ""
    }`,
    agrCurrency: multiLegAgreement.currency.code,
    salesReady:
      multiLegAgreement.workFlowStatusCode === WorkFlowStatusCode.Sready
        ? "YES"
        : "NO",
    currency: multiLegAgreement.currency,
    dateCreated: multiLegAgreement.dateCreated,
    createdBy: multiLegAgreement.createdBy,
    headAgreement: multiLegAgreement.headAgreement,
    id: multiLegAgreement.id,
    idNumber: multiLegAgreement.idNumber,
    dateModified: multiLegAgreement.dateModified,
    route: multiLegAgreement.route,
    modifiedBy: multiLegAgreement.modifiedBy,
    routeAgreements: multiLegAgreement.routeAgreements,
    rowVersion: multiLegAgreement.rowVersion,
    statusCode: multiLegAgreement.statusCode,
    valid: multiLegAgreement.valid,
    workFlowStatusCode: multiLegAgreement.workFlowStatusCode,
    routeLegs: multiLegAgreement.routeLegs,
  };
};

export const transformMultiLegAgreementsToTableColumns = (
  multiLegAgreements: Array<MultiLegAgreement>,
  customerId: string,
  headAgreementId: string,
  year: number
): Array<ExistingMultiLegAgreementTableColumns> => {
  return multiLegAgreements.map((mla) => {
    return transformMultiLegAgreementToTableColumns(
      mla,
      customerId,
      headAgreementId,
      year
    );
  });
};

export const transformAgreementToTableColumns = (
  routeAgreementsForRoutePair: RouteAgreement[],
  customerId: string,
  headAgreementId: string,
  year: number
): ExistingAgreementTableColumns => {
  const routePair = routeAgreementsForRoutePair[0].routes[0].routePair;
  const s = routePair.statisticsForCustomerIdAndYear;
  const routePairCode = routePair.id;

  return {
    headAgreementId,
    customerId,
    routePairCode,
    year,
    offered: routeAgreementsForRoutePair.filter(
      (agreement) => agreement.statusCode === HeadAgreementStatusCode.Offered
    ).length,
    averageNegotiationRate: s?.averageNegotiationRate ?? 0,
    expectedVolume: s?.expectedVolume ?? 0,
    volumeLast12Months: s?.volumeLast12Months ?? 0,
    averageTargetRate: s?.averageTargetRate ?? null,
    active: routeAgreementsForRoutePair.filter(
      (ra) => ra.statusCode === HeadAgreementStatusCode.Active
    ).length,
    pending: routeAgreementsForRoutePair.filter(
      (ra) => ra.statusCode === HeadAgreementStatusCode.Pending
    ).length,
    numRouteAgreements: routeAgreementsForRoutePair.length,
    numSalesReady: routeAgreementsForRoutePair.filter((ra) => ra.isSalesReady)
      .length,
  };
};

export const transformAgreementsToTableColumns = (
  agreements: RouteAgreement[],
  customerId: string,
  headAgreementId: string,
  year: number
): ExistingAgreementTableColumns[] => {
  const groupedByRoutePairs = groupRouteAgreementsByRoutePair(agreements);

  const routePairKeys = keys(groupedByRoutePairs);
  routePairKeys.sort();

  return routePairKeys.map((routePairId) =>
    transformAgreementToTableColumns(
      groupedByRoutePairs[routePairId],
      customerId,
      headAgreementId,
      year
    )
  );
};
