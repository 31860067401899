import { combineReducers, EmptyObject } from "redux";
import {
  statisticsBySellerReducer,
  StatisticsBySellerState,
} from "./statistics-by-seller/redux";
import {
  statisticsByRoutePairReducer,
  StatisticsByRoutePairState,
} from "./statistics-by-route-pair/redux";
import {
  generalStatisticsReducer,
  GeneralStatisticsState,
} from "./common/redux/reducer";
import {
  statisticsByAdvancedReducer,
  StatisticsByAdvancedState,
} from "./statistics-by-advanced/redux";
import {
  statisticsByCustomerReducer,
  StatisticsByCustomerState,
} from "./statistics-by-customer/list/redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/es/storage";
import { PersistPartial } from "redux-persist/es/persistReducer";

export interface StatisticsState {
  statisticsGeneral: GeneralStatisticsState;
  statisticsBySeller: EmptyObject & StatisticsBySellerState & PersistPartial;
  statisticsByRoutePair: EmptyObject &
    StatisticsByRoutePairState &
    PersistPartial;
  statisticsByAdvanced: EmptyObject &
    StatisticsByAdvancedState &
    PersistPartial;
  statisticsByCustomer: EmptyObject &
    StatisticsByCustomerState &
    PersistPartial;
}

export const statisticsReducer = combineReducers<StatisticsState>({
  statisticsGeneral: generalStatisticsReducer,
  statisticsBySeller: persistReducer(
    { key: "statisticsBySellerFilter", storage },
    statisticsBySellerReducer
  ),
  statisticsByRoutePair: persistReducer(
    { key: "statisticsByRouterPairFilter", storage },
    statisticsByRoutePairReducer
  ),
  statisticsByAdvanced: persistReducer(
    { key: "statisticsByAdvancedFilter", storage },
    statisticsByAdvancedReducer
  ),
  statisticsByCustomer: persistReducer(
    { key: "statisticsByCustomerFilter", storage },
    statisticsByCustomerReducer
  ),
});
