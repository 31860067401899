import { FppFilterSettings } from "@/features/search-filter/types/FppFilterSettings";

export const routeTargetFilterSettings: FppFilterSettings = {
  countryEnabled: true,
  countryHeader: "Country",
  customerEnabled: true,
  customerHeader: "Number/index",
  networkVolumeEnabled: true,
  routeVolumeEnabled: true,
  customerCategoryEnabled: true,
  customerCategoryHeader: "Customer category",
  regionEnabled: true,
  regionHeader: "Region",
  statNumberAndIndexEnabled: true,
  statNumberAndIndexHeader: "Stat number/index",
  sellerEnabled: true,
  statNetworkVolumeEnabled: true,
  statRouteVolumeEnabled: true,
  sailingCategoryEnabled: true,
  sailingCategoryHeader: "Sailing category",
  unitTypeEnabled: true,
  unitTypeHeader: "Unit type",
  averageNetPriceEnabled: true,
  averageNetPriceHeader: "Average net price/m",
  averageGrossPriceEnabled: true,
  averageGrossPriceHeader: "Average gross price/m",
  targetEnabled: true,
  targetStatusEnabled: true,
  targetStatusHeader: "Target status",
  parentCustomerEnabled: true,
};
