import { IndentValues } from "@/common/utils/IndentValues";
import { SpaceValues } from "@/common/utils/SpaceValues";
import { SpacingValues } from "@/common/utils/SpacingValues";
import { Box, Indent, Space, Spacing } from "@stenajs-webui/core";
import { FlatButton } from "@stenajs-webui/elements";
import { cssColor } from "@stenajs-webui/theme";
import * as React from "react";
import { CollapsibleSection } from "../../../common/components/collapsiable-section/CollapsableSection";
import FormGroup from "../../../common/forms/FormGroup";
import { FormModelChangeHandler } from "../../../common/forms/types";
import {
  MovementType,
  PriceCalculatorFormData,
} from "../PriceCalculatorFormFetcher";
import { PriceCalculatorMovementTypeSelect } from "./PriceCalculatorMovementTypeSelect";
import { PriceCalculatorMultiLeg } from "./price-calculator-form-sections/PriceCalculatorMultileg";
import { PriceCalculatorSectional } from "./price-calculator-form-sections/PriceCalculatorSectional";
import { PriceCalculatorCargoGoodsDetails } from "./price-calculator-form-sections/cargo-details/PriceCalculatorCargoGoodsDetails";
import { PriceCalculatorConditions } from "./price-calculator-form-sections/cargo-details/PriceCalculatorConditions";
import { PriceCalculatorPreferenceRadioGroup } from "./price-calculator-form-sections/cargo-details/PriceCalculatorLoadingPreferenceRadioGroup";
import { GoodsAndAncillary } from "./price-calculator-form-sections/goods-and-ancillary/GoodsAndAncillary";

interface Props {
  formData: PriceCalculatorFormData;
  onChangeFormData: FormModelChangeHandler<PriceCalculatorFormData>;
  onClearFormData: () => void;
  setState: React.Dispatch<React.SetStateAction<PriceCalculatorFormData>>;
}
export const PriceCalculatorCustomerForm: React.FC<Props> = ({
  formData,
  onChangeFormData,
  onClearFormData,
  setState,
}) => {
  return (
    <>
      <Indent>
        <PriceCalculatorMovementTypeSelect
          formData={formData}
          onChange={onChangeFormData}
        />

        <Spacing />
        <Indent num={2}>
          <hr
            style={{
              border: `1px solid ${cssColor("--lhds-color-ui-300")}`,
              width: "100%",
            }}
          />
        </Indent>

        <Box position={"relative"}>
          <Box
            top={"8px"}
            right={"16px"}
            justifyContent={"flex-end"}
            position={"absolute"}
          >
            <FlatButton
              title={"Clear"}
              label={"Clear"}
              onClick={() => onClearFormData()}
            />
          </Box>
        </Box>
      </Indent>
      {formData.movementType === MovementType.SECTIONAL ? (
        <PriceCalculatorSectional
          formData={formData}
          onChangeFormData={onChangeFormData}
        />
      ) : (
        <PriceCalculatorMultiLeg
          formData={formData}
          onChangeFormData={onChangeFormData}
          setState={setState}
        />
      )}
      <Spacing num={SpacingValues.SIXTEEN} />

      <GoodsAndAncillary
        formData={formData}
        onChangeFormData={onChangeFormData}
      />
      <Spacing num={SpacingValues.SIXTEEN} />
      <Box style={{ padding: "12px" }}>
        <CollapsibleSection
          label={"More options"}
          collapsed={formData.moreOptionsPanelCollapsed ?? false}
          onToggleCollapsed={() =>
            onChangeFormData(
              "moreOptionsPanelCollapsed",
              !formData.moreOptionsPanelCollapsed
            )
          }
        >
          <Indent num={IndentValues.FORTY}>
            <Space num={SpaceValues.SIXTEEN} />
            {formData.movementType === MovementType.SECTIONAL && (
              <>
                <FormGroup title={"Loading Preferences"}>
                  <PriceCalculatorPreferenceRadioGroup
                    formData={formData}
                    onChangeFormData={onChangeFormData}
                  />
                </FormGroup>

                <Spacing num={SpaceValues.TWELVE} />
              </>
            )}

            <PriceCalculatorCargoGoodsDetails
              formData={formData}
              onChangeFormData={onChangeFormData}
            />

            <PriceCalculatorConditions
              formData={formData}
              onChangeFormData={onChangeFormData}
            />
          </Indent>
        </CollapsibleSection>
      </Box>
    </>
  );
};
