import { ErrorDisplay } from "@/common/error/components/ErrorDisplay";
import { FreightPricingPortalError } from "@/common/error/models/FreightPricingPortalError";
import { ZIndex } from "@/common/utils/ZIndexEnum";
import {
  SearchFilterSelectorContext,
  SearchFilterSelectorContextType,
} from "@/features/search-filter/context/searchFilterSelectorContext";
import { createFppSearchFilterActions } from "@/features/search-filter/redux/reducer";
import { Box, Indent, Row, Space, Text } from "@stenajs-webui/core";
import {
  BreadCrumbs,
  Icon,
  stenaExclamationTriangle,
  stenaSailingRoute,
} from "@stenajs-webui/elements";
import { SearchFilterContext } from "@stenajs-webui/filter";
import { TextInput } from "@stenajs-webui/forms";
import { cssColor } from "@stenajs-webui/theme";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { routeFactory } from "../../../../RouteFactory";
import { TARGET_EDIT_CLAIM } from "../../../../common/claims/constants";
import { hasClaim } from "../../../../common/claims/utils";
import { Crumb } from "../../../../common/components/bread-crumbs/Crumb";
import { PageHeader } from "../../../../common/components/page-header/PageHeader";
import { PageLayout } from "../../../../common/components/page-layout/PageLayout";
import { UnAuthorizedView } from "../../../../common/components/unauthorized-view/UnAuthorizedView";
import { useCurrencySelection } from "../../../../common/redux/profile/hooks/UseCurrencySelection";
import { useDefaultYearSelection } from "../../../../common/redux/profile/hooks/UseDefaultYearSelection";
import { profileSelectors } from "../../../../common/redux/profile/selectors";
import { renderRoutePairLabelWithIcon } from "../../../../common/utils/RoutePairLabelWithDescriptionInTable";
import { routeTargetsOverviewSelectors } from "../../route-targets-overview/redux/routeTargetsOverviewSelectors";
import { RoutePairCustomerTargetDataFetcher } from "../features/route-pair-target-table/RoutePairCustomerTargetDataFetcher";
import { routeCustomerTargetSelectors } from "../features/route-pair-target-table/redux/selectors";
import { RouteTargetCurrencySelect } from "./RouteTargetCurrencySelect";
import { RouteTargetNegotiationYearInfoPair } from "./RouteTargetNegotiationYearInfoPair";
import { RouteTargetSaveButton } from "./RouteTargetSaveButton";
import { useRouteTargetPageRoutePairDescription } from "./hooks/UseRouteTargetRoutePairDescriptionQuery";
import { routeTargetPageActions } from "./redux/actions";
import { TargetSummary } from "./target-summary/TargetSummary";

export const RouteTargetPage: React.FC = () => {
  const { workspaceState } = useContext(
    SearchFilterSelectorContext
  ) as SearchFilterSelectorContextType;

  const { claims } = useSelector(profileSelectors.getGlobal);
  const { loading: yearLoading, year: defaultYear } = useDefaultYearSelection();
  const { routePairCode } = useParams() as { routePairCode: string };
  const { handleCurrencyChange, currency } = useCurrencySelection();
  const { routePairDescription } =
    useRouteTargetPageRoutePairDescription(routePairCode);
  const [error, setError] = useState<FreightPricingPortalError[]>([]);
  const dispatch = useDispatch();

  const selectedNegotiationYear = useSelector(
    routeTargetsOverviewSelectors.getSelectedNegotiationYear
  );
  const onSaveError = (saveErrors: FreightPricingPortalError[]) => {
    setError(saveErrors);
  };

  const hasErrors = error.length > 0;

  useEffect(() => {
    dispatch(
      routeTargetPageActions.setCurrentRoutePairCode({
        currentRoutePairCode: routePairCode,
      })
    );
    return () => {
      dispatch(
        routeTargetPageActions.setCurrentRoutePairCode({
          previousRoutePairCode: routePairCode,
        })
      );
    };
  }, [routePairCode, dispatch]);

  const onSelectCurrencyChange = (currency: string) => {
    handleCurrencyChange(currency);
  };

  const isRowsSelected = useSelector(
    routeCustomerTargetSelectors.hasSelectedRows
  );

  return (
    <SearchFilterContext
      state={workspaceState}
      actions={createFppSearchFilterActions("routeTarget")}
      dispatch={dispatch}
    >
      <>
        {hasErrors ? (
          <Row
            alignItems={"center"}
            indent={2}
            spacing={2}
            background={cssColor("--lhds-color-red-100")}
          >
            <Indent />
            <Text>
              <Icon icon={stenaExclamationTriangle} />
              <Space />
              <Text variant={"bold"}>{onSaveError}</Text>
            </Text>
          </Row>
        ) : null}
      </>
      <PageHeader
        icon={stenaSailingRoute}
        sticky
        width={"100vw"}
        offsetLeft={0}
        offsetTop={0}
        zIndex={ZIndex.low}
        title={renderRoutePairLabelWithIcon(
          routePairDescription ?? "",
          routePairCode
        )}
        breadcrumbs={
          <BreadCrumbs>
            <Crumb
              label={"ROUTE TARGETS"}
              path={routeFactory.targetingTool.routeTargets.allRoutes()}
            />
            <Crumb label={routePairCode} isLast />
          </BreadCrumbs>
        }
        contentLeft={
          <>
            {!isRowsSelected ? (
              <RouteTargetCurrencySelect
                selectLabel={"Currency"}
                value={currency}
                onSelectCurrencyChange={onSelectCurrencyChange}
              />
            ) : (
              <>
                <Text size="small" color={cssColor("--lhds-color-ui-500")}>
                  Currency
                </Text>
                <Space num={2} />
                <Box width="82.5px">
                  <TextInput
                    style={{ padding: "0.3rem 0.75rem" }}
                    value={currency}
                    disabled={isRowsSelected}
                  />
                </Box>
              </>
            )}
            <RouteTargetNegotiationYearInfoPair
              label={"Negotiation year"}
              selectedYear={`${selectedNegotiationYear ?? defaultYear}`}
            />
          </>
        }
        contentRight={<RouteTargetSaveButton onErrors={onSaveError} />}
        contentUnder={hasErrors ? <ErrorDisplay error={error[0]} /> : null}
      />

      {hasClaim(claims, [TARGET_EDIT_CLAIM]) ? (
        <PageLayout>
          <TargetSummary
            currency={currency}
            selectedYear={selectedNegotiationYear ?? defaultYear}
          />
          <RoutePairCustomerTargetDataFetcher
            currency={currency}
            routePairCode={routePairCode}
            year={selectedNegotiationYear ?? defaultYear}
            yearLoading={yearLoading}
          />
        </PageLayout>
      ) : (
        <UnAuthorizedView />
      )}
    </SearchFilterContext>
  );
};
