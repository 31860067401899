import { Box, Row, Space, Txt } from "@stenajs-webui/core";
import { NumberDecimalsSelect } from "../../../../../common/components/data-driven-inputs/selects/number-decimals-select/NumberDecimalsSelect";
import { RaiseByTypeSelect } from "../../../../../common/components/data-driven-inputs/selects/raise-by-type-select/RaiseByTypeSelect";
import { RaiseTypeSelect } from "../../../../../common/components/data-driven-inputs/selects/raise-type-select/RaiseTypeSelect";
import * as React from "react";
import { BulkUpdateRatesFormModel } from "../../bulk-update-rates/models/BulkUpdateRatesFormModel";
import { NumericTextInput } from "@/common/components/numeric-text-input/NumericTextInput";
import { RouteAgreementsRatesByFilterQuery } from "@/gql/graphql";
import { testIdConstants } from "@/common/test-id-constants/testIdConstants";

interface Props {
  value: BulkUpdateRatesFormModel;
  onValueChange: (value: BulkUpdateRatesFormModel) => void;
  routeAgreementRates: RouteAgreementsRatesByFilterQuery | undefined;
}

export const BulkEditRatesUpdateManyForm: React.FC<Props> = ({
  value,
  onValueChange,
  routeAgreementRates,
}) => {
  const currencyLabel = routeAgreementRates?.productPrice.routeAgreementPrice
    .byFilter
    ? routeAgreementRates.productPrice.routeAgreementPrice.byFilter
        .agrRoutePrices?.[0].routeAgreement.currency.code
    : "";
  return (
    <Row alignItems={"center"}>
      <Txt variant="bold">{"Raise rate by"}</Txt>
      <Space />
      <Box width={"60px"}>
        <NumericTextInput
          value={value.raiseBy}
          maxDecimals={2}
          onValueChange={(raiseBy) => onValueChange({ ...value, raiseBy })}
          dataTestid={testIdConstants.bulkEditRatesRaiseRateByInput}
        />
      </Box>
      <Space />
      <Box minWidth={"120px"}>
        <RaiseByTypeSelect
          currencyLabel={currencyLabel}
          value={value.raiseByType}
          onValueChange={(raiseByType) =>
            onValueChange({ ...value, raiseByType })
          }
        />
      </Box>
      <Space />
      <Box width={"140px"}>
        <NumberDecimalsSelect
          value={value.numberDecimals}
          onValueChange={(numberDecimals) =>
            onValueChange({ ...value, numberDecimals })
          }
        />
      </Box>
      <Space />
      <Box width={"135px"}>
        <RaiseTypeSelect
          value={value.raiseType}
          onValueChange={(raiseType) => onValueChange({ ...value, raiseType })}
        />
      </Box>
    </Row>
  );
};
