import { InvoiceSpecification } from "@/common/components/invoice-specification/models/InvoiceSpecification";
import { NshlInvoiceSpecificationFragment } from "@/gql/graphql";

export const transformByInvoiceNumbersToInvoiceSpecification = (
  data: NshlInvoiceSpecificationFragment
): InvoiceSpecification => {
  return {
    id: data.id,
    invoiceNo: data.invoiceNo,
    consignmentNo: data.consignmentNo,
    invoiceDate: data.invoiceDate,
    invoiceCurrencyCode: data.invoiceCurrencyCode,
    custNo: data.customer?.custNo,
    custName: data.customer.name,
    custIndex: data.customer.custIndex,
    netAmount: data.netAmount,
    thresholdNoShow: data.thresholdNoShow,
    thresholdLateHandling: data.thresholdLateHandling,
    routePairVolumes: data.routeVolumes.map((routePairVolume) => ({
      routePairCode: routePairVolume.routePair?.code,
      shippedVolume: routePairVolume.shippedVolume,
    })),
    nslhBookings: data.nslhBookings.map((booking) => ({
      id: booking.id,
      articlePrice: booking.articlePrice,
      bookingNo: booking.bookingNo,
      consignmentNo: booking.consignmentNo,
      bookingVersion: booking.bookingVersion,
      custNo: booking.customer.custNo,
      customerReference: booking.customerReference,
      departureDate: booking.departureDate,
      handlingType: booking.handlingType,
      handlingTypeGrouped: booking.handlingTypeGrouped,
      departureTime: booking.departureTime,
      handlingStatus: booking.handlingStatus,
      revisedDate: booking.revisedDate,
      revisedTime: booking.revisedTime,
      routePairCode: booking.route?.routePair.code ?? null,
      routeCode: booking.route.code ?? null,
      routePairDescription: booking.route?.routePair.description ?? null,
      trailerReg: booking.trailerReg,
      vehicleType: booking.vehicleType.shortDescription,
      vehicleReg: booking.vehicleReg,
      lateHandlingUser: booking.lateHandlingUser,
      lateHandlingDate: booking.lateHandlingDate,
    })),
    nslhMonth: data.nslhMonth,
    nslhYear: data.nslhYear,
    routeCodeBreak: data.routeCodeBreak,
  };
};
