import { useMutation } from "@apollo/client";
import { ApolloError, ExecutionResult, gql } from "apollo-boost";
import { useCallback } from "react";

import {
  SetTargetNegotiationStatusMutation,
  SetTargetNegotiationStatusMutationVariables,
} from "@/gql/graphql";
import { TargetNegotiationStatus } from "../../../types";

const mutation = gql`
  mutation SetTargetNegotiationStatus(
    $input: SetTargetNegotiationStatusInput!
  ) {
    productPrice {
      __typename
      targetOverview {
        __typename
        setTargetNegotiationStatus(input: $input) {
          __typename
          targetOverview {
            __typename
            id
            negotiationStatus {
              __typename
              id
              code
              description
            }
          }
        }
      }
    }
  }
`;

interface UseSetTargetNegotiationStatus {
  setTargetNegotiationStatus: (
    routePairCode: string,
    year: number,
    negotiationStatus: TargetNegotiationStatus
  ) => Promise<ExecutionResult<SetTargetNegotiationStatusMutation>>;
  error: ApolloError | undefined;
  loading: boolean;
}

export const useSetTargetNegotiationStatus =
  (): UseSetTargetNegotiationStatus => {
    const [mutate, { loading, error }] = useMutation<
      SetTargetNegotiationStatusMutation,
      SetTargetNegotiationStatusMutationVariables
    >(mutation);

    const setTargetNegotiationStatus = useCallback(
      (
        routePairCode: string,
        year: number,
        negotiationStatus: TargetNegotiationStatus
      ) => {
        return mutate({
          variables: {
            input: {
              routePairCode,
              year,
              negotiationStatusId: negotiationStatus.code,
            },
          },
          optimisticResponse: {
            productPrice: {
              __typename: "ProductPriceMutationRoot",
              targetOverview: {
                __typename: "TargetOverviewMutationRoot",
                setTargetNegotiationStatus: {
                  __typename: "SetTargetNegotiationStatusResult",
                  targetOverview: {
                    __typename: "TargetOverview",
                    id: `${year}:${routePairCode}`,
                    negotiationStatus: {
                      __typename: "TargetNegotiationStatus",
                      ...negotiationStatus,
                    },
                  },
                },
              },
            },
          },
        });
      },
      [mutate]
    );

    return {
      setTargetNegotiationStatus,
      error,
      loading,
    };
  };
