import { Space } from "@stenajs-webui/core";
import {
  BreadCrumbs,
  FlatButton,
  PrimaryButton,
  ResultListBanner,
  SecondaryButton,
  stenaUserSingle,
} from "@stenajs-webui/elements";
import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Crumb } from "../../../common/components/bread-crumbs/Crumb";
import { PageHeader } from "../../../common/components/page-header/PageHeader";
import { StoreState } from "../../../config/redux/RootReducer";
import { routeFactory } from "../../../RouteFactory";
import { adminTargetActions } from "./components/all-routes/redux/actions";
import { adminTargetSelectors } from "./components/all-routes/redux/selectors";
import {
  UpdateAllRoutesAdminData,
  updateAllRoutesAdminTarget,
} from "./components/all-routes/redux/thunks/AdminTargetMutations";
import { testIdConstants } from "@/common/test-id-constants/testIdConstants";

const selector = (state: StoreState) => state.adminTarget.allRoutesData;

export const AdminTargetHeader: React.FC = () => {
  const allRoutesData = useSelector(selector);
  const dispatch = useDispatch();
  const { bannerState } = useSelector(adminTargetSelectors.getProgress);

  const handleResetTable = () => {
    const persistedData = allRoutesData.map((data) => {
      return {
        id: data.id,
        editable: data.persisted,
        persisted: data.persisted,
      };
    });

    dispatch(adminTargetActions.resetTableState(persistedData));
  };

  const isDataModified = useMemo(() => {
    return allRoutesData.some((ard) => {
      return JSON.stringify(ard.editable) !== JSON.stringify(ard.persisted);
    });
  }, [allRoutesData]);

  const handleUpdateAdminRouteTargets = async (): Promise<void> => {
    const dataForUpdate = allRoutesData
      .filter((ard) => {
        return JSON.stringify(ard.persisted) !== JSON.stringify(ard.editable);
      })
      .map((data) => {
        const {
          editable: {
            year,
            routePairCode,
            offPeakAcc,
            offPeakUnAcc,
            peakAcc,
            peakUnAcc,
            shoulderUnAcc,
            shoulderAcc,
            volumeTarget,
          },
        } = data;

        return {
          year,
          routePairCode,
          offPeakAcc,
          offPeakUnAcc,
          peakAcc,
          peakUnAcc,
          shoulderUnAcc,
          shoulderAcc,
          volume: volumeTarget,
        };
      });

    dispatch(
      updateAllRoutesAdminTarget(dataForUpdate as UpdateAllRoutesAdminData)
    );

    const newTableData = allRoutesData.map((ard) => {
      return {
        id: ard.id,
        editable: ard.editable,
        persisted: ard.editable,
      };
    });

    dispatch(adminTargetActions.setRowsAdminTargetAllRoutes(newTableData));
  };

  const headerContentRight = (): React.ReactNode => {
    return (
      <>
        <SecondaryButton
          disabled={!isDataModified}
          label={"Discard"}
          onClick={() => handleResetTable()}
          data-testid={testIdConstants.groupFreightTargetsDiscardButton}
        />
        <Space num={2} />
        <PrimaryButton
          disabled={!isDataModified}
          label={"Save"}
          onClick={() => handleUpdateAdminRouteTargets()}
          data-testid={testIdConstants.groupFreightTargetsSaveButton}
        />
      </>
    );
  };

  return (
    <PageHeader
      title={"Group freight targets"}
      sticky
      width={"100vw"}
      offsetLeft={0}
      offsetTop={0}
      icon={stenaUserSingle}
      breadcrumbs={
        <BreadCrumbs>
          <Crumb
            label={"admin"}
            path={routeFactory.targetingTool.adminTarget.allRoutes()}
          />
          <Crumb
            label={"Group freight targets"}
            path={routeFactory.targetingTool.adminTarget.allRoutes()}
            isLast
          />
        </BreadCrumbs>
      }
      contentRight={headerContentRight()}
      bottomSpacing={false}
      contentUnder={
        bannerState && (
          <ResultListBanner
            bannerState={bannerState}
            variant={"error"}
            contentRight={
              <FlatButton
                label={"Close"}
                onClick={() =>
                  dispatch(
                    adminTargetActions.setProgress({
                      bannerState: undefined,
                    })
                  )
                }
              />
            }
          />
        )
      }
    />
  );
};
