import {
  CustomerSearchResultData,
  MovementType,
  PriceCalculatorFormData,
} from "../PriceCalculatorFormFetcher";
import { useMultiLegPriceCalculation } from "./UseMultiLegPriceCalculation";
import { usePriceCalculation } from "./UsePriceCalculation";
import { useContext, useMemo, useState } from "react";
import {
  transformToCustomerSearchResultData,
  transformToCustomerSearchResultMultiLegData,
  transformToPriceCalculatorInput,
} from "../price-calculator-result-panel/transformers/PriceCalculatorFormResultTransformer";
import { ApolloError } from "apollo-boost";
import {
  FeatureFlagsContext,
  FeatureFlagsContextType,
} from "@/config/featureFlags/FeatureFlagsContext";

export const useCustomerPriceCalculation = () => {
  const [movementType, setMovementType] = useState<MovementType | null>(null);
  const {
    calculateMultiLegPrice,
    loading: priceCalculateMultipleLoading,
    data: priceCalculateMultipleData,
  } = useMultiLegPriceCalculation();

  const {
    calculatePrice,
    loading: priceCalculateLoading,
    data: priceCalculateData,
  } = usePriceCalculation();

  const { featureFlags } = useContext(
    FeatureFlagsContext
  ) as FeatureFlagsContextType;

  const usePricingV2 = featureFlags.pricing.usePricingV2;

  const [formData, setFormData] = useState<PriceCalculatorFormData>();
  const [responseError, setResponseError] = useState<ApolloError | undefined>();

  const customerResult: CustomerSearchResultData | undefined = useMemo(() => {
    if (formData) {
      if (priceCalculateData && movementType === MovementType.SECTIONAL) {
        return transformToCustomerSearchResultData(
          priceCalculateData,
          formData
        );
      } else if (
        priceCalculateMultipleData &&
        movementType === MovementType.MULTILEG
      ) {
        return transformToCustomerSearchResultMultiLegData(
          priceCalculateMultipleData,
          formData
        );
      }
    }
  }, [priceCalculateData, priceCalculateMultipleData, formData, movementType]);

  const sailingTypeMultiLeg = "M";
  const calculateCustomerPrice = async (formData: PriceCalculatorFormData) => {
    setFormData(formData);
    setMovementType(formData.movementType);
    const input = transformToPriceCalculatorInput(formData);
    if (input.movementType === sailingTypeMultiLeg) {
      const { error } = await calculateMultiLegPrice({
        variables: {
          input,
          usePricingV2,
        },
      });
      setResponseError(error);
    } else {
      const { error } = await calculatePrice({
        variables: {
          input,
          usePricingV2,
        },
      });
      setResponseError(error);
    }
  };

  return {
    calculateCustomerPrice,
    loading: priceCalculateLoading || priceCalculateMultipleLoading,
    customerResult,
    error: responseError,
  };
};
