import { ZIndex } from "@/common/utils/ZIndexEnum";
import { Box, Row, SeparatorLine, Space } from "@stenajs-webui/core";
import { Banner, FlatButton, PrimaryButton } from "@stenajs-webui/elements";
import { TableContext } from "@stenajs-webui/grid";
import { SelectedItemsActionsPanel } from "@stenajs-webui/panels";
import * as React from "react";
import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LoadingModalWithLock } from "../../../../../common/components/loading-modals/LoadingModalWithLock";
import { StoreState } from "../../../../../config/redux/RootReducer";
import {
  RaiseType,
  emptyBulkUpdateRatesFormModel,
} from "../../bulk-update-rates/models/BulkUpdateRatesFormModel";
import { BulkEditRatesTableColumn } from "../config/BulkEditRatesTableConfig";
import { bulkEditRatesActions } from "../redux/reducer";
import { bulkEditRatesSelectors } from "../redux/selectors";
import { raisePricesUsingSelectionThunk } from "../thunks/RaisePricesUsingSelectionThunk";
import { BulkEditRatesUpdateManyForm } from "./BulkEditRatesUpdateManyForm";
import { RouteAgreementsRatesByFilterQuery } from "@/gql/graphql";
import { testIdConstants } from "@/common/test-id-constants/testIdConstants";

interface Props {
  tableContext: TableContext<BulkEditRatesTableColumn>;
  numItemsSelected: number;
  routeAgreementRates: RouteAgreementsRatesByFilterQuery | undefined;
  promptVisible: boolean;
  showPrompt: () => void;
  hidePrompt: () => void;
}

export const BulkEditRatesToolbar: React.FC<Props> = ({
  tableContext,
  numItemsSelected,
  routeAgreementRates,
  promptVisible,
  showPrompt,
  hidePrompt,
}) => {
  const dispatch = useDispatch();
  const [formModel, setFormModel] = useState(emptyBulkUpdateRatesFormModel);
  const canSubmit = formModel !== emptyBulkUpdateRatesFormModel;

  const tableReset = () => {
    tableContext.dispatch(tableContext.actions.clearSelection());
  };

  const bulkUpdateRatesLoading = useSelector(
    bulkEditRatesSelectors.getBulkUpdateRatesLoading
  );

  const selectedRowIds = useSelector(bulkEditRatesSelectors.getSelectedRowIds);

  const routeAgreementPricesSelector = useCallback(
    (state: StoreState) => {
      const persistedPrices = Object.values(state.bulkEditRatesState.edited);
      return persistedPrices.filter((p) =>
        selectedRowIds.includes(p?.id ?? "")
      );
    },
    [selectedRowIds]
  );

  const selectedRouteAgreementPrices = useSelector(
    routeAgreementPricesSelector
  );

  const onClickApply = async () => {
    if (
      selectedRouteAgreementPrices.some(
        (price) => Number(price?.meterPrice) < 1
      ) &&
      formModel.raiseType === RaiseType.BY_METER_PRICE
    ) {
      showPrompt();
      return;
    }
    dispatch(bulkEditRatesActions.setBulkUpdateRatesLoading(true));
    setTimeout(
      () => dispatch(raisePricesUsingSelectionThunk(formModel, tableContext)),
      1000
    );
  };

  const onYesPromptSelectedRaise = async () => {
    dispatch(bulkEditRatesActions.setBulkUpdateRatesLoading(true));
    setTimeout(
      () => dispatch(raisePricesUsingSelectionThunk(formModel, tableContext)),
      1000
    );
    dispatch(bulkEditRatesActions.setBulkUpdateRatesLoading(false));
    hidePrompt();
  };

  return (
    <Box position={"sticky"} top={98} zIndex={ZIndex.lowest}>
      <SelectedItemsActionsPanel
        numItemsSelected={numItemsSelected}
        afterLabelContent={
          <FlatButton label={"Clear all"} onClick={tableReset} />
        }
        rightContent={
          <Row alignItems={"center"}>
            <BulkEditRatesUpdateManyForm
              value={formModel}
              onValueChange={setFormModel}
              routeAgreementRates={routeAgreementRates}
            />
            <Space />
            <PrimaryButton
              label={"Apply"}
              disabled={!canSubmit}
              onClick={() => onClickApply()}
              data-testid={testIdConstants.bulkEditRatesActionsPanelApplyButton}
            />
          </Row>
        }
      />
      {promptVisible && (
        <Box width={"100%"}>
          <SeparatorLine />
          <Banner
            headerText="You have selected a row where the meter price is 0."
            text={"Are you sure you want to continue?"}
            variant="info"
            contentRight={
              <Row width={"100%"} indent>
                <FlatButton label="No" onClick={hidePrompt} />
                <Space num={3} />
                <PrimaryButton label="Yes" onClick={onYesPromptSelectedRaise} />
              </Row>
            }
          />
        </Box>
      )}
      {bulkUpdateRatesLoading && (
        <LoadingModalWithLock label="Applying changes and recalculating actuals. Thanks for your patience." />
      )}
    </Box>
  );
};
