import { HeadAgreementStatusCode } from "@/gql/graphql";
import { Banner, stenaLockClosed } from "@stenajs-webui/elements";
import * as React from "react";

interface Props {
  status: HeadAgreementStatusCode;
}

export const ActiveIndicatorBox: React.FC<Props> = ({ status }) => {
  return (
    <Banner
      text={`This agreement is ${status.toLowerCase()}, you can only change validity, name and notes.`}
      variant="info"
      icon={stenaLockClosed}
    />
  );
};
