import { NumericAgrVehicleLengthTextInput } from "@/common/components/numeric-agr-vehicle-length-text-input/NumericAgrVehicleLengthTextInput";
import { NumericAgrVehicleWeightTextInput } from "@/common/components/numeric-agr-vehicle-weight-text-input/NumericAgrVehicleWeightTextInput";
import { NumericTextInput } from "@/common/components/numeric-text-input/NumericTextInput";
import { DateFormats } from "@/common/dates/DateFormats";
import {
  commaToDotTransform,
  dotToCommaTransform,
} from "@/common/formatters/NumericTextFieldInputCleaner";
import { Column, Row, Space } from "@stenajs-webui/core";
import { FlatButton, stenaCopy, stenaTrash } from "@stenajs-webui/elements";
import { TextInput } from "@stenajs-webui/forms";
import { Tooltip } from "@stenajs-webui/tooltip";
import { format } from "date-fns";
import * as React from "react";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { VehicleTypeWithAllSelect } from "../../../../../../../common/components/data-driven-inputs/selects/vehicle-type-with-all-select/VehicleTypeWithAllSelect";
import { NumericTextInputNoScroll } from "../../../../../../../common/components/forms/NumericTextInputNoScroll";
import { articleFormActions } from "../actions";
import { ArticlePriceFormModel } from "../models/ArticlesFormModel";
import { ArticlePriceItemTableCell } from "./ArticlePriceItemTableCell";
import { ArticlePriceItemRow } from "./ArticlePriceItemsTable";

interface Props {
  articleId: string;
  showPrice?: boolean;
  showPercent?: boolean;
  articlePriceRow: ArticlePriceItemRow;
  vehicleTypeWidth: number;
  priceWidth: number;
  percentWidth: number;
  lengthFromWidth: number;
  lengthToWidth: number;
  weightFromWidth: number;
  weightToWidth: number;
  validFromWidth: number;
}

export const ArticlePriceItem: React.FC<Props> = ({
  articleId,
  showPercent,
  showPrice,
  vehicleTypeWidth,
  priceWidth,
  percentWidth,
  lengthFromWidth,
  lengthToWidth,
  weightFromWidth,
  weightToWidth,
  validFromWidth,
  articlePriceRow: {
    vehicleTypeId,
    priceValidityId,
    lengthTo,
    lengthFrom,
    weightFrom,
    weightTo,
    price,
    priceId,
    percentage,
    validFromDate,
  },
}) => {
  const renderError = (fromValue: string, toValue: string) => {
    if (
      fromValue !== "" &&
      toValue !== "" &&
      Number(commaToDotTransform(fromValue)) >
        Number(commaToDotTransform(toValue))
    ) {
      return "error";
    }
    return "standard";
  };
  const tooltipDisabled = (fromValue: string, toValue: string) => {
    return (
      Number(commaToDotTransform(fromValue)) <=
      Number(commaToDotTransform(toValue))
    );
  };

  const dispatch = useDispatch();

  const id = priceId;

  const setFields = useCallback(
    (fields: Partial<ArticlePriceFormModel>) => {
      dispatch(articleFormActions.setArticlePriceFields(articleId, id, fields));
    },
    [dispatch, articleId, id]
  );

  const onClickCopy = useCallback(() => {
    dispatch(articleFormActions.copyArticlePrice(id));
  }, [dispatch, id]);

  const onClickDelete = useCallback(() => {
    dispatch(articleFormActions.deleteArticlePrice(id));
  }, [dispatch, id]);

  return (
    <Row indent={1}>
      <ArticlePriceItemTableCell width={vehicleTypeWidth}>
        <Column flex={1}>
          <VehicleTypeWithAllSelect
            value={vehicleTypeId}
            onValueChange={(vehicleTypeId) => setFields({ vehicleTypeId })}
            menuPortalTarget={document.body}
          />
        </Column>
      </ArticlePriceItemTableCell>
      {showPrice && (
        <ArticlePriceItemTableCell width={priceWidth}>
          <NumericTextInput
            value={dotToCommaTransform(price)}
            onValueChange={(price) =>
              setFields({ price: commaToDotTransform(price) })
            }
            maxDecimals={2}
          />
        </ArticlePriceItemTableCell>
      )}
      {showPercent && (
        <ArticlePriceItemTableCell width={percentWidth}>
          <NumericTextInputNoScroll
            value={percentage}
            hideButtons
            onValueChange={(percent) => setFields({ percent })}
          />
        </ArticlePriceItemTableCell>
      )}
      <ArticlePriceItemTableCell width={lengthFromWidth}>
        <NumericAgrVehicleLengthTextInput
          value={dotToCommaTransform(lengthFrom)}
          onValueChange={(lengthFrom) => setFields({ lengthFrom })}
        />
      </ArticlePriceItemTableCell>
      <ArticlePriceItemTableCell width={lengthToWidth}>
        <Tooltip
          disabled={tooltipDisabled(lengthFrom, lengthTo)}
          variant={"error"}
          label={"Length to must be greater than or equal to Length from"}
        >
          <NumericAgrVehicleLengthTextInput
            value={dotToCommaTransform(lengthTo)}
            onValueChange={(lengthTo) => setFields({ lengthTo })}
            variant={renderError(lengthFrom, lengthTo)}
          />
        </Tooltip>
      </ArticlePriceItemTableCell>
      <ArticlePriceItemTableCell width={weightFromWidth}>
        <NumericAgrVehicleWeightTextInput
          value={weightFrom}
          onValueChange={(weightFrom) => setFields({ weightFrom })}
        />
      </ArticlePriceItemTableCell>
      <ArticlePriceItemTableCell width={weightToWidth}>
        <Tooltip
          disabled={tooltipDisabled(weightFrom, weightTo)}
          variant={"error"}
          label={"Weight to must be greater than or equal to Weight from"}
        >
          <NumericAgrVehicleWeightTextInput
            value={weightTo}
            onValueChange={(weightTo) => setFields({ weightTo })}
            variant={renderError(weightFrom, weightTo)}
          />
        </Tooltip>
      </ArticlePriceItemTableCell>
      <ArticlePriceItemTableCell width={validFromWidth}>
        <TextInput
          value={
            validFromDate ? format(validFromDate, DateFormats.fullDate) : ""
          }
          disabled
        />
      </ArticlePriceItemTableCell>

      <ArticlePriceItemTableCell width={"100px"}>
        {priceValidityId == null && (
          <>
            <FlatButton leftIcon={stenaCopy} onClick={onClickCopy} />
            <FlatButton leftIcon={stenaTrash} onClick={onClickDelete} />
          </>
        )}
      </ArticlePriceItemTableCell>

      <Space num={5} />
    </Row>
  );
};
