import { WorkspaceState } from "@/features/search-filter/redux/reducer";
import { RoutePairCustomerTarget } from "../components/RouteTargetPageWrapper";
import { AllCustomerTargetsTableRow } from "../features/route-pair-target-table/config/RowsAndColumnsConfig";
import {
  FetchRoutePairCustomerTargetFragment,
  RoutePairTargetQueryVariables,
} from "@/gql/graphql";

export const transformRoutPairTargetQueryToAllCustomerTargetsTableRow = (
  value: FetchRoutePairCustomerTargetFragment | RoutePairCustomerTarget
): AllCustomerTargetsTableRow => {
  return {
    id: value.id,
    category: value.customerCategory.code,
    country: value.customerCountry.code,
    customerNo: value.custNo,
    customerNVolume: value.custNetworkVolume ?? undefined,
    customerRVolume: value.custRouteVolume ?? undefined,
    customerVolume: value.custRouteRowVolume ?? undefined,
    gross: value.avgGrossMeterPrice ?? undefined,
    net: value.avgNetMeterPrice ?? undefined,
    name: value.customerName,
    sailingCat: value.sailingCategory.name,
    sailingCatCode: value.sailingCategory.code,
    salesRegion: value.salesRegion.shortName,
    seller: value.seller,
    statNo: value.statNo ?? undefined,
    statNVolume: value.statNetworkVolume ?? undefined,
    statRVolume: value.statRouteVolume ?? undefined,
    statVolume: value.statRouteRowVolume ?? undefined,
    target: value.targetInPercent ?? null,
    unitType: value.unitCategoryCode,
    year: value.year,
    routePairCode: value.routePairCode,
  };
};

export const transformRoutePairTargetStateToQueryInput = (
  value: WorkspaceState,
  currency: string,
  routePairCode: string,
  year: number
): RoutePairTargetQueryVariables => {
  return {
    filter: {
      currency: currency,
      routePairCode: routePairCode,
      year: year,
      avgGrossMeterPriceFrom: value.averageGrossPrice.from ?? null,
      avgGrossMeterPriceTo: value.averageGrossPrice.to ?? null,
      avgNetMeterPriceFrom: value.averageNetPrice.from ?? null,
      avgNetMeterPriceTo: value.averageNetPrice.to ?? null,
      custAccountsNetworkVolumeFrom: value.networkVolume.from ?? null,
      custAccountsNetworkVolumeTo: value.networkVolume.to ?? null,
      custAccountsRouteVolumeFrom: value.routeVolume.from ?? null,
      custAccountsRouteVolumeTo: value.routeVolume.to ?? null,
      custCountryCodes: value.countries,
      custNos: value.customers?.map((customer) => Number(customer.code)),
      parentCustomerNumber: Number(value.parentCustomer?.code) ?? undefined,
      custCategoryCodes: value.customerCategories?.map((cc) => cc.code),
      sailingCategories: value.sailingCategories?.map((sc) => sc.code),
      salesRegions: value.regions?.map((r) => Number(r.id)),
      sellers: value.sellers?.map((s) => s.code),
      showWithoutTargets: value.targetStatuses.find(
        (ts) => ts.code === "without_targets"
      )
        ? true
        : false,
      showWithTargets: value.targetStatuses.find(
        (ts) => ts.code === "with_targets"
      )
        ? true
        : false,
      statAccountsNetworkVolumeFrom: value.statNetworkVolume.from ?? null,
      statAccountsNetworkVolumeTo: value.statNetworkVolume.to ?? null,
      statAccountsRouteVolumeFrom: value.statRouteVolume.from ?? null,
      statAccountsRouteVolumeTo: value.statRouteVolume.to ?? null,
      statNos: value.statNumbersAndIndexes?.map((customer) =>
        Number(customer.code)
      ),
      targetFrom: value.target.from ?? null,
      targetTo: value.target.to ?? null,
      unitTypes: value.unitTypes?.map((ut) => ut.code),
    },
  };
};
