import React, { Fragment } from "react";
import { Heading, Indent, Row, Space } from "@stenajs-webui/core";
import { Link, Tag } from "@stenajs-webui/elements";
import { format, parseISO } from "date-fns";
import { useNavigate } from "react-router-dom";
import { routeFactory } from "../../../../RouteFactory";
import { InvoiceSpecificationRow } from "../redux/reducers";
import { formatThousandsAndDecimalsWithNullAndUndefinedValuesAccepted } from "@/common/numbers";

export interface Props {
  publicInvoiceSpecification: InvoiceSpecificationRow;
}

const NoShowLateTypeCode = {
  NO_SHOW: "NOSHOW",
  LATE_HANDL: "LATEHANDL",
};

const transformNslhTypeCode = (code: string) => {
  if (code === NoShowLateTypeCode.NO_SHOW) {
    return "No show";
  }
  return "Late handling";
};

const formatDateStringToDisplayDate = (date: Date) => {
  return format(date, "yyyy-MM");
};

export const InvoiceSpecificationSummaryRow: React.FC<Props> = ({
  publicInvoiceSpecification,
}) => {
  const navigate = useNavigate();
  return (
    <Row
      height={"40px"}
      display={"flex"}
      alignItems={"center"}
      width={"100%"}
      justifyContent={"space-between"}
      indent={3}
      data-testid={publicInvoiceSpecification.nslhInvoice.invoiceNo}
    >
      <Row alignItems={"center"}>
        <Heading variant={"h5"}>
          {publicInvoiceSpecification.nslhInvoice.invoiceNo}
        </Heading>
        <Indent num={2} />
        <Tag
          size={"medium"}
          variant={"info"}
          label={formatDateStringToDisplayDate(
            parseISO(publicInvoiceSpecification.lateHandlingDate)
          )}
        />
        {publicInvoiceSpecification.noShowLateTypeCodes.map((code: string) => (
          <Fragment key={code}>
            <Indent num={0.5} />
            <Tag
              size={"medium"}
              variant={"info"}
              label={transformNslhTypeCode(code)}
            />
          </Fragment>
        ))}
        <Indent num={0.5} />
        <Tag
          size={"medium"}
          variant={"passive"}
          label={`${publicInvoiceSpecification.customer?.custIndex} (${publicInvoiceSpecification.customer?.custNo})`}
        />
      </Row>

      <Row alignItems={"center"}>
        <Heading
          variant={"h5"}
        >{`${formatThousandsAndDecimalsWithNullAndUndefinedValuesAccepted(
          publicInvoiceSpecification.nslhInvoice.netAmount
        )} ${
          publicInvoiceSpecification.nslhInvoice.invoiceCurrencyCode
        }`}</Heading>
        <Space num={2} />
        <Link
          onClick={() =>
            navigate(
              routeFactory.noShowLateHandling.invoiceSpecification(
                String(publicInvoiceSpecification.nslhInvoice.invoiceNo)
              )
            )
          }
        >
          {"View specification"}
        </Link>
      </Row>
    </Row>
  );
};
