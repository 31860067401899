import { NumericTextInput } from "@/common/components/numeric-text-input/NumericTextInput";
import { stenaDetails } from "@stenajs-webui/elements";
import { useFilterWorkspace } from "../../hooks/UseFilterWorkspace";
import { FppSearchFilterSection } from "../FppSearchFilterSection";

interface Props {
  enabled?: boolean;
  label?: string;
}

export const AgreementNumberFilterSection: React.FC<Props> = ({
  enabled,
  label,
}) => {
  const { agreementNumber, setAgreementNumber } = useFilterWorkspace();

  return (
    <FppSearchFilterSection
      enabled={enabled}
      label={label ?? "Agreement Number"}
      numSelected={agreementNumber?.length ? 1 : undefined}
      sectionId={"agreementNumber"}
      leftIcon={stenaDetails}
    >
      <NumericTextInput
        maxDecimals={0}
        value={agreementNumber}
        onValueChange={setAgreementNumber}
      />
    </FppSearchFilterSection>
  );
};
