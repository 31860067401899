import { IndentValues } from "@/common/utils/IndentValues";
import { SpacingValues } from "@/common/utils/SpacingValues";
import { SpaceValues } from "@/common/utils/SpaceValues";
import { ReportScreenCustomerFragment } from "@/gql/graphql";
import {
  Box,
  Column,
  Heading,
  Indent,
  Row,
  Space,
  Spacing,
  Text,
} from "@stenajs-webui/core";
import { Card, CardHeader } from "@stenajs-webui/elements";
import StepBoxBody from "../../../../common/components/StepBoxBody";
import { CustomerContactTable } from "./CustomerContactTable";

type CustomerDetailsStepProps = {
  customerSubscribers: ReportScreenCustomerFragment["subscriberContactsForReport"];
  customer: ReportScreenCustomerFragment | null | undefined;
  reportType: String;
  selectedCustomerSubscriberContact:
    | ReportScreenCustomerFragment["subscriberContactsForReport"]["0"]
    | undefined;
  setSelectedCustomerSubscriberContact: React.Dispatch<
    React.SetStateAction<
      | ReportScreenCustomerFragment["subscriberContactsForReport"]["0"]
      | undefined
    >
  >;
};
export const CustomerDetailsStep = ({
  customerSubscribers,
  reportType,
  customer,
  selectedCustomerSubscriberContact,
  setSelectedCustomerSubscriberContact,
}: CustomerDetailsStepProps) => {
  const customerFirstname =
    selectedCustomerSubscriberContact?.subscriber.firstName;
  const customerLastname =
    selectedCustomerSubscriberContact?.subscriber.surName;
  const customerFullname = "".concat(
    customerFirstname || "",
    " ",
    customerLastname || ""
  );

  const sellerFullName = "".concat(
    customer?.seller.sellerFirstName || "",
    " ",
    customer?.seller.sellerLastName || ""
  );
  return (
    <>
      <CardHeader text="Select customer contact" />
      <Box>
        <StepBoxBody width={"100%"}>
          <CustomerContactTable
            customerContacts={customerSubscribers}
            setSelectedCustomerSubscriberContact={
              setSelectedCustomerSubscriberContact
            }
            selectedCustomerId={
              selectedCustomerSubscriberContact?.subscriber.id ||
              selectedCustomerSubscriberContact?.id ||
              ""
            }
          />
          <Space num={SpaceValues.TWENTYFOUR} />
          <Card height={"379px"}>
            <Space num={SpaceValues.TWENTYFOUR} />
            <Box alignItems={"center"}>
              <Heading variant="h3">Freight Agreement {reportType}</Heading>
            </Box>
            <Indent num={IndentValues.TWENTYFOUR}>
              <Spacing num={SpacingValues.SIXTEEN} />
              <Heading variant="h4">{customer?.name}</Heading>
              <Spacing num={SpacingValues.EIGHT} />
              <Row justifyContent={"space-between"} width={"100%"}>
                <Column>
                  <Box>
                    <Heading variant="h5">Stena Line contact details</Heading>
                    <Space num={SpaceValues.FOUR} />
                    <Text>{sellerFullName}</Text>
                    <Space num={SpaceValues.FOUR} />
                    {customer?.seller.sellerPhoneNumber ? (
                      <Text>{customer?.seller.sellerPhoneNumber || ""}</Text>
                    ) : (
                      <Text>{customer?.seller.sellerEmailAddress || ""}</Text>
                    )}
                  </Box>
                </Column>
                <Column>
                  <Box>
                    <Heading variant="h5">Customer contact details</Heading>
                    <Space num={SpaceValues.FOUR} />
                    <Text>{customerFullname}</Text>
                    <Space num={SpaceValues.FOUR} />
                    <Text>
                      {selectedCustomerSubscriberContact?.subscriberContactId
                        ? selectedCustomerSubscriberContact.mobile
                        : selectedCustomerSubscriberContact?.subscriber.telNo}
                    </Text>
                    <Space num={SpaceValues.FOUR} />
                    <Text>
                      {selectedCustomerSubscriberContact?.email ?? ""}
                    </Text>
                  </Box>
                </Column>
              </Row>
            </Indent>
            <Space num={SpaceValues.TWENTYFOUR} />
          </Card>
        </StepBoxBody>
      </Box>
    </>
  );
};
