import { InfoPair } from "@/common/components/info-pair/InfoPair";
import { formatDateStringRangeWithSwedishTimeZone } from "@/common/dates/formatters";
import { formatLongSalingTypeCodesToString } from "@/common/formatters/SailingStatusCodeFormatter";
import { Row, Space, Text } from "@stenajs-webui/core";
import { SecondaryButton, Tag, stenaAngleRight } from "@stenajs-webui/elements";
import { cssColor } from "@stenajs-webui/theme";
import React, { CSSProperties, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { routeFactory } from "../../../../RouteFactory";
import { HeadAgreementStatusCodeChip } from "../../../../common/components/chips/HeadAgreementStatusCodeChip";
import { SwitchWithLabelForRouteAgreementSalesReady } from "../../../../common/components/data-driven-inputs/switches/SwitchWithLabelForRouteAgreementSalesReady";
import { StoreState } from "../../../../config/redux/RootReducer";
import { RateSheetTable } from "../../rate-sheet/components/RateSheetTable";
import { routeAgreementCanBeEditedByStatusCode } from "../../route-agreement/util/RouteAgreementCalculator";
import { customerRouteRedux } from "../redux";
import {
  CustomerRouteFetcherHeadAgreementFragment,
  CustomerRouteFetcherRouteAgreementFragment,
} from "@/gql/graphql";
import { testIdConstants } from "@/common/test-id-constants/testIdConstants";

interface Props {
  headAgreement: CustomerRouteFetcherHeadAgreementFragment;
  routeAgreement: CustomerRouteFetcherRouteAgreementFragment;
  routePairId: string;
  customerId: string;
  bulkUpdateModeEnabled: boolean;
}

const ListItemTag: React.FC<{ label: string | undefined }> = ({ label }) => {
  return (
    <Tag
      style={
        {
          "--swui-tag-border-radius": "4px",
        } as CSSProperties
      }
      label={label}
    />
  );
};

export const CustomerRateSheetListItem: React.FC<Props> = React.memo(
  ({
    routeAgreement,
    headAgreement,
    routePairId,
    customerId,
    bulkUpdateModeEnabled,
  }) => {
    const { id } = routeAgreement;
    const salingTypeCodes = formatLongSalingTypeCodesToString(
      routeAgreement.sailingTypeCodes
    );

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const selector = useCallback(
      (state: StoreState) =>
        state.customerRoute.routeAgreements[id]?.editable.isSalesReady,
      [id]
    );

    const isSalesReady = useSelector(selector);

    const setSalesReady = useCallback(
      (isSalesReady) =>
        dispatch(customerRouteRedux.actions.setSalesReady(id, isSalesReady)),
      [id, dispatch]
    );

    return (
      <RateSheetTable
        hideBulkUpdateButton
        routeAgreementId={routeAgreement.id}
        routeAgreement={routeAgreement}
        currencyLabel={routeAgreement.currency.code}
        bulkUpdateModeEnabled={bulkUpdateModeEnabled}
        headerLeft={
          <Row alignItems={"center"}>
            <Text size={"large"}>{routeAgreement.agreementNumber}</Text>
            <Space num={2} />
            <Text size={"large"}>{routeAgreement.routeDescription}</Text>
            {routeAgreement.name && (
              <>
                <Space num={2} />
                <InfoPair
                  leftContent={"Name"}
                  leftTextColor={cssColor("--lhds-color-ui-500")}
                  borderBottom={false}
                  rightContent={routeAgreement.name}
                />
              </>
            )}
          </Row>
        }
        headerRight={
          <SecondaryButton
            label={"To agreement"}
            data-testid={testIdConstants.routeRatesToAgreementButton}
            rightIcon={stenaAngleRight}
            onClick={() =>
              navigate(
                routeFactory.productAndPrice.routeAgreement.routeAgreementDetails(
                  {
                    customerId,
                    headAgreementId: headAgreement.id,
                    routeAgreementId: routeAgreement.id,
                    routePairId,
                    tab: "rates",
                  }
                )
              )
            }
          />
        }
        contentBelowHeader={
          <Row alignItems={"center"}>
            {routeAgreementCanBeEditedByStatusCode(
              routeAgreement.statusCode
            ) ? (
              <>
                <SwitchWithLabelForRouteAgreementSalesReady
                  routeAgreement={routeAgreement}
                  disabled={bulkUpdateModeEnabled}
                  label={"Sales ready"}
                  value={isSalesReady}
                  onValueChange={setSalesReady}
                />
                <Space />
              </>
            ) : null}
            <HeadAgreementStatusCodeChip
              statusCode={routeAgreement.statusCode}
            />
            <Space />
            <ListItemTag
              label={formatDateStringRangeWithSwedishTimeZone({
                dateStringFrom: routeAgreement.valid.start?.isoString,
                dateStringTo: routeAgreement.valid.end?.isoString,
              })}
            />
            <Space />
            <ListItemTag label={salingTypeCodes} />
            {routeAgreement.hasArticles && (
              <>
                <Space />
                <ListItemTag label={"Articles"} />
              </>
            )}
            {routeAgreement.hasConditions && (
              <>
                <Space />
                <ListItemTag label={"Conditions"} />
              </>
            )}
            <Space />
            <ListItemTag label={routeAgreement.currency.code} />
          </Row>
        }
      />
    );
  }
);
