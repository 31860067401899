import { Box, Space, Text } from "@stenajs-webui/core";
import React, { useContext } from "react";
import { AuditData } from "../../PriceCalculatorFormFetcher";
import { transformAuditLogsToText } from "../transformers/TransformAuditTrackText";

import {
  FeatureFlagsContext,
  FeatureFlagsContextType,
} from "@/config/featureFlags/FeatureFlagsContext";
import { SpacingValues } from "@/common/utils/SpacingValues";
import { IndentValues } from "@/common/utils/IndentValues";

interface Props {
  auditData: AuditData[] | null;
}

export const PriceCalculatorAuditTab: React.FC<Props> = ({ auditData }) => {
  const { featureFlags } = useContext(
    FeatureFlagsContext
  ) as FeatureFlagsContextType;

  const usePricingV2 = featureFlags.pricing.usePricingV2;

  const auditDataError = () =>
    usePricingV2 && auditData && auditData.filter((ad) => ad.errorMessage)
      ? true
      : false;

  const auditErrorWithLogs = () => {
    if (auditDataError() && usePricingV2 && auditData) {
      return (
        <Box
          spacing={SpacingValues.TWENTYFOUR}
          indent={IndentValues.TWENTYFOUR}
        >
          {auditData.map(
            (ad) =>
              ad.errorMessage && (
                <>
                  <Text variant={"bold"}>{`Error: ${ad.errorMessage}`}</Text>
                  <Space num={0.5} />
                </>
              )
          )}
          <Space />
          {auditData?.map((leg) =>
            transformAuditLogsToText(leg.auditLogs, usePricingV2)
          )}
        </Box>
      );
    }
  };

  return (
    <>
      {auditDataError()
        ? auditErrorWithLogs()
        : auditData?.map((leg, index) => (
            <Box
              spacing={SpacingValues.TWENTYFOUR}
              indent={IndentValues.TWENTYFOUR}
              key={index}
            >
              {transformAuditLogsToText(leg.auditLogs, usePricingV2)}
            </Box>
          ))}
    </>
  );
};
