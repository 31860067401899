import * as React from "react";
import { gql, useQuery } from "@apollo/client";
import { ZIndex } from "@/common/utils/ZIndexEnum";
import { Indent } from "@stenajs-webui/core";
import {
  BreadCrumbs,
  ResultListBanner,
  TabMenu,
  UseResultListBannerStateResult,
  stenaBusinessAgreement,
} from "@stenajs-webui/elements";
import { Crumb } from "../../../../../common/components/bread-crumbs/Crumb";
import { PageHeader } from "../../../../../common/components/page-header/PageHeader";
import { Tab } from "../../../../../common/components/tabs/Tab";
import { TabsRouting } from "../../../../../common/navigation/agreement-tabs/AgreementTabsRouting";
import { routeFactory } from "../../../../../RouteFactory";
import { useCustomer } from "../../../customers/common/customer-header/UseCustomer";
import { useNegotiationYears } from "../../../customers/common/negotiation-year/NegotiationYearGate";
import { routeAgreementCanBeEdited } from "../../util/RouteAgreementCalculator";

import { RouteAgreementSaveButton } from "./RouteAgreementSaveButton";
import { SalesReadySwitch } from "./SalesReadySwitch";
import { testIdConstants } from "@/common/test-id-constants/testIdConstants";
import {
  RouteAgreementDetailsHeaderQuery,
  RouteAgreementDetailsHeaderQueryVariables,
} from "@/gql/graphql";
import { routeAgreementPageFragment } from "../fragments/RouteAgreementPageFragment";

interface Props {
  customerId: string;
  headAgreementId: string;
  routePairId: string;
  routeAgreementId: string;
  bannerHook: UseResultListBannerStateResult;
}

export const routeAgreementQuery = gql`
  ${routeAgreementPageFragment}
  query RouteAgreementDetailsHeader($routeAgreementId: ID!) {
    productPrice {
      routeAgreement {
        byId(id: $routeAgreementId) {
          ...RouteAgreementPageRouteAgreement
        }
      }
    }
  }
`;

export const RouteAgreementDetailsHeader: React.FC<Props> = (props) => {
  const {
    customerId,
    routePairId,
    routeAgreementId,
    headAgreementId,
    bannerHook,
  } = props;

  const { loading, data } = useQuery<
    RouteAgreementDetailsHeaderQuery,
    RouteAgreementDetailsHeaderQueryVariables
  >(routeAgreementQuery, {
    variables: {
      routeAgreementId,
    },
  });

  const { defaultYear } = useNegotiationYears();
  const { customer } = useCustomer(customerId, defaultYear);

  const headAgreement = data?.productPrice.routeAgreement.byId?.headAgreement;
  const routeAgreement = data?.productPrice.routeAgreement.byId;

  const path =
    routeFactory.productAndPrice.routeAgreement.routeAgreementDetails(props);

  return (
    <PageHeader
      title={"Route agreement"}
      loading={loading}
      icon={stenaBusinessAgreement}
      zIndex={ZIndex.low}
      contentRight={
        routeAgreement && (
          <>
            <SalesReadySwitch
              routeAgreementId={routeAgreementId}
              routeAgreement={routeAgreement}
            />
            <Indent num={2} />
            <RouteAgreementSaveButton
              routeAgreementId={routeAgreementId}
              rowVersion={routeAgreement.rowVersion}
              editableRouteAgreement={routeAgreementCanBeEdited(routeAgreement)}
              bannerHook={bannerHook}
            />
          </>
        )
      }
      contentUnder={
        bannerHook.bannerState && (
          <ResultListBanner
            bannerState={bannerHook.bannerState}
            variant={"error"}
            data-testid={testIdConstants.createRouteRatesErrorBanner}
          />
        )
      }
      breadcrumbs={
        <BreadCrumbs>
          <Crumb
            label={"Negotiation plan"}
            path={routeFactory.productAndPrice.customer.customerList()}
          />
          {customer && (
            <Crumb
              label={customer?.breadCrumbName ?? ""}
              path={routeFactory.productAndPrice.customer.customerHeadAgreement(
                {
                  customerId,
                  headAgreementId,
                }
              )}
              isLast={!headAgreement}
              dataTestid={testIdConstants.breadcrumbCustomer}
            />
          )}
          {headAgreement && (
            <Crumb
              label={routePairId.toUpperCase()}
              path={routeFactory.productAndPrice.customer.customerRoutePair({
                customerId,
                headAgreementId: headAgreement.id,
                routePairId,
              })}
              isLast={!routeAgreement}
            />
          )}
          {headAgreement && routeAgreement && (
            <Crumb
              label={String(routeAgreement.agreementNumber)}
              path={routeFactory.productAndPrice.routeAgreement.routeAgreementDetails(
                {
                  customerId,
                  headAgreementId: headAgreement.id,
                  routePairId,
                  routeAgreementId,
                  tab: "rates",
                }
              )}
              isLast
            />
          )}
        </BreadCrumbs>
      }
      tabs={
        <TabMenu>
          <Tab
            label={TabsRouting.Rates}
            path={path + TabsRouting.RatesPath}
            dataTestid={testIdConstants.routeAgreementRatesTab}
          />
          <Tab
            label={TabsRouting.Routes}
            path={path + TabsRouting.RoutesPath}
            dataTestid={testIdConstants.routeAgreementRoutesTab}
          />
          <Tab
            label={TabsRouting.Articles}
            path={path + TabsRouting.ArticlesPath}
            hasBadge={routeAgreement?.hasArticles}
          />
          <Tab label={TabsRouting.Notes} path={path + TabsRouting.NotesPath} />
          <Tab
            label={TabsRouting.Conditions}
            path={path + TabsRouting.ConditionsPath}
            hasBadge={routeAgreement?.hasConditions}
          />
          <Tab
            label={TabsRouting.Matrix}
            path={path + TabsRouting.MatrixPath}
            hasBadge={routeAgreement?.hasMatrix}
          />
        </TabMenu>
      }
    />
  );
};
