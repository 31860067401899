import { stenaUserGroupLarge } from "@stenajs-webui/elements";
import { ChipMultiSelect } from "@stenajs-webui/select";
import { compact } from "lodash";
import * as React from "react";
import { useMemo } from "react";
import { useFilterWorkspace } from "../../hooks/UseFilterWorkspace";
import { Seller } from "../../types/FilterEntitys";
import { FppSearchFilterSection } from "../FppSearchFilterSection";
import { useFetchAllSellers } from "../../hooks/FilterFetchers/UseFetchAllSellers";

interface SellerOptionGroup {
  label: string;
  options: Array<SellerOption>;
}

interface SellerOption {
  label: string;
  value: string;
}

interface Props {
  enabled?: boolean;
  label?: string;
}
export const SellersFilterSection: React.FC<Props> = (props) =>
  props.enabled ? <SellersFilterSectionInternal {...props} /> : <></>;

const SellersFilterSectionInternal: React.FC<Props> = ({ enabled, label }) => {
  const { sellers, setSellers } = useFilterWorkspace();
  const { sellers: items, refetch, error, loading } = useFetchAllSellers();

  const onSellersChange = (sellers: string[]) => {
    setSellers(
      sellers.map<Seller>((s) => ({
        id: s,
        code: s,
        name: s,
      }))
    );
  };

  const otherUsers = useMemo(() => {
    return items.filter((item) => item.code !== "me") ?? [];
  }, [items]);

  const meOptions = useMemo<Array<SellerOption>>(() => {
    return items
      .filter((item) => item.code === "me")
      .map((item) => ({
        label: item.name,
        value: item.id,
      }));
  }, [items]);

  const otherUsersOptions = useMemo<Array<SellerOption>>(() => {
    return otherUsers.map((s) => ({
      label: s.name.toUpperCase().replace("LINE\\", ""),
      value: s.id,
    }));
  }, [otherUsers]);

  const allOptionsAsList = useMemo<Array<SellerOption>>(
    () => [...meOptions, ...otherUsersOptions],
    [meOptions, otherUsersOptions]
  );

  const options = useMemo<Array<SellerOptionGroup>>(
    () => [
      {
        label: "Me",
        options: meOptions,
      },
      {
        label: "Others",
        options: otherUsersOptions,
      },
    ],
    [meOptions, otherUsersOptions]
  );

  const selected = useMemo<Array<SellerOption>>(
    () =>
      sellers
        ? compact(
            sellers.map((seller) =>
              allOptionsAsList.find((o) => o.value === seller.code)
            )
          )
        : [],
    [allOptionsAsList, sellers]
  );

  return (
    <FppSearchFilterSection
      sectionId={"sellers"}
      label={label}
      enabled={enabled}
      numSelected={sellers.length}
      leftIcon={stenaUserGroupLarge}
      error={error?.message}
      loading={loading}
      onRetry={refetch}
      children={
        <ChipMultiSelect
          loading={loading}
          options={options}
          value={selected}
          onValueChange={(sellers: SellerOption[]) =>
            onSellersChange?.(
              sellers?.map((seller: SellerOption) => seller.value) ?? []
            )
          }
        />
      }
    />
  );
};
