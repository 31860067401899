import { SpacingValues } from "@/common/utils/SpacingValues";
import {
  ReportScreenHeadagreementFragment,
  SurchargeDocumentsQuery,
} from "@/gql/graphql";
import { Spacing } from "@stenajs-webui/core";
import { CardHeader, stenaLockClosed } from "@stenajs-webui/elements";
import { TableContext } from "@stenajs-webui/grid";
import { LoadingModal } from "@stenajs-webui/modal";
import { useEffect } from "react";
import StepBoxBody from "../../common/components/StepBoxBody";
import StepContainer from "../../common/components/StepContainer";
import { SurchargesTable } from "./SurchargesTable";
import { AdditionalSurchargeTableColumnKeys } from "./common/surcharges-table-config";

interface Props {
  tableContext: TableContext<AdditionalSurchargeTableColumnKeys>;
  headAgreementId: string;
  customerInvoiceCurrency: string;
  setIsNextAvailable: React.Dispatch<React.SetStateAction<boolean>>;
  yearValidFrom: number;
  yearValidTo: number;
  loading: boolean;
  additionalSurchargeDocuments: SurchargeDocumentsQuery["productPrice"]["additionalCharges"]["all"];
  isCompletingWizard: boolean;
  selectedAgreements:
    | {
        selectedRouteAgreements: ReportScreenHeadagreementFragment["routeAgreements"];
        selectedMultilegRouteAgreements: ReportScreenHeadagreementFragment["multiLegAgrRoutes"];
      }
    | undefined;
}

const SurchargeStep = ({
  tableContext,
  setIsNextAvailable,
  loading,
  additionalSurchargeDocuments,
  isCompletingWizard,
  customerInvoiceCurrency,
  selectedAgreements,
}: Props) => {
  useEffect(() => {
    setIsNextAvailable(!loading);
  }, [loading, setIsNextAvailable]);

  return (
    <StepContainer>
      {isCompletingWizard && (
        <LoadingModal
          headerText={"Creating agreement..."}
          headerIconLeft={stenaLockClosed}
        />
      )}
      <CardHeader text="Surcharge appendices to include" />
      <StepBoxBody width={"100%"}>
        <Spacing num={SpacingValues.TWELVE} />

        <SurchargesTable
          tableContext={tableContext}
          loading={loading}
          additionalSurchargeDocuments={additionalSurchargeDocuments}
          selectedAgreements={selectedAgreements}
          customerInvoiceCurrency={customerInvoiceCurrency}
        />
      </StepBoxBody>
    </StepContainer>
  );
};
export default SurchargeStep;
