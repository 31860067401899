import { ErrorDisplay } from "@/common/error/components/ErrorDisplay";
import { Box, Row, SeparatorLine, Spacing } from "@stenajs-webui/core";
import {
  Banner,
  Card,
  CardHeader,
  FlatButtonLink,
  stenaInfoCircle,
} from "@stenajs-webui/elements";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { routeFactory } from "../../../../RouteFactory";
import {
  setNslhSearchInvoicesByInvoiceNoData,
  setNslhSearchInvoicesCustomerSelectedData,
} from "../redux/reducers";
import { createNoShowLateHandlingSearchInvoicesStateSelectors } from "../redux/selectors";
import { InvoiceSpecificationSummaryRow } from "./InvoiceSpecificationSummaryRow";
import { testIdConstants } from "@/common/test-id-constants/testIdConstants";

const numberOfInvoicesPerPage = 25;

const NoResultsFound: React.FC = () => (
  <Row justifyContent={"center"}>
    <Banner
      icon={stenaInfoCircle}
      variant={"info"}
      text={"Your search did not return any result. Please try again"}
    />
  </Row>
);

const NoResultsFoundSearchingByInvoiceNo: React.FC<{ invoiceNo: string }> = ({
  invoiceNo,
}) => (
  <Row justifyContent={"center"} width={"100%"}>
    <Banner
      icon={stenaInfoCircle}
      variant={"info"}
      text={`No invoice with ${invoiceNo} exists. Please try again.`}
    />
  </Row>
);

const SearchHelpText: React.FC = () => (
  <Row justifyContent={"center"} width={"100%"}>
    <Banner
      data-testid={testIdConstants.noInvoicesBanner}
      icon={stenaInfoCircle}
      variant={"info"}
      text={"Please enter invoice or customer number to search for invoices"}
    />
  </Row>
);

export const InvoiceSpecificationList: React.FC = () => {
  const dispatch = useDispatch();

  const invoices = useSelector(
    createNoShowLateHandlingSearchInvoicesStateSelectors.getNslhSearchInvoicesByFilterData
  );

  const invoiceNoInput = useSelector(
    createNoShowLateHandlingSearchInvoicesStateSelectors.getNslhSearchInvoicesInvoiceNoInput
  );

  const consignments = useSelector(
    createNoShowLateHandlingSearchInvoicesStateSelectors.getNslhSearchInvoicesByInvoiceNoData
  );

  const error = useSelector(
    createNoShowLateHandlingSearchInvoicesStateSelectors.getNslhSearchInvoicesError
  );

  const navigate = useNavigate();

  const [page, setPage] = useState(1);

  useEffect(() => {
    if (consignments && consignments.length === 1) {
      const { invoiceNo } = consignments[0];
      dispatch(setNslhSearchInvoicesByInvoiceNoData(undefined));
      dispatch(setNslhSearchInvoicesCustomerSelectedData(null));
      navigate(
        routeFactory.noShowLateHandling.invoiceSpecification(String(invoiceNo))
      );
    }
  }, [consignments, navigate, dispatch]);

  const {
    displayedInvoices,
    morePages,
    numberOfShownInvoices,
    totalNumberOfInvoices,
  } = useMemo(() => {
    const totalNumberOfInvoices = invoices?.length ?? 0;
    const numberOfShownInvoices =
      totalNumberOfInvoices >= numberOfInvoicesPerPage * page
        ? page * numberOfInvoicesPerPage
        : totalNumberOfInvoices;
    const morePages = numberOfShownInvoices < totalNumberOfInvoices;
    const displayedInvoices = invoices?.slice(0, numberOfShownInvoices) ?? [];

    return {
      displayedInvoices,
      morePages,
      numberOfShownInvoices,
      totalNumberOfInvoices,
    };
  }, [invoices, page]);

  if (error) {
    return (
      <>
        <Spacing />
        <Box alignItems={"center"} width={"100%"}>
          <ErrorDisplay error={error} />
        </Box>
      </>
    );
  }

  if (!invoices && !consignments) {
    return <SearchHelpText />;
  }

  if (consignments && consignments.length === 0) {
    return <NoResultsFoundSearchingByInvoiceNo invoiceNo={invoiceNoInput} />;
  }

  return (
    <Box width={"100%"}>
      {displayedInvoices.length === 0 ? (
        <>
          <NoResultsFound />
        </>
      ) : (
        <Card>
          <>
            <CardHeader
              text={`Showing ${numberOfShownInvoices} of ${totalNumberOfInvoices}`}
            />
            <SeparatorLine />
            {displayedInvoices.map((invoice) => (
              <React.Fragment key={invoice.id}>
                <InvoiceSpecificationSummaryRow
                  publicInvoiceSpecification={invoice}
                />
                <SeparatorLine />
              </React.Fragment>
            ))}
            {morePages && (
              <Row justifyContent={"center"}>
                <Spacing num={2}>
                  <FlatButtonLink
                    variant={"normal"}
                    onClick={() => setPage(page + 1)}
                    size={"large"}
                    label={`Show ${numberOfInvoicesPerPage} more`}
                  />
                </Spacing>
              </Row>
            )}
          </>
        </Card>
      )}
    </Box>
  );
};
