import { SpaceValues } from "@/common/utils/SpaceValues";
import { ReportScreenQuery } from "@/gql/graphql";
import { Box, Row, Space } from "@stenajs-webui/core";
import { useRef } from "react";
import StepContainer from "../../common/components/StepContainer";
import { useGetContainerWidth } from "../hooks/getRowContainerWidth";
import ConditionsStep from "./components/conditions/ConditionsStep";
import { LastPageStep } from "./components/last-page/LastPageStep";

export const ConditionsWithLastPage = ({
  reportType,
  conditionsTexts,
  setConditionsTexts,
  lastPageText,
  setLastPageText,
}: {
  reportType: string;
  conditionsTexts: {
    condition: string;
    agreementTexts: ReportScreenQuery["productPrice"]["agreementTexts"]["byAgreementTextCodes"];
  };
  setConditionsTexts: React.Dispatch<
    React.SetStateAction<{
      condition: string;
      agreementTexts: ReportScreenQuery["productPrice"]["agreementTexts"]["byAgreementTextCodes"];
    }>
  >;
  lastPageText: string;
  setLastPageText: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const rowContainer = useRef<HTMLDivElement>(null);
  const { containerWidth } = useGetContainerWidth(rowContainer);

  return (
    <StepContainer>
      <Row width={"100%"} flexWrap={"wrap"} ref={rowContainer}>
        <Box
          width={containerWidth}
          minWidth={"1000px"}
          justifyContent={"space-between"}
        >
          <ConditionsStep
            reportType={reportType}
            conditionsTexts={conditionsTexts}
            setConditionsTexts={setConditionsTexts}
          />
        </Box>
        <Box width={containerWidth} minWidth={"1000px"}>
          <LastPageStep
            lastPageText={lastPageText}
            setLastPageText={setLastPageText}
          />
        </Box>
        <Space num={SpaceValues.SIXTEEN} />
      </Row>
    </StepContainer>
  );
};
