import { SeparatorLine, useBoolean } from "@stenajs-webui/core";
import {
  ActionMenu,
  ActionMenuItem,
  FlatButton,
  stenaBan,
  stenaCheckCircle,
  stenaClock,
  stenaCopy,
  stenaDots,
  stenaFileCsv,
  stenaHistory,
  stenaTrash,
} from "@stenajs-webui/elements";
import { Popover } from "@stenajs-webui/tooltip";
import * as React from "react";

interface Props {}

export const RebateTableActionButtons: React.FC<Props> = () => {
  const [popoverOpen, openPopover, closePopover] = useBoolean(false);

  return (
    <Popover
      animation={false}
      disablePadding
      placement={"left-start"}
      appendTo={document.body}
      visible={popoverOpen}
      onClickOutside={closePopover}
      content={
        <ActionMenu>
          <ActionMenuItem
            leftIcon={stenaCheckCircle}
            label={"Activate"}
            onClick={closePopover}
          />
          <SeparatorLine />
          <ActionMenuItem
            leftIcon={stenaClock}
            label={"Pending"}
            onClick={closePopover}
          />
          <SeparatorLine />
          <ActionMenuItem
            leftIcon={stenaBan}
            label={"Void"}
            onClick={closePopover}
          />
          <SeparatorLine />
          <ActionMenuItem
            leftIcon={stenaHistory}
            label={"Reactivate"}
            onClick={closePopover}
          />
          <SeparatorLine />
          <ActionMenuItem
            leftIcon={stenaFileCsv}
            label={"Create report"}
            onClick={closePopover}
          />
          <SeparatorLine />
          <ActionMenuItem
            leftIcon={stenaCopy}
            label={"Copy"}
            onClick={closePopover}
          />
          <SeparatorLine />
          <ActionMenuItem
            leftIcon={stenaTrash}
            label={"Delete"}
            onClick={closePopover}
            variant={"danger"}
          />
        </ActionMenu>
      }
    >
      <FlatButton leftIcon={stenaDots} onClick={openPopover} />
    </Popover>
  );
};
