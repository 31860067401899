import { isDefined } from "@/common/types/IsDefined";
import {
  Ancillary,
  BookingSearchResultData,
  CustomerSearchResultData,
  MovementType,
  PriceCalculatorFormData,
  RouteLeg,
} from "../../PriceCalculatorFormFetcher";
import {
  ConsignmentQuery,
  FetchConsignmentFragment,
  PriceCalcInput,
  PriceCalculatorMultiLegResultQuery,
  PriceCalculatorResultQuery,
} from "@/gql/graphql";

const transformToNoOfDriversFromConsignment = (
  data: FetchConsignmentFragment
) =>
  (data.numOfAdults ?? 0) +
  (data.numOfChildren ?? 0) +
  (data.numOfInfants ?? 0);

const transformToNoOfDriversFromInput = (data: PriceCalculatorFormData) =>
  (data.passengersAdults ?? 0) +
  (data.passengersChildren ?? 0) +
  (data.passengersInfants ?? 0);

export const transformToPriceCalculatorInput = (
  data: PriceCalculatorFormData
): PriceCalcInput => {
  return {
    ancillaries: data.conditionsAncillary,
    goodsRecords: data.conditionsGoods
      ? data.conditionsGoods?.map((cg) => {
          return {
            goodsCode: cg.goodsCode,
            weight: Number(cg.weight),
          };
        })
      : null,
    customerNumber: data.selectedCustomerNum,
    departureDate: data.sailingDate,
    departureTime: data.departureTime?.replace(":", ""),
    hazardousGoodsRowWeight: Number(data.cargoHazardousGoodsRowWeight),
    includeImoClass1Row: data.cargoHazardousGoods,
    length: parseFloat(data.vehicleLength ?? ""),
    livestock: data.livestock,
    livestockType: data.livestockType,
    loadingPreferenceCode: data.loadingPreference,
    movementType: data.movementType === MovementType.SECTIONAL ? "S" : "M",
    multiLegRouteCode: data.multiLegRouteData?.code,
    routeLegs:
      data.routeLegs?.map(transformRouteLegToPriceCalculatorInputRouteLeg) ??
      [],
    noOfAdults: data.passengersAdults,
    noOfChildren: data.passengersChildren,
    noOfDrivers:
      data.movementType === MovementType.SECTIONAL
        ? transformToNoOfDriversFromInput(data)
        : 0,
    noOfFoc: 0,
    noOfHazardousGoodsRows: data.cargoHazardousGoodsAmountRows,
    noOfInfants: data.passengersInfants,
    noOfPlugins: data.vehiclePlugins ?? 0,
    noOfSleepers: data.passengersSleepers,
    noOfTradeVehicles:
      data.vehicleTradeWeight && Number(data.vehicleTradeWeight) > 0 ? 1 : 0,
    noOfVehTypeUnits: data.vehicleTradeUnits,
    offeredAgreementsAllowed: data.includePendingOffered,
    pendingAgreementsAllowed: data.includePendingOffered,
    qualifiedGoods: data.cargoHazardousGoodsQualified ?? false,
    receiverCountryCode: data.conditionsReceiverCountryCode,
    routeCode: data.routeCode ?? "",
    sailingTypeCode: data.sailingType,
    senderCountryCode: data.conditionsSenderCountryCode,
    standBy: data.conditionsStandby ?? false,
    tradeVehicleWeight: Number(data.vehicleTradeWeight),
    transit: data.conditionsTransit ?? false,
    vehicleTypeCode: data.vehicleType,
    weight: Number(data.vehicleWeight),
    width: parseFloat(data.vehicleWidth ?? ""),
  };
};

export const transformBookingToPriceCalculatorInput = (
  data: FetchConsignmentFragment
): PriceCalcInput => {
  return {
    routeLegs: null,
    movementType: data.movementType.code,
    length: data.length,
    width: data.width,
    weight: data.weight,
    noOfAdults: data.numOfAdults,
    noOfChildren: data.numOfChildren,
    noOfDrivers:
      data.movementType.description.toUpperCase() === MovementType.SECTIONAL
        ? transformToNoOfDriversFromConsignment(data)
        : 0,
    noOfFoc: data.numOfFoc,
    noOfInfants: data.numOfInfants,
    noOfSleepers: data.numOfSleepers,
    vehicleTypeCode: data.vehicleType.code,
    customerNumber: data.custNo,
    routeCode: data.sailingArchive.route.code,
    departureDate: data.sailingArchive.departureDate,
    departureTime: data.sailingArchive.departureTime?.replace(":", ""),
    sailingTypeCode: data.sailingArchive.sailingType,
    ancillaries: transformToAncillaries(data.consignmentArticles),
    goodsRecords: data.consignmentGoods
      ? data.consignmentGoods?.map((cg) => {
          return {
            goodsCode: cg.goodsCode,
            weight: cg.weight,
          };
        })
      : null,
    hazardousGoodsRowWeight: transformToHazardousGoodsWeight(
      data.consignmentHazardousGoods
    ),
    includeImoClass1Row: data.consignmentHazardousGoods
      ? transformToIncludeIMOClass1Row(data.consignmentHazardousGoods)
      : null,
    livestock: data.livestock,
    livestockType: data.livestockType?.code ?? "",
    loadingPreferenceCode: transformToLoadingPreferenceCode(
      data.consignmentLoadingPreferences
    ),
    multiLegRouteCode: data.multiLegRoute?.code ?? "",

    noOfHazardousGoodsRows: data.consignmentHazardousGoods.length,
    noOfPlugins: data.numOfPlugIn ?? 0,
    noOfTradeVehicles: data.numOfTradeVehicles,
    noOfVehTypeUnits: data.numOfVehicleTypeUnits,
    offeredAgreementsAllowed: false,
    pendingAgreementsAllowed: false,
    qualifiedGoods: data.qualifiedGoods,
    receiverCountryCode: transformToCountryCode(
      data.consignmentAddresses,
      false
    ),
    senderCountryCode: transformToCountryCode(data.consignmentAddresses, true),
    standBy: data.standby,
    tradeVehicleWeight: data.numOfTradeVehicles === 0 ? null : 0,
    transit: data.transit ?? false,
  };
};

const transformRouteLegToPriceCalculatorInputRouteLeg = (
  routeLeg: RouteLeg
): RouteLeg => {
  return {
    departureDate: routeLeg.departureDate,
    departureTime: routeLeg.departureTime?.replace(":", ""),
    routeCode: routeLeg.routeCode,
    sailingTypeCode: routeLeg.sailingTypeCode,
  };
};

const transformToAncillaries = (
  value: ConsignmentQuery["priceCalc"]["consignments"]["byId"][0]["consignmentArticles"]
): Ancillary[] => {
  return value
    .map((article) => {
      if (article.articleType.type === "ANC") {
        return {
          ancillaryCode: article.articleCode,
          quantity: article.quantity,
        };
      }
      return undefined;
    })
    .filter(isDefined);
};

export const transformToHazardousGoodsWeight = (
  value: ConsignmentQuery["priceCalc"]["consignments"]["byId"][0]["consignmentHazardousGoods"]
): number => {
  return value.reduce((acc, curr) => acc + (curr.weight || 0), 0);
};

const transformToIncludeIMOClass1Row = (
  value: ConsignmentQuery["priceCalc"]["consignments"]["byId"][0]["consignmentHazardousGoods"]
): boolean => {
  return value.length > 0;
};

export const transformToLoadingPreferenceCode = (
  value: ConsignmentQuery["priceCalc"]["consignments"]["byId"][0]["consignmentLoadingPreferences"]
): string => {
  let loadingPreferenceCode = "";
  for (let i = 0; i < value.length; i++) {
    loadingPreferenceCode = value[i].loadingPreferenceCode;
  }
  return loadingPreferenceCode;
};

export const transformToCountryCode = (
  value: ConsignmentQuery["priceCalc"]["consignments"]["byId"][0]["consignmentAddresses"],
  sender: boolean = true
): string => {
  let countryCode = "";
  for (let i = 0; i < value.length; i++) {
    if (sender && value[i].addressTypeCode === "S") {
      countryCode = value[i].countryCode ?? "";
    }
    if (!sender && value[i].addressTypeCode === "R") {
      countryCode = value[i].countryCode ?? "";
    }
  }
  return countryCode;
};

export const transformToCustomerSearchResultData = (
  queryData: PriceCalculatorResultQuery,
  formData: PriceCalculatorFormData
): CustomerSearchResultData => {
  if (queryData.priceCalc.byInput.__typename === "PriceCalc") {
    return {
      formData: formData,
      priceCalcData: [queryData.priceCalc.byInput],
      handledError: undefined,
    };
  }
  return {
    formData: formData,
    priceCalcData: [],
    handledError: queryData.priceCalc.byInput,
  };
};

export const transformToCustomerSearchResultMultiLegData = (
  queryData: PriceCalculatorMultiLegResultQuery,
  formData: PriceCalculatorFormData
): CustomerSearchResultData => {
  if (
    queryData.priceCalc.byMultiLegInput.__typename === "PriceCalcMultipleList"
  ) {
    return {
      formData: formData,
      priceCalcData: queryData.priceCalc.byMultiLegInput.list,
      handledError: undefined,
    };
  }
  return {
    formData: formData,
    priceCalcData: [],
    handledError: queryData.priceCalc.byMultiLegInput,
  };
};

export const transformToBookingSearchResultData = (
  queryData: PriceCalculatorResultQuery,
  consignments: FetchConsignmentFragment[]
): BookingSearchResultData => {
  if (queryData.priceCalc.byInput.__typename === "PriceCalc") {
    return {
      consignmentData: consignments,
      priceCalcData: [queryData.priceCalc.byInput],
      handledError: undefined,
    };
  }

  return {
    consignmentData: consignments,
    priceCalcData: [],
    handledError: queryData.priceCalc.byInput,
  };
};
