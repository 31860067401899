import { FreightPricingPortalError } from "@/common/error/models/FreightPricingPortalError";
import {
  SearchFilterSelectorContext,
  SearchFilterSelectorContextType,
} from "@/features/search-filter/context/searchFilterSelectorContext";
import { Space } from "@stenajs-webui/core";
import { PrimaryButton, SecondaryButton } from "@stenajs-webui/elements";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useCurrencySelection } from "../../../../common/redux/profile/hooks/UseCurrencySelection";
import { useDefaultYearSelection } from "../../../../common/redux/profile/hooks/UseDefaultYearSelection";
import { isDefined } from "../../../../common/types/IsDefined";
import { routeTargetsOverviewSelectors } from "../../route-targets-overview/redux/routeTargetsOverviewSelectors";
import { routeCustomerTargetActions } from "../features/route-pair-target-table/redux/actions";
import { customerTargetActions } from "../features/route-pair-target-table/redux/reducers";
import { routeCustomerTargetSelectors } from "../features/route-pair-target-table/redux/selectors";
import {
  transformRoutPairTargetQueryToAllCustomerTargetsTableRow,
  transformRoutePairTargetStateToQueryInput,
} from "../transformers/TargetRoutePairTransformers";
import { LoadingTargetModal } from "./TargetLoadingModal";
import { useUpdateRoutePairCustomerTargetMutation } from "./hooks/UseUpdateRoutePairCustomerTargetMutation";
import { getRoutePairCustomerTargetSuccessResult } from "./utils/UnionHelpers";
import { UpdateRoutePairCustomerTargetItemInput } from "@/gql/graphql";

interface Props {
  onErrors: (saveErrors: FreightPricingPortalError[]) => void;
}

export const RouteTargetSaveButton: React.FC<Props> = ({ onErrors }) => {
  const dispatch = useDispatch();
  const [numberOfEditedTargets, setNumberOfEditedTargets] = useState<number>(0);
  const { updateRoutePairCustomerTargets, loading, error } =
    useUpdateRoutePairCustomerTargetMutation();

  const { committedState } = useContext(
    SearchFilterSelectorContext
  ) as SearchFilterSelectorContextType;

  const { year: defaultYear } = useDefaultYearSelection();

  useEffect(() => {
    if (error) {
      onErrors(error);
    }
  }, [error, onErrors]);

  const currentYear = useSelector(
    routeTargetsOverviewSelectors.getSelectedNegotiationYear
  );
  const { routePairCode } = useParams() as { routePairCode: string };
  const { currency } = useCurrencySelection();

  const handleResetRoutePairCustomerTargets = () => {
    dispatch(customerTargetActions.revertAll());

    dispatch(routeCustomerTargetActions.incrementDiscardClicked());
  };

  const routeCustomerTargetEditedData = useSelector(
    routeCustomerTargetSelectors.getEditedCustomerTargetTableRows
  );
  const routeCustomerTargetPersistedData = useSelector(
    routeCustomerTargetSelectors.getPersistedCustomerTargetTableRows
  );
  const listOrder = useSelector(routeCustomerTargetSelectors.getListOrder);

  const isDataModified = useMemo(() => {
    return listOrder.some((order) => {
      return (
        JSON.stringify(routeCustomerTargetEditedData[order]) !==
        JSON.stringify(routeCustomerTargetPersistedData[order])
      );
    });
  }, [
    listOrder,
    routeCustomerTargetEditedData,
    routeCustomerTargetPersistedData,
  ]);

  const handleUpdateRouteCustomerTargets = async (): Promise<void> => {
    dispatch(
      routeCustomerTargetActions.setRouteCustomerTargetState({
        isSortingDisabled: false,
      })
    );

    const dataForUpdate = listOrder
      .filter((order) => {
        return (
          JSON.stringify(routeCustomerTargetPersistedData[order]) !==
          JSON.stringify(routeCustomerTargetEditedData[order])
        );
      })
      .map<UpdateRoutePairCustomerTargetItemInput | undefined>((idToUpdate) => {
        const tableRowData = routeCustomerTargetEditedData[idToUpdate];
        if (tableRowData) {
          const {
            year,
            routePairCode,
            sailingCatCode,
            target,
            customerNo,
            unitType,
          } = tableRowData;

          if (customerNo !== undefined && unitType !== undefined) {
            return {
              year: year,
              routePairCode: routePairCode,
              sailingCategoryCode: sailingCatCode,
              targetInPercent: target,
              custNo: String(customerNo),
              unitCategoryCode: unitType,
            };
          }
        }
        return undefined;
      })
      .filter(isDefined);

    setNumberOfEditedTargets(dataForUpdate.length);

    const variablesFilter = transformRoutePairTargetStateToQueryInput(
      committedState,
      currency,
      routePairCode,
      currentYear ?? defaultYear
    );

    try {
      const response = await updateRoutePairCustomerTargets(
        { routePairCustomerTargetUpdates: dataForUpdate },
        variablesFilter.filter
      );

      if (response.data) {
        const data =
          response.data?.productPrice.routePairCustomerTarget
            .updateRoutePairCustomerTargets;

        const routeCustomerTargetData =
          getRoutePairCustomerTargetSuccessResult(data);

        if (routeCustomerTargetData) {
          const allCustomerTargetTableRows =
            routeCustomerTargetData.targetRoutePairs.map(
              transformRoutPairTargetQueryToAllCustomerTargetsTableRow
            );

          dispatch(customerTargetActions.setState(allCustomerTargetTableRows));
        }
      }
    } catch (e) {}

    document.documentElement.scrollTop = 0;
  };

  return (
    <>
      <SecondaryButton
        disabled={!isDataModified || loading}
        label={"Discard"}
        onClick={handleResetRoutePairCustomerTargets}
      />
      <Space num={2} />
      <PrimaryButton
        disabled={!isDataModified || loading}
        label={"Save"}
        onClick={handleUpdateRouteCustomerTargets}
        loading={loading}
      />
      {loading && !error && (
        <LoadingTargetModal numberOfEditedTargets={numberOfEditedTargets} />
      )}
    </>
  );
};
