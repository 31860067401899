import { InfoPair } from "@/common/components/info-pair/InfoPair";
import { Box, Row, Space } from "@stenajs-webui/core";
import { Tag } from "@stenajs-webui/elements";
import { Popover } from "@stenajs-webui/tooltip";
import * as React from "react";
import { RebateTableItem } from "../config/RebateTableConfig";

interface Props {
  item: RebateTableItem;
}

export const RebateCustomerListTag: React.FC<Props> = ({ item }) => {
  return (
    <Row indent>
      {item.customerList
        .map((customer) => customer.index)
        .slice(0, 6)
        .join(", ")}
      <Space />
      {item.customerList.length > 6 && (
        <Popover
          placement={"bottom"}
          content={
            <Box minWidth={"200px"}>
              <InfoPair
                indentRow={1}
                leftContent={"Index"}
                leftTextColor={"var(--swui-text-primary-color)"}
                leftTextVariant={"bold"}
                rightContent={"Index no."}
                rightTextColor={"var(--swui-text-primary-color)"}
                rightTextVariant={"bold"}
              />
              {item.customerList.map((customer) => (
                <InfoPair
                  indentRow={1}
                  leftContent={customer.index}
                  leftTextColor={"var(--swui-text-primary-color)"}
                  rightContent={customer.indexNo}
                  rightTextColor={"var(--swui-text-primary-color)"}
                />
              ))}
            </Box>
          }
        >
          <Tag label={`+${item.customerList.length - 6}`} />
        </Popover>
      )}
    </Row>
  );
};
