import { testIdConstants } from "@/common/test-id-constants/testIdConstants";
import { useArraySet } from "@stenajs-webui/core";
import { Tag, stenaSailingLocation } from "@stenajs-webui/elements";
import { FilterCheckbox, FilterCheckboxList } from "@stenajs-webui/filter";
import { CollapsibleWithCheckbox } from "@stenajs-webui/panels";
import { groupBy, reject } from "lodash";
import * as React from "react";
import { useState } from "react";
import { useFetchAllSalesRegionsWithCountries } from "../../hooks/FilterFetchers/UseFetchSalesRegionsWithCountries";
import { useFilterWorkspace } from "../../hooks/UseFilterWorkspace";
import { SalesRegionWithCountries } from "../../types/FilterEntitys";
import { FppSearchFilterSection } from "../FppSearchFilterSection";

const SalesRegionWithCountriesCollapsible = ({
  salesRegion,
  groupedSalesRegion,
  selectedSalesRegions,
  setSalesRegions,
  checkboxClickHandler,
}: {
  salesRegion: string;
  groupedSalesRegion: SalesRegionWithCountries[];
  selectedSalesRegions: SalesRegionWithCountries[];
  setSalesRegions: (salesRegions: SalesRegionWithCountries[]) => void;
  checkboxClickHandler: (_: boolean, id: string) => void;
}) => {
  const [collapsed, setCollapsed] = useState(true);

  const allChecked = groupedSalesRegion.filter((salesRegion) =>
    selectedSalesRegions.find((selected) => salesRegion.id === selected.id)
  );

  const handleGroupChecked = () => {
    if (allChecked.length > 0) {
      setSalesRegions(
        reject(selectedSalesRegions, (salesRegion) =>
          allChecked.find((p) => p.id === salesRegion.id)
        ) as SalesRegionWithCountries[]
      );
    } else {
      setSalesRegions(selectedSalesRegions.concat(groupedSalesRegion));
    }
  };

  return (
    <CollapsibleWithCheckbox
      value={allChecked.length === groupedSalesRegion.length}
      indeterminate={
        allChecked.length > 0 && allChecked.length !== groupedSalesRegion.length
      }
      onChange={handleGroupChecked}
      label={salesRegion}
      collapsed={collapsed}
      onClick={() => setCollapsed(!collapsed)}
      data-testid={
        testIdConstants.salesRegionWithCountriesFilterSectionCollapsible
      }
      contentRight={
        allChecked.length > 0 && (
          <Tag label={String(allChecked.length)} size="small" />
        )
      }
    >
      <FilterCheckboxList maxHeight={"400px"}>
        <div
          data-testid={
            testIdConstants.salesRegionWithCountriesFilterSectionCheckboxList
          }
        >
          {groupedSalesRegion.map((salesRegion) => {
            return (
              <FilterCheckbox
                key={salesRegion.id}
                value={
                  !!selectedSalesRegions.find(
                    (selectedSalesRegion) =>
                      selectedSalesRegion.id === salesRegion.id
                  )
                }
                label={salesRegion.name}
                onValueChange={(v) => checkboxClickHandler(v, salesRegion.id)}
              />
            );
          })}
        </div>
      </FilterCheckboxList>
    </CollapsibleWithCheckbox>
  );
};

interface Props {
  enabled?: boolean;
  label?: string;
}
export const SalesRegionWithCountriesFilterSection: React.FC<Props> = (props) =>
  props.enabled ? (
    <SalesRegionWithCountriesFilterSectionInternal {...props} />
  ) : (
    <></>
  );

const SalesRegionWithCountriesFilterSectionInternal: React.FC<Props> = ({
  enabled,
  label,
}) => {
  const { salesRegionsWithCountries, setSalesRegionsWithCountries } =
    useFilterWorkspace();
  const { items, loading, error, refetch } =
    useFetchAllSalesRegionsWithCountries();

  const grouped = groupBy(items, (item) => item.salesRegion.name);

  const { toggle } = useArraySet(
    salesRegionsWithCountries,
    setSalesRegionsWithCountries,
    (a, b) => a.id === b.id
  );

  const checkboxClickHandler = (_: boolean, id: string) => {
    const entityToToggle = items.find((item) => item.id === id);
    if (entityToToggle) {
      toggle(entityToToggle);
    }
  };

  return (
    <div data-testid={testIdConstants.salesRegionWithCountriesFilterSection}>
      <FppSearchFilterSection
        sectionId={"salesRegionsWithCountries"}
        label={label}
        enabled={enabled}
        numSelected={salesRegionsWithCountries.length}
        leftIcon={stenaSailingLocation}
        error={error?.message}
        loading={loading}
        onRetry={refetch}
        children={Object.keys(grouped).map((seaArea) => {
          return (
            <React.Fragment key={seaArea}>
              <SalesRegionWithCountriesCollapsible
                selectedSalesRegions={salesRegionsWithCountries}
                setSalesRegions={setSalesRegionsWithCountries}
                salesRegion={seaArea}
                checkboxClickHandler={checkboxClickHandler}
                groupedSalesRegion={grouped[seaArea]}
              />
            </React.Fragment>
          );
        })}
      />
    </div>
  );
};
