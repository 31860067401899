import { PageLayout } from "@/common/components/page-layout/PageLayout";
import { RebateAgreementStatusCode } from "@/gql/graphql";
import { Space } from "@stenajs-webui/core";
import {
  Card,
  CardBody,
  CardHeader,
  PrimaryButton,
} from "@stenajs-webui/elements";
import { StandardTable } from "@stenajs-webui/grid";
import * as React from "react";
import { RebatePageHeader } from "./components/RebatePageHeader";
import { RebateTableItem, rebateTableConfig } from "./config/RebateTableConfig";
import { SearchFilterContext } from "@stenajs-webui/filter";
import { FppSearchFilter } from "../search-filter/components/FppSearchFilter";
import { rebateFilterSettings } from "./config/RebateFilterConfig";
import { testIdConstants } from "@/common/test-id-constants/testIdConstants";
import { createFppSearchFilterActions } from "../search-filter/redux/reducer";
import { useDispatch } from "react-redux";
import { useContext } from "react";
import {
  SearchFilterSelectorContext,
  SearchFilterSelectorContextType,
} from "../search-filter/context/searchFilterSelectorContext";

const rebateTableMockItems: Array<RebateTableItem> = [
  {
    id: "1",
    status: RebateAgreementStatusCode.Active,
    name: "Schenker rebate 20 percent",
    paymentReceiver: 119312,
    customerList: [
      {
        index: "EGETEX",
        indexNo: 119210,
      },
      {
        index: "EETTTR",
        indexNo: 119211,
      },
      {
        index: "EGETAX",
        indexNo: 119212,
      },
      {
        index: "EETTAR",
        indexNo: 119213,
      },
      {
        index: "EGETBX",
        indexNo: 119214,
      },
      {
        index: "EETTBR",
        indexNo: 119215,
      },
      {
        index: "EGETCX",
        indexNo: 119216,
      },
      {
        index: "EETTCR",
        indexNo: 119217,
      },
      {
        index: "EGETCX",
        indexNo: 119218,
      },
    ],
    validFrom: "2023-01-01",
    validTo: "2023-12-31",
    rebateAgreementNumber: 3206,
  },
  {
    id: "2",
    status: RebateAgreementStatusCode.Void,
    name: "Schenker SHN test",
    paymentReceiver: 119312,
    customerList: [{ index: "SCHABC", indexNo: 119219 }],
    validFrom: "2023-01-01",
    validTo: "2023-12-31",
    rebateAgreementNumber: 3206,
  },
  {
    id: "3",
    status: RebateAgreementStatusCode.Pending,
    name: "Schenker SHN (Copy 2) test",
    paymentReceiver: 119312,
    customerList: [
      { index: "SHNNNN", indexNo: 119220 },
      { index: "SHNNND", indexNo: 119221 },
    ],
    validFrom: "2023-01-01",
    validTo: "2023-12-31",
    rebateAgreementNumber: 3206,
  },
  {
    id: "4",
    status: RebateAgreementStatusCode.Finalized,
    name: "Schenker IBH 2",
    paymentReceiver: 119312,
    customerList: [
      { index: "EGETEX", indexNo: 119222 },
      { index: "EETTTR", indexNo: 119223 },
    ],
    validFrom: "2023-01-01",
    validTo: "2023-12-31",
    rebateAgreementNumber: 3206,
  },
];

export const RebateView: React.FC = () => {
  const dispatch = useDispatch();

  const { workspaceState } = useContext(
    SearchFilterSelectorContext
  ) as SearchFilterSelectorContextType;

  return (
    <>
      <RebatePageHeader />
      <PageLayout>
        <Card>
          <CardHeader
            text="Rebate agreements"
            contentRight={
              <PrimaryButton label="Create new" onClick={() => ""} />
            }
          />
          <CardBody>
            <SearchFilterContext
              state={workspaceState}
              actions={createFppSearchFilterActions("rebateFilter")}
              dispatch={dispatch}
            >
              <FppSearchFilter
                settings={rebateFilterSettings}
                filterButtonDataTestId={testIdConstants.rebateFilterButton}
              />
            </SearchFilterContext>
          </CardBody>

          <StandardTable
            config={rebateTableConfig()}
            items={rebateTableMockItems}
            noItemsLabel={"No rebate agreements found"}
            loading={false}
            error={undefined}
          />
          <Space />
        </Card>
      </PageLayout>
    </>
  );
};
