import { SpacingValues } from "@/common/utils/SpacingValues";
import { Row, Space, Text } from "@stenajs-webui/core";
import { keys } from "lodash";
import React, { Fragment, useMemo } from "react";
import { RouteAgreement } from "../../../../../customers/customer-details/types";
import { groupRouteAgreementsBySeaArea } from "../util/RouteAgreementGrouper";
import { SeaAreaTable } from "./SeaAreaTable";

interface Props {
  customerId: string;
  routeAgreements: Array<RouteAgreement>;
  headAgreementId: string;
  year: number;
  allExpanded: boolean;
}

export const ExistingAgreementList: React.FC<Props> = ({
  routeAgreements,
  customerId,
  headAgreementId,
  year,
  allExpanded,
}) => {
  const agreementsGroupedBySeaArea = useMemo(() => {
    const routeAgreementsPerSeaArea =
      groupRouteAgreementsBySeaArea(routeAgreements);
    const seaAreaKeys = keys(routeAgreementsPerSeaArea);
    seaAreaKeys.sort();
    return seaAreaKeys.map((k) => routeAgreementsPerSeaArea[k]);
  }, [routeAgreements]);

  if (agreementsGroupedBySeaArea.length === 0) {
    return (
      <Row spacing={SpacingValues.THIRTYTWO} justifyContent={"center"}>
        <Text>There are no route agreements.</Text>
      </Row>
    );
  }

  return (
    <>
      {agreementsGroupedBySeaArea.map((agreementsForSeaArea, index) => {
        const { seaArea } = agreementsForSeaArea[0].routes[0].routePair;
        return (
          <Fragment key={seaArea.id}>
            {index !== 0 && <Space num={3} />}
            <SeaAreaTable
              agreementsForSeaArea={agreementsForSeaArea}
              customerId={customerId}
              headAgreementId={headAgreementId}
              year={year}
              allExpanded={allExpanded}
            />
          </Fragment>
        );
      })}
    </>
  );
};
