import { createAction, createReducer, createSlice } from "@reduxjs/toolkit";
import {
  SearchFilterState as WebUiSearchFilterState,
  createSearchFilterActions,
  createSearchFilterInitialState,
  createSearchFilterReducer,
} from "@stenajs-webui/filter";
import {
  Customer,
  CustomerCategory,
  CustomerType,
  EmptyLoaded,
  IncludeCustomersWith,
  NegotiationStatus,
  NoShowLateHandlingStatus,
  NoShowLateHandlingType,
  NoteChoice,
  RebateStatus,
  RoutePair,
  SailingCategory,
  SalesRegion,
  SalesRegionWithCountries,
  Seller,
  TargetStatus,
  Unacc,
  UnitType,
  VehicleType,
} from "../types/FilterEntitys";
import { RangeFilter } from "../types/RangeFilter";
import { DateRange } from "@stenajs-webui/calendar";

export interface WorkspaceState {
  negotiationYear: number;
  year: number;
  month: number;
  negotiationStatus: Array<NegotiationStatus>;
  sellers: Array<Seller>;
  customerTypes: Array<CustomerType>;
  includeCustomersWith: Array<IncludeCustomersWith>;
  salesRegions: Array<SalesRegion>;
  salesRegionsWithCountries: Array<SalesRegionWithCountries>;
  routePairs: Array<RoutePair>;
  customers: Array<Customer>;
  parentCustomer: Customer | undefined;
  sailingCategories: Array<SailingCategory>;
  routeAgreementSailingCategories: Array<SailingCategory>;
  unitTypes: Array<UnitType>;
  averageNetPrice: RangeFilter;
  averageGrossPrice: RangeFilter;
  target: RangeFilter;
  rate: RangeFilter;
  twelveMonthVolume: RangeFilter;
  noShowLateHandlingType: Array<NoShowLateHandlingType>;
  noShowLateHandlingStatus: Array<NoShowLateHandlingStatus>;
  countries: Array<string>;
  networkVolume: RangeFilter;
  routeVolume: RangeFilter;
  customerCategories: Array<CustomerCategory>;
  regions: Array<SalesRegion>;
  statNumbersAndIndexes: Array<Customer>;
  statNetworkVolume: RangeFilter;
  statRouteVolume: RangeFilter;
  targetStatuses: Array<TargetStatus>;
  vehicleTypeAll: boolean;
  vehicleTypes: Array<VehicleType>;
  vehicleLength: RangeFilter;
  vehicleWeight: RangeFilter;
  emptyLoaded: Array<EmptyLoaded>;
  unacc: Array<Unacc>;
  bookAndCheckInNotes: Array<NoteChoice>;
  bookingNo?: string;
  startTime: string | undefined;
  endTime: string | undefined;
  startDate: string | undefined;
  endDate: string | undefined;
  dateRange: DateRange;
  agreementName: string;
  agreementNumber: string;
  rebateStatuses: Array<RebateStatus>;
}

export type SearchFilterSectionKey =
  | "negotiationYear"
  | "negotiationStatus"
  | "sellers"
  | "customerTypes"
  | "includeCustomersWith"
  | "salesRegions"
  | "salesRegionsWithCountries"
  | "routePairs"
  | "customers"
  | "parentCustomer"
  | "sailingCategories"
  | "routeAgreementSailingCategories"
  | "unitTypes"
  | "averageNetPrice"
  | "averageGrossPrice"
  | "target"
  | "rate"
  | "twelveMonthVolume"
  | "countries"
  | "networkVolume"
  | "routeVolume"
  | "customerCategories"
  | "regions"
  | "statNumbersAndIndexes"
  | "statNetworkVolume"
  | "statRouteVolume"
  | "targetStatuses"
  | "vehicleTypes"
  | "vehicleLength"
  | "vehicleWeight"
  | "emptyLoaded"
  | "unacc"
  | "noShowLateHandlingType"
  | "noShowLateHandlingStatus"
  | "bookingNo"
  | "startTime"
  | "endTime"
  | "startDate"
  | "endDate"
  | "yearMonth"
  | "bcNote"
  | "departureInput"
  | "dateRange"
  | "agreementName"
  | "agreementNumber"
  | "rebateStatuses";

export interface SearchFilterState {
  workspace: WebUiSearchFilterState<WorkspaceState>;
  committed: WorkspaceState;
}

export const initialSearchFilterFormModel: WorkspaceState = {
  negotiationYear: new Date().getUTCFullYear(),
  year: new Date().getUTCFullYear(),
  month: new Date().getUTCMonth() + 1,
  negotiationStatus: [],
  sellers: [],
  customerTypes: [],
  includeCustomersWith: [],
  salesRegions: [],
  salesRegionsWithCountries: [],
  routePairs: [],
  customers: [],
  parentCustomer: undefined,
  sailingCategories: [],
  routeAgreementSailingCategories: [],
  unitTypes: [],
  averageNetPrice: {},
  averageGrossPrice: {},
  rate: {},
  target: {},
  twelveMonthVolume: {},
  noShowLateHandlingType: [],
  noShowLateHandlingStatus: [],
  countries: [],
  networkVolume: {},
  routeVolume: {},
  customerCategories: [],
  regions: [],
  statNumbersAndIndexes: [],
  statNetworkVolume: {},
  statRouteVolume: {},
  targetStatuses: [],
  vehicleTypes: [],
  vehicleTypeAll: false,
  vehicleLength: {},
  vehicleWeight: {},
  emptyLoaded: [],
  unacc: [],
  bookingNo: "",
  bookAndCheckInNotes: [],
  startTime: "",
  endTime: "",
  endDate: undefined,
  startDate: undefined,
  dateRange: {},
  agreementName: "",
  agreementNumber: "",
  rebateStatuses: [],
};

export const searchFilterInitialState: SearchFilterState = {
  workspace: createSearchFilterInitialState(initialSearchFilterFormModel),
  committed: initialSearchFilterFormModel,
};

export const filterCommittedReducerId = "filterCommittedReducerId";

const filterCommittedSlice = createSlice({
  name: filterCommittedReducerId,
  initialState: searchFilterInitialState.committed,
  reducers: {
    clearAll() {
      return initialSearchFilterFormModel;
    },
  },
});

export const {
  reducer: filterCommittedReducer,
  actions: filterCommittedActions,
} = filterCommittedSlice;

export const commitSearchFilter = createAction("commitSearchFilter");

export const createFppSearchFilterReducer = (reducerId: string) =>
  createReducer<SearchFilterState>(
    {
      workspace: searchFilterInitialState.workspace,
      committed: initialSearchFilterFormModel,
    },
    (build) => {
      const searchFilterReducer = createSearchFilterReducer<WorkspaceState>(
        reducerId,
        searchFilterInitialState.workspace
      );

      build
        .addCase(commitSearchFilter, (state) => {
          state.committed = state.workspace.formModel;
        })
        .addDefaultCase((state, action) => {
          state.workspace = searchFilterReducer(state.workspace, action as any);
          state.committed = filterCommittedReducer(state.committed, action);
        });
    }
  );

export const createFppSearchFilterActions = (reducerId: string) =>
  createSearchFilterActions<WorkspaceState, SearchFilterSectionKey>(
    reducerId,
    searchFilterInitialState.workspace.formModel
  );
