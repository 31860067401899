import { routeFactory } from "@/RouteFactory";
import { HeadAgreementListSwitcher } from "@/features/product-and-price/head-agreement/list/components/HeadAgreementListSwitcher";
import { useNavigate, useParams } from "react-router-dom";
import { useHeadAgreementsByCustomer } from "@/features/product-and-price/head-agreement/list/hooks/UseHeadAgreementsSwitcher";
import { Window } from "@stenajs-webui/modal";
import { Tab, TabMenu } from "@stenajs-webui/elements";
import { useState } from "react";
import { SeparatorLine } from "@stenajs-webui/core";

interface HeaderAgreementPickerModalProps {
  isOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  reportType: string;
  parentCustomerId: string;
}

enum SelectedTab {
  "ownAgreements",
  "parentAgreements",
}

export const HeaderAgreementPickerModal = ({
  isOpen,
  setIsModalOpen,
  reportType,
  parentCustomerId,
}: HeaderAgreementPickerModalProps) => {
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState<SelectedTab>(
    SelectedTab.ownAgreements
  );
  const {
    headAgreements: parentHeadAgreements,
    error: parentError,
    loading: parentLoading,
  } = useHeadAgreementsByCustomer(parentCustomerId);
  const { customerId } = useParams() as { customerId: string };
  const {
    headAgreements: ownHeadAgreements,
    error: ownError,
    loading: ownLoading,
  } = useHeadAgreementsByCustomer(customerId);

  return (
    <Window
      headerText={`Select ${
        selectedTab === SelectedTab.ownAgreements ? "own" : "parent"
      } header agreement`}
      isOpen={isOpen}
      width={"800px"}
      onRequestClose={() => setIsModalOpen(false)}
      indent={0}
      spacing={0}
    >
      <TabMenu>
        <Tab
          label={"Own agreements"}
          onClick={() => setSelectedTab(SelectedTab.ownAgreements)}
          selected={selectedTab === SelectedTab.ownAgreements}
        />
        <Tab
          label={"Parent agreements"}
          onClick={() => setSelectedTab(SelectedTab.parentAgreements)}
          selected={selectedTab === SelectedTab.parentAgreements}
        />
      </TabMenu>
      <SeparatorLine />
      {selectedTab === SelectedTab.ownAgreements ? (
        <HeadAgreementListSwitcher
          headAgreements={ownHeadAgreements || []}
          error={ownError}
          loading={ownLoading}
          onSelectHeadAgreement={(headAgreementId: string) =>
            navigate(
              routeFactory.productAndPrice.headAgreement.createReport({
                customerId,
                headAgreementId: headAgreementId,
                reportType: reportType,
              })
            )
          }
        />
      ) : (
        <HeadAgreementListSwitcher
          headAgreements={parentHeadAgreements || []}
          error={parentError}
          loading={parentLoading}
          onSelectHeadAgreement={(headAgreementId: string) =>
            navigate(
              routeFactory.productAndPrice.headAgreement.createReport({
                customerId,
                headAgreementId,
                reportType,
                parent: parentCustomerId,
              })
            )
          }
        />
      )}
    </Window>
  );
};
