import { testIdConstants } from "@/common/test-id-constants/testIdConstants";
import { HeaderAgreementPickerModal } from "@/features/product-and-price/customers/common/head-agreement-picker/HeadAgreementPickerModal";
import {
  CustomerHeaderCustomersByIdFragment,
  HeadAgreementInfoRowSectionFragment,
  HeadAgreementStatusCode,
} from "@/gql/graphql";
import { routeFactory } from "@/RouteFactory";
import { ActionMenuItem, stenaFilePdfLabel } from "@stenajs-webui/elements";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

interface Props {
  customer: CustomerHeaderCustomersByIdFragment | null | undefined;
  headAgreement: HeadAgreementInfoRowSectionFragment;
  onRequestClose: () => void;
}

export const CreateReportMenuItems = ({
  customer,
  headAgreement,
  onRequestClose,
}: Props) => {
  const navigate = useNavigate();
  const customerId = customer?.id ?? "";
  const parentCustomerId = customer?.parent?.id ?? "";
  const [reportType, setReportType] = useState("");
  const [isHeaderAgreementPickerOpen, setIsHeaderAgreementPickerOpen] =
    useState(false);

  return (
    <>
      {!customer?.parent ? (
        <>
          <ActionMenuItem
            label={"Create offer"}
            leftIcon={stenaFilePdfLabel}
            disabled={headAgreement.statusCode === HeadAgreementStatusCode.Void}
            onClick={() =>
              navigate(
                routeFactory.productAndPrice.headAgreement.createReport({
                  customerId,
                  headAgreementId: headAgreement.id,
                  reportType: "offer",
                })
              )
            }
            data-testid={testIdConstants.headerAgreementActionsMenuCreateOffer}
          />
          <ActionMenuItem
            label={"Create confirmation"}
            leftIcon={stenaFilePdfLabel}
            disabled={headAgreement.statusCode === HeadAgreementStatusCode.Void}
            onClick={() =>
              navigate(
                routeFactory.productAndPrice.headAgreement.createReport({
                  customerId,
                  headAgreementId: headAgreement.id,
                  reportType: "confirmation",
                })
              )
            }
            data-testid={
              testIdConstants.headerAgreementActionsMenuCreateConfirmation
            }
          />
        </>
      ) : (
        <>
          <ActionMenuItem
            label={"Create offer"}
            leftIcon={stenaFilePdfLabel}
            onClick={() => {
              setReportType("offer");
              setIsHeaderAgreementPickerOpen(true);
              onRequestClose();
            }}
            data-testid={testIdConstants.headerAgreementActionsMenuCreateOffer}
          />
          <ActionMenuItem
            label={"Create confirmation"}
            leftIcon={stenaFilePdfLabel}
            onClick={() => {
              setReportType("confirmation");
              setIsHeaderAgreementPickerOpen(true);
              onRequestClose();
            }}
            data-testid={
              testIdConstants.headerAgreementActionsMenuCreateConfirmation
            }
          />
        </>
      )}
      <HeaderAgreementPickerModal
        isOpen={isHeaderAgreementPickerOpen}
        setIsModalOpen={setIsHeaderAgreementPickerOpen}
        parentCustomerId={parentCustomerId}
        reportType={reportType}
      />
    </>
  );
};
