import * as React from "react";
import { Indent, Row, Text } from "@stenajs-webui/core";
import {
  Icon,
  SecondaryButton,
  stenaExclamationTriangle,
} from "@stenajs-webui/elements";
import { ValidationErrorMessage } from "../../../../../common/components/validation-error-message/ValidationErrorMessage";
import { StoreState } from "../../../../../config/redux/RootReducer";
import { useSelector } from "react-redux";
import { cssColor } from "@stenajs-webui/theme";
import { testIdConstants } from "@/common/test-id-constants/testIdConstants";

interface Props {
  onClickRecalculate: () => void;
}

const border = `1px solid ${cssColor("--lhds-color-blue-300")}`;

const selector = (state: StoreState) =>
  state.routeAgreementDetails.recalculationProgress;

export const RecalculationBoxPanel: React.FC<Props> = ({
  onClickRecalculate,
}) => {
  const { loading, error } = useSelector(selector);

  return (
    <>
      <Row
        justifyContent={"space-between"}
        alignItems={"center"}
        indent={2}
        spacing={2}
        border={border}
        background={cssColor("--lhds-color-blue-100")}
      >
        <Row alignItems={"center"}>
          <Icon
            icon={stenaExclamationTriangle}
            color={cssColor("--lhds-color-blue-600")}
          />
          <Indent />
          <Text>
            Statistics for volume and last years prices needs to be
            recalculated.
          </Text>
        </Row>
        <Row alignItems={"center"}>
          {error && <ValidationErrorMessage error={error} />}
          <Indent />
          <SecondaryButton
            label={"Recalculate"}
            onClick={onClickRecalculate}
            loading={loading}
            data-testid={testIdConstants.createRouteRatesRecalculateStatistics}
          />
        </Row>
      </Row>
    </>
  );
};
