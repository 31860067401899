import {
  SearchFilterState,
  createFppSearchFilterReducer,
} from "@/features/search-filter/redux/reducer";
import { combineReducers } from "redux";

export interface RebateFilterState {
  filter: SearchFilterState;
}

export const rebateFilterReducer = combineReducers<RebateFilterState>({
  filter: createFppSearchFilterReducer("rebateFilter"),
});
