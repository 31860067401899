import { routeFactory } from "@/RouteFactory";
import { Crumb } from "@/common/components/bread-crumbs/Crumb";
import { PageHeader } from "@/common/components/page-header/PageHeader";
import { ZIndex } from "@/common/utils/ZIndexEnum";
import { BreadCrumbs, stenaFinanceRebate } from "@stenajs-webui/elements";
import * as React from "react";

interface Props {}

export const RebatePageHeader: React.FC<Props> = () => {
  // FIXME: Fix error handling later
  //   const bannerState: ResultListBannerState | undefined = {
  //     headerText: "Error",
  //     items: [{ text: "error" }],
  //   };

  return (
    <PageHeader
      title={"Rebate"}
      loading={true}
      icon={stenaFinanceRebate}
      zIndex={ZIndex.low}
      // FIXME: Fix error handling later
      //   contentAbove={
      //     bannerState ? (
      //       <ResultListBanner bannerState={bannerState} variant={"error"} />
      //     ) : undefined
      //   }
      breadcrumbs={
        <BreadCrumbs>
          <Crumb label={"Rebate"} path={routeFactory.rebates.rebatesRoot()} />
          <Crumb
            label={"Rebate agreements"}
            path={routeFactory.rebates.rebatesRoot()}
            isLast
          />
        </BreadCrumbs>
      }
    />
  );
};
