import { routeFactory } from "@/RouteFactory";
import { Crumb } from "@/common/components/bread-crumbs/Crumb";
import { PageHeader } from "@/common/components/page-header/PageHeader";
import { ZIndex } from "@/common/utils/ZIndexEnum";
import {
  BreadCrumbs,
  ResultListBanner,
  stenaDetails,
} from "@stenajs-webui/elements";
import * as React from "react";
import { ApiErrorResult } from "./SurchargeDocumentsUploadModal";

interface Props {
  validationError: ApiErrorResult | undefined;
}

export const SurchargeDocumentsPageHeader: React.FC<Props> = ({
  validationError,
}) => {
  return (
    <PageHeader
      title={"Surcharge documents"}
      loading={true}
      icon={stenaDetails}
      zIndex={ZIndex.low}
      contentUnder={
        validationError && (
          <ResultListBanner
            bannerState={{
              headerText: "Something went wrong",
              items: [
                { text: validationError?.Extensions?.error?.Message ?? "" },
              ],
            }}
            variant={"error"}
          />
        )
      }
      breadcrumbs={
        <BreadCrumbs>
          <Crumb
            label={"admin"}
            path={routeFactory.admin.surchargeDocuments()}
          />
          <Crumb
            label={"Surcharge documents"}
            path={routeFactory.admin.surchargeDocuments()}
            isLast
          />
        </BreadCrumbs>
      }
    />
  );
};
