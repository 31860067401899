import { NumericTextInput } from "@/common/components/numeric-text-input/NumericTextInput";
import { dotToCommaTransform } from "@/common/formatters/NumericTextFieldInputCleaner";
import { Indent } from "@stenajs-webui/core";
import {
  StandardTableCellRenderer,
  UseGridCellResult,
} from "@stenajs-webui/grid";
import { ModifiedFieldItemState } from "@stenajs-webui/redux";
import * as React from "react";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { convertEditorValueToValidInput } from "@/common/numbers";
import { StoreState } from "../../../../../config/redux/RootReducer";
import { AllCustomerTargetsTableRow } from "./config/RowsAndColumnsConfig";
import { routeCustomerTargetActions } from "./redux/actions";
import { customerTargetActions } from "./redux/reducers";
import { RoutePairCustomerTargetEditableTableCell } from "./RoutePairCustomerTargetEditableTableCell";

type AcceptedTypes = string | undefined | number | null;

export const createRoutePairCustomerTargetTargetTableCell =
  (): StandardTableCellRenderer<AcceptedTypes, AllCustomerTargetsTableRow> =>
  ({ item, gridCell, isEditable, isSelected, zIndex, value, label }) => {
    const id = item.id;
    return (
      <RoutePairCustomerTargetTableCell
        entityId={id}
        gridCell={gridCell}
        isEditable={isEditable}
      />
    );
  };

interface AllRoutesStatisticsTableCellProps {
  entityId: string;
  isEditable?: boolean;
  gridCell: UseGridCellResult<string>;
}

export const RoutePairCustomerTargetTableCell: React.FC<
  AllRoutesStatisticsTableCellProps
> = ({ entityId, isEditable, gridCell }) => {
  const dispatch = useDispatch();

  const originalValueRow = useSelector((state: StoreState) => {
    return state.customerTargetState.persisted[entityId];
  });
  const editableValueRow = useSelector((state: StoreState) => {
    return state.customerTargetState.edited[entityId];
  });

  const modifiedField: ModifiedFieldItemState =
    editableValueRow?.target === originalValueRow?.target
      ? { id: "" }
      : {
          id: "",
          modified: true,
          newValue: String(editableValueRow?.target ?? ""),
          originalValue: String(originalValueRow?.target) ?? "",
        };

  const onDone = useCallback(() => {
    gridCell.editorValue &&
      dispatch(
        customerTargetActions.setValue({
          id: entityId,
          target: convertEditorValueToValidInput(gridCell.editorValue),
        })
      );
    gridCell.stopEditingAndMove("down");
  }, [dispatch, entityId, gridCell]);

  const textFieldValue =
    editableValueRow?.target || editableValueRow?.target === 0
      ? convertValueForTableCell(Number(editableValueRow?.target))
      : "-";

  useEffect(() => {
    if (isEditable && gridCell.isEditing) {
      dispatch(
        routeCustomerTargetActions.setRouteCustomerTargetState({
          isSortingDisabled: true,
        })
      );
    }

    if (!gridCell.isEditing) {
      dispatch(
        routeCustomerTargetActions.setRouteCustomerTargetState({
          isSortingDisabled: false,
        })
      );
    }
  }, [dispatch, isEditable, gridCell.isEditing]);

  return isEditable && gridCell.isEditing ? (
    <Indent row alignItems={"center"}>
      <NumericTextInput
        onValueChange={gridCell.setEditorValue}
        value={gridCell.editorValue}
        onDone={onDone}
        onEsc={gridCell.stopEditingAndRevert}
        autoFocus
        useNegativeValues
        maxDecimals={2}
        maxNumbers={3}
        selectAllOnMount={!gridCell.lastKeyEvent}
      />
    </Indent>
  ) : (
    <Indent row alignItems={"center"}>
      <RoutePairCustomerTargetEditableTableCell
        value={textFieldValue}
        modifiedField={modifiedField}
        isEditable={isEditable}
      />
    </Indent>
  );
};

const convertValueForTableCell = (value: number): string => {
  if (value === 0) return `${dotToCommaTransform(value.toFixed(2))}`;
  if (value > 0) return `+${dotToCommaTransform(value.toFixed(2))}`;

  return `${dotToCommaTransform(value.toFixed(2))}`;
};
