import { SpaceValues } from "@/common/utils/SpaceValues";
import { Column, Row, Space } from "@stenajs-webui/core";
import {
  Banner,
  Card,
  CardBody,
  FlatButton,
  PrimaryButton,
} from "@stenajs-webui/elements";
import { CheckboxWithLabel } from "@stenajs-webui/forms";
import * as React from "react";
import { FormModelChangeHandler } from "../../../common/forms/types";
import { PriceCalculatorFormData } from "../PriceCalculatorFormFetcher";
import { isCalculationDisabled } from "./utils/PriceCalculatorActionBarHelper";
import { testIdConstants } from "@/common/test-id-constants/testIdConstants";

interface Props {
  formData: PriceCalculatorFormData;
  onChangeFormData: FormModelChangeHandler<PriceCalculatorFormData>;
  onCalculatePrice: () => void;
  loading: boolean;
  error?: Error;
  onClearError: () => void;
}

export const PriceCalculatorActionBar: React.FC<Props> = ({
  formData,
  onChangeFormData,
  onCalculatePrice,
  loading,
  error,
  onClearError,
}) => {
  return (
    <Card>
      <CardBody>
        {error && (
          <Column maxHeight={"200px"} overflowY={"auto"}>
            <Banner
              variant={"error"}
              headerText={"Could not calculate price"}
              text={error?.message}
              contentRight={
                <FlatButton
                  label={"Clear error"}
                  onClick={() => onClearError()}
                />
              }
            />
            <Space num={SpaceValues.SIXTEEN} />
          </Column>
        )}

        <Row alignItems={"center"}>
          <CheckboxWithLabel
            label={"Include pending/offered agreements"}
            value={formData.includePendingOffered}
            onValueChange={(value) =>
              onChangeFormData("includePendingOffered", value)
            }
          />
          <Space num={SpaceValues.SIXTEEN} />
          <PrimaryButton
            label={"Calculate price"}
            loading={loading}
            loadingLabel={"Calculating price"}
            onClick={() => onCalculatePrice()}
            disabled={isCalculationDisabled(formData)}
            data-testid={testIdConstants.priceCalculatorCalculatePriceButton}
          />
        </Row>
      </CardBody>
    </Card>
  );
};
