import { formatPercentWithSign, formatThousands } from "@/common/numbers";
import { RouteAgreementFieldsFragment } from "@/gql/graphql";
import { Box, Indent, Text } from "@stenajs-webui/core";
import {
  createColumnConfig,
  createStandardTableInitialState,
  StandardTable,
  StandardTableConfig,
  useLocalStateTableContext,
} from "@stenajs-webui/grid";
import { cssColor } from "@stenajs-webui/theme";
import { uniq } from "lodash";
import React, { useEffect } from "react";
import { Link } from "../../../../../../../common/navigation/Link";
import { routeFactory } from "../../../../../../../RouteFactory";
import { RoutePairTable } from "./RoutePairTable";
import { transformAgreementsToTableColumns } from "./transformers/transformers";

export interface ExistingAgreementTableColumns {
  routePairCode: string;
  customerId: string;
  headAgreementId: string;
  year: number;
  numRouteAgreements: number;
  numSalesReady: number;
  pending: number;
  offered: number;
  active: number;
  averageTargetRate: number | null;
  averageNegotiationRate: number;
  volumeLast12Months: number;
  expectedVolume: number;
}

export type ExistingAgreementTableColumnKeys =
  | keyof Omit<
      ExistingAgreementTableColumns,
      | "headAgreementId"
      | "routePairCode"
      | "customerId"
      | "year"
      | "numRouteAgreements"
      | "pending"
      | "offered"
      | "active"
    >
  | "route"
  | "statuses";

const config: StandardTableConfig<
  ExistingAgreementTableColumns,
  ExistingAgreementTableColumnKeys
> = {
  keyResolver: (item) => item.routePairCode,
  enableExpandCollapse: true,
  showHeaderExpandCollapse: true,
  disableSorting: true,
  initialSortOrder: "volumeLast12Months",
  initialSortOrderDesc: true,
  rowBackgroundResolver: (item) =>
    item.numSalesReady === item.numRouteAgreements
      ? cssColor("--lhds-color-green-50")
      : undefined,
  columns: {
    route: createColumnConfig((item) => item, {
      renderCell: ({ label, item }) => (
        <Indent>
          <Link
            to={routeFactory.productAndPrice.customer.customerRoutePair({
              customerId: item.customerId,
              headAgreementId: item.headAgreementId,
              routePairId: item.routePairCode,
            })}
          >
            {item.routePairCode}
          </Link>
        </Indent>
      ),
      columnLabel: "Route",
    }),
    numSalesReady: createColumnConfig((item) => item.numSalesReady, {
      columnLabel: "Sales ready",
      renderCell: ({ label, value, item }) => (
        <Indent>
          <Text>
            {value}/{item.numRouteAgreements}
          </Text>
        </Indent>
      ),
    }),
    statuses: createColumnConfig(
      (item) => `${item.pending} / ${item.offered} / ${item.active}`,
      {
        columnLabel: "Pending / Offered / Active",
      }
    ),
    volumeLast12Months: createColumnConfig((item) => item.volumeLast12Months, {
      columnLabel: "Vol. last 12 m",
      itemLabelFormatter: (value) => formatThousands(value),
    }),
    expectedVolume: createColumnConfig((item) => item.expectedVolume, {
      columnLabel: "Exp. vol",
      itemLabelFormatter: (value) => formatThousands(value),
    }),
    averageTargetRate: createColumnConfig(
      (item) => item.averageTargetRate ?? 0,
      {
        columnLabel: "Avg. target rate %",
        itemLabelFormatter: (value, item) => {
          return item.averageTargetRate === null
            ? ""
            : value !== null
            ? formatPercentWithSign(value, 2)
            : "";
        },
      }
    ),
    averageNegotiationRate: createColumnConfig(
      (item) => item.averageNegotiationRate,
      {
        columnLabel: "Avg. neg rate %",
        itemLabelFormatter: (value) => formatPercentWithSign(value),
      }
    ),
  },

  renderRowExpansion: (item) => (
    <Box background={cssColor("--lhds-color-ui-50")}>
      <RoutePairTable
        headAgreementId={item.headAgreementId}
        routePairCode={item.routePairCode}
        year={item.year}
        customerId={item.customerId}
      />
    </Box>
  ),
  columnOrder: [
    "route",
    "numSalesReady",
    "statuses",
    "volumeLast12Months",
    "expectedVolume",
    "averageTargetRate",
    "averageNegotiationRate",
  ],
};

interface Props {
  customerId: string;
  agreementsForSeaArea: RouteAgreementFieldsFragment[];
  headAgreementId: string;
  year: number;
  allExpanded: boolean;
}

export const SeaAreaTable: React.FC<Props> = ({
  customerId,
  headAgreementId,
  year,
  agreementsForSeaArea,
  allExpanded,
}) => {
  const seaArea = agreementsForSeaArea[0].routes[0].routePair.seaArea;
  const { tableContext } = useLocalStateTableContext(
    `route-agreement-tables-${seaArea.id}`,
    createStandardTableInitialState<ExistingAgreementTableColumnKeys>(undefined)
  );

  const getIdsBySeaArea = () => {
    return agreementsForSeaArea.flatMap((b) => {
      return b.routes
        .filter((a) => a.routePair.seaArea.id === seaArea.id)
        .map((c) => c.routePair.id);
    });
  };

  useEffect(() => {
    allExpanded
      ? tableContext.dispatch(
          tableContext.actions.expandByIds(uniq(getIdsBySeaArea()))
        )
      : tableContext.dispatch(tableContext.actions.collapseAll());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allExpanded]);

  return (
    <Box>
      <Text
        variant={"bold"}
        size={"small"}
        color={cssColor("--lhds-color-ui-600")}
      >
        {seaArea.name.toUpperCase()}
      </Text>
      <Box overflowX={"auto"}>
        <Box display={"table"}>
          <StandardTable
            config={config}
            items={transformAgreementsToTableColumns(
              agreementsForSeaArea,
              customerId,
              headAgreementId,
              year
            )}
            tableContext={tableContext}
          />
        </Box>
      </Box>
    </Box>
  );
};
