import * as React from "react";
import { useMemo } from "react";
import { Select } from "@stenajs-webui/select";
import { RaiseByType } from "../../../../../features/product-and-price/rate-sheet/bulk-update-rates/models/BulkUpdateRatesFormModel";
import { testIdConstants } from "@/common/test-id-constants/testIdConstants";

interface Props {
  value?: RaiseByType;
  onValueChange?: (value: RaiseByType) => void;
  currencyLabel?: string;
}

export interface RaiseByTypeOption {
  label: string;
  value: RaiseByType;
}

export const RaiseByTypeSelect: React.FC<Props> = ({
  onValueChange,
  value,
  currencyLabel,
}) => {
  const options: Array<RaiseByTypeOption> = useMemo(
    () => [
      { label: "Percent (%)", value: RaiseByType.PERCENT },
      {
        label: `Absolute${currencyLabel ? ` (${currencyLabel})` : ""}`,
        value: RaiseByType.ABSOLUTE,
      },
    ],
    [currencyLabel]
  );

  const selected = useMemo(() => {
    return options.find((o) => o.value === value);
  }, [value, options]);

  return (
    <div data-testid={testIdConstants.raiseByTypeSelect}>
      <Select
        value={selected}
        onChange={(v: any) => onValueChange?.(v.value)}
        options={options}
      />
    </div>
  );
};
