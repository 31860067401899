import { testIdConstants } from "@/common/test-id-constants/testIdConstants";
import { Indent, Row, Spacing, Txt } from "@stenajs-webui/core";
import { RadioButtonWithLabel } from "@stenajs-webui/forms";
import * as React from "react";

interface Props {
  isChecked: boolean;
  setChecked: (checked: boolean) => void;
  setUnchecked: (checked: boolean) => void;
}

export const NoShowLateHandlingIncludePricesRadioButtons: React.FC<Props> = ({
  isChecked,
  setChecked,
  setUnchecked,
}) => {
  return (
    <Row display={"flex"} flexDirection={"column"}>
      <Txt size={"small"}>Include prices from latest version</Txt>
      <Spacing num={0.5} />
      <Row>
        <RadioButtonWithLabel
          label={"YES"}
          checked={isChecked}
          data-testid={testIdConstants.routePriceIncludePriceRadioButton}
          onClick={() => setChecked(true)}
        />
        <Indent num={2} />
        <RadioButtonWithLabel
          label={"NO"}
          checked={isChecked === false}
          data-testid={testIdConstants.routePriceDoNotIncludePriceRadioButton}
          onClick={() => setUnchecked(false)}
        />
      </Row>
    </Row>
  );
};
