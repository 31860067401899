import { SalesRegionWithCountriesFilterQuery } from "@/gql/graphql";
import { useQuery } from "@apollo/client";
import { gql } from "apollo-boost";
import { SalesRegionWithCountries } from "../../types/FilterEntitys";

export const salesRegionWithCountriesFilterQuery = gql`
  query SalesRegionWithCountriesFilter {
    productPrice {
      country {
        all {
          code
          id
          name
          salesRegion {
            id
            code
            name
            shortName
          }
        }
      }
    }
  }
`;

export const useFetchAllSalesRegionsWithCountries = () => {
  const { data, loading, error, refetch } =
    useQuery<SalesRegionWithCountriesFilterQuery>(
      salesRegionWithCountriesFilterQuery,
      { fetchPolicy: "cache-first" }
    );
  const items =
    data?.productPrice.country.all.map<SalesRegionWithCountries>((r) => ({
      id: r.id,
      code: r.code,
      name: r.name,
      salesRegion: r.salesRegion,
    })) ?? [];
  return { items, loading, error, refetch };
};
