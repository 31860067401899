import { routeFactory } from "@/RouteFactory";
import {
  NOSHOW_LATE_EDIT,
  OPEN_AGREEMENT,
  OPEN_INVOICING,
  TARGET_ADMIN_CLAIM,
  TARGET_EDIT_CLAIM,
  UPDATE_ROUTE_PRICES,
} from "@/common/claims/constants";
import { hasClaim } from "@/common/claims/utils";
import { testIdConstants } from "@/common/test-id-constants/testIdConstants";
import { Column } from "@stenajs-webui/core";
import {
  stenaBusinessAgreement,
  stenaBusinessNslh,
  stenaCalculate,
  stenaDetails,
  stenaSailingRoute,
  stenaStatisticsBar,
  stenaUserSingle,
} from "@stenajs-webui/elements";
import { Drawer } from "@stenajs-webui/modal";
import {
  SidebarMenuCollapsible,
  SidebarMenuHeading,
  SidebarMenuLink,
  SidebarMenuSeparator,
  SidebarMenu as StenaSidebarMenu,
} from "@stenajs-webui/panels";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { UseTabMatches } from "./custom-hooks/UseTabMatches";
import { NslhInvoiceTab } from "../no-show-late-handling/config/tabConfig";

interface Props {
  isOpen: boolean;
  claims: string[];
  close: () => void;
}

export const SidebarMenu: React.FC<Props> = ({ isOpen, claims, close }) => {
  const navigate = useNavigate();
  const { tabMatches } = UseTabMatches();

  const sidebarMenuLinkOnClick = (url: string) => {
    navigate(url);
    close();
  };

  const onKeyDown = (
    event: React.KeyboardEvent<HTMLAnchorElement>,
    url: string
  ) => {
    return event.code === "Space" || event.code === "Enter"
      ? sidebarMenuLinkOnClick(url)
      : undefined;
  };

  return (
    <Drawer isOpen={isOpen} onRequestClose={close} width={"300px"}>
      <Column>
        <StenaSidebarMenu onCloseClick={close}>
          <SidebarMenuHeading label={"Freight pricing portal"} />
          {hasClaim(claims, [TARGET_EDIT_CLAIM]) && (
            <SidebarMenuLink
              onClick={() =>
                sidebarMenuLinkOnClick(
                  routeFactory.targetingTool.routeTargets.allRoutes()
                )
              }
              onKeyDown={(e) =>
                onKeyDown(
                  e,
                  routeFactory.targetingTool.routeTargets.allRoutes()
                )
              }
              leftIcon={stenaSailingRoute}
              label={"Route targets"}
              tabIndex={0}
              selected={!!tabMatches.routeTargetsTabMatch}
              data-testid={testIdConstants.menuLinkRouteTargets}
            />
          )}

          <SidebarMenuLink
            label="Negotiation plan"
            leftIcon={stenaBusinessAgreement}
            selected={!!tabMatches.customersTabMatch}
            tabIndex={0}
            onClick={() =>
              sidebarMenuLinkOnClick(
                routeFactory.productAndPrice.customer.customerList()
              )
            }
            onKeyDown={(e) =>
              onKeyDown(e, routeFactory.productAndPrice.customer.customerList())
            }
            data-testid={testIdConstants.menuLinkNegotiationPlan}
          />

          <SidebarMenuLink
            label="Statistics"
            leftIcon={stenaStatisticsBar}
            onClick={() =>
              sidebarMenuLinkOnClick(routeFactory.statistics.totalTab())
            }
            onKeyDown={(e) => onKeyDown(e, routeFactory.statistics.totalTab())}
            selected={!!tabMatches.statisticsTabMatch}
            tabIndex={0}
            data-testid={testIdConstants.menuLinkStatistics}
          />

          <SidebarMenuLink
            leftIcon={stenaCalculate}
            label="Price Calculator"
            onClick={() =>
              sidebarMenuLinkOnClick(
                routeFactory.priceCalculator.priceCalculatorRoot()
              )
            }
            onKeyDown={(e) =>
              onKeyDown(e, routeFactory.priceCalculator.priceCalculatorRoot())
            }
            selected={!!tabMatches.priceCalculatorTabMatch}
            tabIndex={0}
            data-testid={testIdConstants.menuLinkPriceCalculator}
          />
          {hasClaim(claims, [
            NOSHOW_LATE_EDIT,
            OPEN_AGREEMENT,
            OPEN_INVOICING,
          ]) && (
            <SidebarMenuCollapsible
              label={"No show & Late handling"}
              leftIcon={stenaBusinessNslh}
              initialExpand
            >
              {hasClaim(claims, [OPEN_AGREEMENT]) && (
                <SidebarMenuLink
                  label="Route prices"
                  onClick={() =>
                    sidebarMenuLinkOnClick(
                      routeFactory.noShowLateHandling.routePrices()
                    )
                  }
                  onKeyDown={(e) =>
                    onKeyDown(e, routeFactory.noShowLateHandling.routePrices())
                  }
                  selected={!!tabMatches.noShowLateHandlingRoutePricesMatch}
                  tabIndex={0}
                  data-testid={testIdConstants.menuLinkNSLHRoutePrices}
                />
              )}
              {hasClaim(claims, [NOSHOW_LATE_EDIT]) && (
                <SidebarMenuLink
                  label="Handle bookings"
                  onClick={() =>
                    sidebarMenuLinkOnClick(
                      routeFactory.noShowLateHandling.handleBookings()
                    )
                  }
                  onKeyDown={(e) =>
                    onKeyDown(
                      e,
                      routeFactory.noShowLateHandling.handleBookings()
                    )
                  }
                  selected={!!tabMatches.noShowLateHandlingHandleBookingsMatch}
                  tabIndex={0}
                  data-testid={testIdConstants.menuLinkNSLHHandleBookings}
                />
              )}

              {hasClaim(claims, [OPEN_INVOICING]) && (
                <SidebarMenuLink
                  label="Follow up"
                  onClick={() =>
                    sidebarMenuLinkOnClick(
                      routeFactory.noShowLateHandling.followUpTabView(
                        NslhInvoiceTab.overview
                      )
                    )
                  }
                  onKeyDown={(e) =>
                    onKeyDown(
                      e,
                      routeFactory.noShowLateHandling.followUpTabView(
                        NslhInvoiceTab.overview
                      )
                    )
                  }
                  selected={!!tabMatches.noShowLateHandlingSearchFollowUpMatch}
                  tabIndex={0}
                  data-testid={testIdConstants.menuLinkNSLHFollowUp}
                />
              )}
            </SidebarMenuCollapsible>
          )}
          {hasClaim(claims, [TARGET_ADMIN_CLAIM]) && (
            <>
              <SidebarMenuSeparator />
              <SidebarMenuHeading label={"Admin"} />
              <SidebarMenuLink
                leftIcon={stenaUserSingle}
                label="Group freight targets"
                onClick={() =>
                  sidebarMenuLinkOnClick(
                    routeFactory.targetingTool.adminTarget.allRoutes()
                  )
                }
                onKeyDown={(e) =>
                  onKeyDown(
                    e,
                    routeFactory.targetingTool.adminTarget.allRoutes()
                  )
                }
                selected={!!tabMatches.groupFreightTargetsMatch}
                tabIndex={0}
                data-testid={testIdConstants.menuLinkAdminGroupFreightTargets}
              />
              {hasClaim(claims, [UPDATE_ROUTE_PRICES]) && (
                <SidebarMenuLink
                  leftIcon={stenaDetails}
                  label="Upload surcharge documents"
                  onClick={() =>
                    sidebarMenuLinkOnClick(
                      routeFactory.admin.surchargeDocuments()
                    )
                  }
                  onKeyDown={(e) =>
                    onKeyDown(e, routeFactory.admin.surchargeDocuments())
                  }
                  selected={!!tabMatches.surchargeDocumentsMatch}
                  tabIndex={0}
                />
              )}
            </>
          )}
        </StenaSidebarMenu>
      </Column>
    </Drawer>
  );
};
