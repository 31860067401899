import { stenaDetails } from "@stenajs-webui/elements";
import { TextInput } from "@stenajs-webui/forms";
import { useFilterWorkspace } from "../../hooks/UseFilterWorkspace";
import { FppSearchFilterSection } from "../FppSearchFilterSection";

interface Props {
  enabled?: boolean;
  label?: string;
}

export const AgreementNameFilterSection: React.FC<Props> = ({
  enabled,
  label,
}) => {
  const { agreementName, setAgreementName } = useFilterWorkspace();

  return (
    <FppSearchFilterSection
      enabled={enabled}
      label={label ?? "Agreement name"}
      numSelected={agreementName?.length ? 1 : undefined}
      sectionId={"agreementName"}
      leftIcon={stenaDetails}
    >
      <TextInput value={agreementName} onValueChange={setAgreementName} />
    </FppSearchFilterSection>
  );
};
