import React, { Fragment } from "react";
import { Indent, Row, Text } from "@stenajs-webui/core";
import { Icon, stenaExclamationTriangle } from "@stenajs-webui/elements";
import { cssColor } from "@stenajs-webui/theme";
import { testIdConstants } from "@/common/test-id-constants/testIdConstants";

interface Props {
  error?: Error;
  width?: string;
}

export const ValidationErrorMessage: React.FC<Props> = ({ error, width }) => {
  return (
    <Row alignItems={"center"} width={width}>
      {error && (
        <>
          <Icon
            icon={stenaExclamationTriangle}
            color={cssColor("--lhds-color-red-500")}
          />
          <Indent />
          <Text
            color={cssColor("--lhds-color-red-500")}
            data-testid={testIdConstants.errorMessageText}
          >
            {error.message.split("\n").map((text) => (
              <Fragment key={text}>
                {text}
                <br />
              </Fragment>
            ))}
          </Text>
        </>
      )}
    </Row>
  );
};
