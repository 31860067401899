import { RebateAgreementStatusCode } from "@/gql/graphql";
import { useArraySet } from "@stenajs-webui/core";
import { stenaStatusInProgress } from "@stenajs-webui/elements";
import { FilterCheckbox, FilterCheckboxList } from "@stenajs-webui/filter";
import * as React from "react";
import { useFilterWorkspace } from "../../hooks/UseFilterWorkspace";
import { RebateStatus } from "../../types/FilterEntitys";
import { FppSearchFilterSection } from "../FppSearchFilterSection";

interface Props {
  enabled?: boolean;
  label?: string;
}

const items: Array<RebateStatus> = [
  {
    id: RebateAgreementStatusCode.Active,
    name: "Active",
    code: RebateAgreementStatusCode.Active,
  },
  {
    id: RebateAgreementStatusCode.Pending,
    name: "Pending",
    code: RebateAgreementStatusCode.Pending,
  },
  {
    id: RebateAgreementStatusCode.Finalized,
    name: "Finalized",
    code: RebateAgreementStatusCode.Finalized,
  },
  {
    id: RebateAgreementStatusCode.Void,
    name: "Void",
    code: RebateAgreementStatusCode.Void,
  },
];

export const RebateStatusFilterSection: React.FC<Props> = (props) =>
  props.enabled ? <RebateStatusFilterSectionInternal {...props} /> : <></>;
const RebateStatusFilterSectionInternal: React.FC<Props> = ({
  enabled,
  label,
}) => {
  const { rebateStatuses, setRebateStatuses } = useFilterWorkspace();

  const { toggle } = useArraySet(
    rebateStatuses,
    setRebateStatuses,
    (a, b) => a.id === b.id
  );

  const checkboxClickHandler = (_: boolean, id: string) => {
    const entityToToggle = items.find((item) => item.id === id);
    if (entityToToggle) {
      toggle(entityToToggle);
    }
  };
  return (
    <FppSearchFilterSection
      sectionId={"rebateStatuses"}
      label={label}
      enabled={enabled}
      numSelected={rebateStatuses.length}
      leftIcon={stenaStatusInProgress}
      children={
        <FilterCheckboxList maxHeight={"400px"}>
          {items.map((item) => (
            <FilterCheckbox
              key={item.id}
              label={item.name}
              value={
                !!rebateStatuses.find(
                  (rebateStatus) => rebateStatus.id === item.id
                )
              }
              onValueChange={(v) => checkboxClickHandler(v, item.id)}
            />
          ))}
        </FilterCheckboxList>
      }
    />
  );
};
