import {
  ReportScreenHeadagreementFragment,
  SurchargeDocumentsQuery,
} from "@/gql/graphql";
import { StandardTable, TableContext } from "@stenajs-webui/grid";
import { uniq } from "lodash";
import React, { useEffect, useMemo } from "react";
import {
  AdditionalSurchargeTableColumnKeys,
  tableConfig,
} from "./common/surcharges-table-config";

interface Props {
  additionalSurchargeDocuments?: SurchargeDocumentsQuery["productPrice"]["additionalCharges"]["all"];
  tableContext: TableContext<AdditionalSurchargeTableColumnKeys>;
  loading: boolean;
  selectedAgreements:
    | {
        selectedRouteAgreements: ReportScreenHeadagreementFragment["routeAgreements"];
        selectedMultilegRouteAgreements: ReportScreenHeadagreementFragment["multiLegAgrRoutes"];
      }
    | undefined;
  customerInvoiceCurrency: string;
}

export interface AdditionalSurchargesTableRow {
  id: string;
  name: string | null;
  validFrom: string | null;
  currencyCode: string | null;
  seaAreaCode: string | null;
}

export const SurchargesTable: React.FC<Props> = ({
  additionalSurchargeDocuments,
  tableContext,
  loading,
  selectedAgreements,
  customerInvoiceCurrency,
}) => {
  const tableRows = useMemo(
    () =>
      additionalSurchargeDocuments?.map<AdditionalSurchargesTableRow>(
        (additionalSurchargeDocument) => ({
          id: additionalSurchargeDocument.id,
          currencyCode: additionalSurchargeDocument.currency,
          seaAreaCode: additionalSurchargeDocument.seaArea?.name ?? "",
          name: additionalSurchargeDocument.fileName,
          validFrom: additionalSurchargeDocument.validFrom,
        })
      ),
    [additionalSurchargeDocuments]
  );

  const prepopulateMatchingRows = () => {
    const selectedRouteAgreements =
      selectedAgreements?.selectedRouteAgreements?.flatMap(
        (selectedAgreement) => {
          return (
            tableRows
              ?.filter(
                (tr) =>
                  tr.currencyCode === customerInvoiceCurrency &&
                  tr.seaAreaCode ===
                    selectedAgreement.routes[0].routePair.seaArea.name
              )
              .map((tr) => tr.id) ?? []
          );
        }
      ) ?? [];

    const selectedMultilegRouteAgreements =
      selectedAgreements?.selectedMultilegRouteAgreements?.flatMap(
        (selectedAgreement) => {
          return (
            tableRows
              ?.filter(
                (tr) =>
                  tr.currencyCode === customerInvoiceCurrency &&
                  tr.seaAreaCode ===
                    selectedAgreement.route.routePair.seaArea.name
              )
              .map((tr) => tr.id) ?? []
          );
        }
      ) ?? [];

    return uniq(
      selectedRouteAgreements.concat(selectedMultilegRouteAgreements)
    );
  };

  useEffect(() => {
    tableContext.dispatch(
      tableContext.actions.setSelectedIds(prepopulateMatchingRows())
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StandardTable
      tableContext={tableContext}
      config={tableConfig}
      items={tableRows}
      loading={loading}
    />
  );
};
