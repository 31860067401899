import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const headAgreementCommonReducerId = "headAgreementCommon";

export interface HeadAgreementCommonState {
  copyToNextYear: boolean;
}

const initialState: HeadAgreementCommonState = {
  copyToNextYear: false,
};

const headAgreementCommonSlice = createSlice({
  name: headAgreementCommonReducerId,
  initialState,
  reducers: {
    setCopyToNextYear(
      state,
      { payload: copyToNextYear }: PayloadAction<boolean>
    ) {
      state.copyToNextYear = copyToNextYear;
    },
  },
});

export const {
  reducer: headAgreementCommonReducer,
  actions: headAgreementCommonActions,
} = headAgreementCommonSlice;
