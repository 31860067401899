import * as React from "react";
import { routeFactory } from "../../../../RouteFactory";
import { PageHeader } from "../../../../common/components/page-header/PageHeader";
import { useCustomer } from "../../customers/common/customer-header/UseCustomer";
import { PageHeaderItem } from "../../../../common/components/page-header/PageHeaderItem";
import { Crumb } from "../../../../common/components/bread-crumbs/Crumb";
import { ExpectedVolumeActionButtons } from "./ExpectedVolumeActionButtons";
import {
  BreadCrumbs,
  FlatButton,
  ResultListBanner,
  ResultListBannerState,
} from "@stenajs-webui/elements";
import { expectedVolumeActions } from "../redux/ExpectedVolumeRedux";
import { useDispatch } from "react-redux";
import { useHeadAgreementsByCustomer } from "../../head-agreement/list/hooks/UseHeadAgreementsSwitcher";

interface Props {
  customerId: string;
  year: number;
  bannerState: ResultListBannerState | undefined;
}

export const ExpectedVolumeHeader: React.FC<Props> = ({
  customerId,
  year,
  bannerState,
}) => {
  const dispatch = useDispatch();
  const { loading: loadingCustomer, customer } = useCustomer(customerId, year);
  const { defaultHeadAgreement } = useHeadAgreementsByCustomer(customerId);

  return (
    <PageHeader
      title={"Expected volume"}
      loading={loadingCustomer}
      contentRight={
        <ExpectedVolumeActionButtons
          customerId={customerId}
          customerStatisticalGrouping={customer?.statisticalGrouping?.id ?? "0"}
          year={year}
        />
      }
      contentUnder={
        bannerState && (
          <ResultListBanner
            bannerState={bannerState}
            variant={"error"}
            contentRight={
              <FlatButton
                label={"Close"}
                onClick={() =>
                  dispatch(
                    expectedVolumeActions.setProgress({
                      bannerState: undefined,
                    })
                  )
                }
              />
            }
          />
        )
      }
      breadcrumbs={
        <BreadCrumbs>
          <Crumb
            label={"Negotiation plan"}
            path={routeFactory.productAndPrice.customer.customerList()}
          />
          <Crumb
            label={customer?.breadCrumbName ?? ""}
            path={routeFactory.productAndPrice.customer.customerHeadAgreement({
              customerId,
              headAgreementId: defaultHeadAgreement?.id ?? "",
            })}
          />
          <Crumb
            label={"Expected volumes"}
            path={routeFactory.productAndPrice.customer.customerExpectedVolume({
              customerId,
            })}
            isLast
          />
        </BreadCrumbs>
      }
    >
      <PageHeaderItem label={"Cust. no."} value={customer?.custNo} />
      <PageHeaderItem label={"Cust. name"} value={customer?.name} />
      <PageHeaderItem label={"Cust. index"} value={customer?.custIndex} />
      <PageHeaderItem
        label={"Stat. no."}
        value={customer?.statisticalGrouping?.id}
      />
      <PageHeaderItem
        label={"Invoice curr."}
        value={customer?.invoiceCurrency.code}
      />
      <PageHeaderItem label={"Seller"} value={customer?.seller.name} />
      <PageHeaderItem
        label={"Sales region"}
        value={customer?.salesRegion.name}
      />
    </PageHeader>
  );
};
