import { DateRange, DateRangeDualTextInput } from "@stenajs-webui/calendar";
import { stenaCalendar } from "@stenajs-webui/elements";
import * as React from "react";
import { useFilterWorkspace } from "../../hooks/UseFilterWorkspace";
import { FppSearchFilterSection } from "../FppSearchFilterSection";

interface Props {
  enabled?: boolean;
  label?: string;
}

export const DateRangeFilterSection: React.FC<Props> = ({ enabled, label }) => {
  const { dateRange, setDateRange } = useFilterWorkspace();

  const onFilterValueChange = (dateRange: DateRange) => {
    setDateRange({ ...dateRange });
  };

  const numberOfActiveFilters = () => {
    return dateRange.startDate || dateRange.endDate ? 1 : 0;
  };

  return (
    <FppSearchFilterSection
      sectionId={"dateRange"}
      label={label}
      enabled={enabled}
      numSelected={numberOfActiveFilters()}
      leftIcon={stenaCalendar}
      children={
        <DateRangeDualTextInput
          value={dateRange}
          onValueChange={onFilterValueChange}
        />
      }
    />
  );
};
