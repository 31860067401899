import { FppFilterSettings } from "@/features/search-filter/types/FppFilterSettings";

export const rebateFilterSettings: FppFilterSettings = {
  sellerEnabled: true,
  sellerHeader: "Seller",
  salesRegionWithCountriesEnabled: true,
  salesRegionWithCountriesHeader: "Sales regions",
  dateRangeEnabled: true,
  dateRangeHeader: "Date",
  agreementNameEnabled: true,
  agreementNumberEnabled: true,
  rebateStatusEnabled: true,
  rebateStatusHeader: "Status",
};
