import { firstLetterCapital } from "@/common/string/GeneralStringFormatting";
import { Box, useArraySet } from "@stenajs-webui/core";
import { Tag, stenaSailingVehicle } from "@stenajs-webui/elements";
import { FilterCheckbox, FilterCheckboxList } from "@stenajs-webui/filter";
import { CollapsibleWithCheckbox } from "@stenajs-webui/panels";
import { groupBy, reject } from "lodash";
import * as React from "react";
import { useState } from "react";
import { useFilterWorkspace } from "../../hooks/UseFilterWorkspace";
import { VehicleType } from "../../types/FilterEntitys";
import { FppSearchFilterSection } from "../FppSearchFilterSection";
import { useFetchAllVehicleTypes } from "../../hooks/FilterFetchers/UseFetchAllVehicleTypes";
import { testIdConstants } from "@/common/test-id-constants/testIdConstants";

const VehicleTypeCollapsible = ({
  label,
  groupedVehicleType,
  selectedVehicleTypes,
  setVehicleTypes,
  checkboxClickHandler,
}: {
  label: string;
  groupedVehicleType: VehicleType[];
  selectedVehicleTypes: VehicleType[];
  setVehicleTypes: (vehicleTypes: VehicleType[]) => void;
  checkboxClickHandler: (_: boolean, id: string) => void;
}) => {
  const [collapsed, setCollapsed] = useState(true);

  const allChecked = groupedVehicleType.filter((vt) =>
    selectedVehicleTypes.find((selected) => vt.id === selected.id)
  );

  const handleGroupChecked = () => {
    if (allChecked.length > 0) {
      setVehicleTypes(
        reject(selectedVehicleTypes, (vt) =>
          allChecked.find((p) => p.id === vt.id)
        ) as VehicleType[]
      );
    } else {
      setVehicleTypes(selectedVehicleTypes.concat(groupedVehicleType));
    }
  };

  return (
    <CollapsibleWithCheckbox
      value={allChecked.length === groupedVehicleType.length}
      indeterminate={
        allChecked.length > 0 && allChecked.length !== groupedVehicleType.length
      }
      onChange={handleGroupChecked}
      label={label}
      collapsed={collapsed}
      onClick={() => setCollapsed(!collapsed)}
      contentRight={
        allChecked.length > 0 && (
          <Tag label={String(allChecked.length)} size="small" />
        )
      }
    >
      <FilterCheckboxList maxHeight={"400px"}>
        {groupedVehicleType.map((vehicleType) => {
          return (
            <FilterCheckbox
              key={vehicleType.id}
              value={
                !!selectedVehicleTypes.find(
                  (selectedVehicleType) =>
                    selectedVehicleType.id === vehicleType.id
                )
              }
              label={`${firstLetterCapital(vehicleType.name)} (${
                vehicleType.code
              })`}
              onValueChange={(v) => checkboxClickHandler(v, vehicleType.id)}
            />
          );
        })}
      </FilterCheckboxList>
    </CollapsibleWithCheckbox>
  );
};

interface Props {
  enabled?: boolean;
  label?: string;
}
export const VehicleTypeFilterSection: React.FC<Props> = (props) =>
  props.enabled ? <VehicleTypeFilterSectionInternal {...props} /> : <></>;

const VehicleTypeFilterSectionInternal: React.FC<Props> = ({
  enabled,
  label,
}) => {
  const { vehicleTypes, setVehicleTypes, vehicleTypeAll, setVehicleTypeAll } =
    useFilterWorkspace();
  const { error, items, loading, refetch } = useFetchAllVehicleTypes();
  const grouped = groupBy(items, (item) => item.accompanied);

  const { toggle } = useArraySet(
    vehicleTypes,
    setVehicleTypes,
    (a, b) => a.id === b.id
  );

  const checkboxClickHandler = (_: boolean, id: string) => {
    const entityToToggle = items.find((item) => item.id === id);
    if (entityToToggle) {
      toggle(entityToToggle);
    }
  };

  return (
    <div data-testid={testIdConstants.vehicleTypeFilterSection}>
      <FppSearchFilterSection
        sectionId={"vehicleTypes"}
        label={label}
        enabled={enabled}
        numSelected={vehicleTypes.length}
        leftIcon={stenaSailingVehicle}
        error={error?.message}
        loading={loading}
        onRetry={refetch}
        children={
          <>
            <Box height={"40px"}>
              <FilterCheckbox
                value={vehicleTypeAll}
                label={"Vehicle type = ALL"}
                onValueChange={setVehicleTypeAll}
                data-testid={
                  testIdConstants.vehicleTypeFilterSectionTypeAllCheckbox
                }
              />
            </Box>
            {Object.keys(grouped).map((accompanied) => {
              return (
                <React.Fragment key={accompanied}>
                  <VehicleTypeCollapsible
                    selectedVehicleTypes={vehicleTypes}
                    setVehicleTypes={setVehicleTypes}
                    label={
                      accompanied === "true" ? "Accompanied" : "Unaccompanied"
                    }
                    checkboxClickHandler={checkboxClickHandler}
                    groupedVehicleType={grouped[accompanied]}
                  />
                </React.Fragment>
              );
            })}
          </>
        }
      />
    </div>
  );
};
