import {
  UseTargetOverviewsQuery,
  UseTargetOverviewsQueryVariables,
} from "@/gql/graphql";
import { gql, useLazyQuery } from "@apollo/client";

const query = gql`
  fragment targetOverviewByYear on TargetOverview {
    id
    completionRate
    last12MonthVol
    negotiationStatus {
      code
      description
    }
    noOfAccounts
    routePairCode
    volumeTargetGm
    weightedTargetCustomer
    weightedTargetGm
    routePair {
      id
      description
    }
  }
  query UseTargetOverviews($year: Int!) {
    productPrice {
      targetOverviews {
        byYear(year: $year) {
          ...targetOverviewByYear
        }
      }
    }
  }
`;

export const useTargetOverviewsQuery = () => {
  const [fetchTargetOverview, { data, loading, error }] = useLazyQuery<
    UseTargetOverviewsQuery,
    UseTargetOverviewsQueryVariables
  >(query, { fetchPolicy: "cache-and-network" });

  return {
    error,
    loading,
    fetchTargetOverview,
    targetOverviews: data?.productPrice.targetOverviews.byYear,
  };
};
