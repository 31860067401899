import { CSSObject } from "@emotion/react";
import { ZIndex } from "@/common/utils/ZIndexEnum";
import { Box, Indent, Row, Spacing, Txt } from "@stenajs-webui/core";
import { Select, SelectProps } from "@stenajs-webui/select";
import * as React from "react";
import { useCallback } from "react";
import {
  currentYear,
  monthOptions,
  yearOptions,
} from "./util/GetDatesForSelect";
import { testIdConstants } from "@/common/test-id-constants/testIdConstants";

export interface NoShowLateHandlingNewDateSelectProps
  extends Omit<SelectProps<any>, "value" | "onChange"> {
  yearValue: number;
  monthValue: number;
  onYearValueChange: (yearValue: number) => void;
  onMonthValueChange: (monthValue: number) => void;
}

export const NoShowLateHandlingNewDateSelect: React.FC<
  NoShowLateHandlingNewDateSelectProps
> = ({
  onYearValueChange,
  onMonthValueChange,
  yearValue,
  monthValue,
  ...selectProps
}) => {
  const selectedYear = yearOptions.find((o) => o.value === yearValue);
  const selectedMonth = monthOptions.find((o) => o.value === monthValue);

  const disabledOptions = useCallback(
    (option: number) => {
      if (selectedYear?.value && selectedYear.value > currentYear) {
        return false;
      }

      return option < new Date().getUTCMonth() + 1;
    },
    [selectedYear]
  );

  return (
    <Row display={"flex"}>
      <Box display={"flex"} flexDirection={"column"} width={"20%"}>
        <Txt size={"small"}>Select year</Txt>
        <Spacing num={0.5} />
        <Box
          width={"100%"}
          data-testid={testIdConstants.routePriceAddNewValidFromDateYearSelect}
        >
          <Select
            styles={{
              menuPortal: (base: CSSObject) => ({
                ...base,
                zIndex: ZIndex.highest,
              }),
            }}
            {...selectProps}
            options={yearOptions}
            value={selectedYear}
            onChange={(item) => item && onYearValueChange(item.value)}
          />
        </Box>
      </Box>
      <Indent />
      <Box display={"flex"} flexDirection={"column"} width={"20%"}>
        <Txt size={"small"}>Select month</Txt>
        <Spacing num={0.5} />
        <Box width={"75%"}>
          <Select
            styles={{
              menuPortal: (base: CSSObject) => ({
                ...base,
                zIndex: ZIndex.highest,
              }),
            }}
            {...selectProps}
            options={monthOptions}
            value={selectedMonth}
            isOptionDisabled={(option) => disabledOptions(option.value)}
            onChange={(item) => item && onMonthValueChange(item.value)}
          />
        </Box>
      </Box>
    </Row>
  );
};
